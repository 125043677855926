import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  TYPOGRAPHY_GRAY,
  ROW_FOCUSED_BG,
  ROW_HOVER_BG,
} from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';
import { checkTernaryCondition } from 'utils/helper';

export const dropdownStyleProps = {
  boxSizing: 'border-box',
  height: '2rem',
  borderRadius: '2px',
};

export const DropdownWrapper = styled.div`
  display: flex;
  gap: 1rem;
  text-align: left;
`;

export const CombinationsWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding-top: 0.5rem;
`;
export const CopyModalWrapper = styled.div`
  overflow-y: auto;
`;
export const CombinationBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 1.25rem;
  font-size: 0.75rem;
  color: ${({ disabled }) =>
    checkTernaryCondition(disabled, TYPOGRAPHY_GRAY, DARK_CHARCOAL)};
  letter-spacing: 0.2px;
  padding: 0.25rem 0.5rem;
  font-weight: ${({ selected }) => checkTernaryCondition(selected, 600, 400)};
  background-color: ${({ selected }) => selected && ROW_FOCUSED_BG};

  :hover {
    background-color: ${({ selected, disabled }) =>
      checkTernaryCondition(
        selected,
        ROW_FOCUSED_BG,
        !disabled && ROW_HOVER_BG,
      )};
  }
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 1.104rem;
  padding: 0.125rem 0.25rem;
  color: ${COOL_GRAY};
  border: 0.5px solid ${COOL_GRAY};
  border-radius: 4px;
  font-size: 0.688rem;
  margin: 0.25rem;
  cursor: pointer;
`;

export const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0rem 0rem 0rem;
`;

export const NoDataBox = styled.div`
  height: 3.75rem;
  width: 100%;
  color: ${DARK_CHARCOAL};
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const closeIconSx = {
  ...pointerCursor,
  alignSelf: 'flex-start',
  color: COOL_GRAY,
};
export const chipCloseIconsSx = {
  height: '0.75rem',
  width: '0.75rem',
  alignSelf: 'center',
};
export const closeChipSx = {
  height: '0.938rem',
};

export const flexColumnStyle = { display: 'flex', flexDirection: 'column' };

export const CopyPaginationWrapper = styled.div`
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
`;

export const CopyContentDividerStyle = { marginTop: '0.75rem' };

export const CopyPaginationText = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
`;
