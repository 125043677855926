import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  MODAL_CONTENT,
  allFarmsOption,
  allFuelsOption,
  allYearsOption,
  farmsDataProps,
  fuelsDataProps,
  farmyearsDataProps,
  fieldsDataProps,
  fieldYearsDataProps,
  cropsDataProps,
  allFieldsOption,
  allCropsOption,
  getValidCombinations,
  ITEMS_PER_PAGE,
} from './CopyModal.content';
import { PAGE_CONTENT as BASELINE_CONTENT } from '../../BaseLineReporting/BaseLineReportingMainPage.content';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  SubmitBtnStyle,
  ModalDialogStyle,
} from '../Modals.style';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
  TextWrapper,
} from 'components/FormComponents/FormStyles';
import {
  Chip,
  ChipWrapper,
  CombinationBox,
  CombinationsWrapper,
  DropdownWrapper,
  NoDataBox,
  closeIconSx,
  closeChipSx,
  chipCloseIconsSx,
  dropdownStyleProps,
  flexColumnStyle,
  CopyModalWrapper,
  CopyPaginationText,
  CopyPaginationWrapper,
  CopyContentDividerStyle,
} from './CopyModal.style';
import { useEffect, useMemo, useState } from 'react';
import {
  FETCH_FUEL_COPIED_DATA,
  FETCH_FARM_COPY_ACROSS_DROPDOWN_DATA,
  FETCH_FIELD_COPY_ACROSS_DROPDOWN_DATA,
  FETCH_CROP_COPIED_DATA,
} from 'urls';
import axios from 'axios';
import {
  checkTernaryCondition,
  getUniqueObjectsByKey,
  isEmpty,
} from 'utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { WHITE } from 'theme/GlobalColors';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toastStyle, flexSpaceBetween } from 'theme/GlobalStyles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CopyModal = ({
  open = false,
  modalCloseHandler = () => null,
  title = '',
  apiParams = null,
  copyHandler = () => null,
}) => {
  const [loading, setLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);

  const [dropdown1List, setDropdown1List] = useState([]);
  const [dropdown2List, setDropdown2List] = useState([]);
  const [dropdown3List, setDropdown3List] = useState([]);

  const [dropdown1Value, setDropdown1Value] = useState(null);
  const [dropdown2Value, setDropdown2Value] = useState(null);
  const [dropdown3Value, setDropdown3Value] = useState(null);

  const [selectedCombinations, setSelectedCombinations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [validCombinations, setValidCombinations] = useState([]);

  const dropdown1Props = checkTernaryCondition(
    title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle,
    farmsDataProps,
    fieldsDataProps,
  );
  const dropdown2Props = checkTernaryCondition(
    title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle,
    farmyearsDataProps,
    fieldYearsDataProps,
  );
  const dropdown3Props = checkTernaryCondition(
    title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle,
    fuelsDataProps,
    cropsDataProps,
  );

  const closeModal = () => {
    setDropdownData([]);
    setDropdown1List([]);
    setDropdown2List([]);
    setDropdown3List([]);
    setDropdown1Value(null);
    setDropdown2Value(null);
    setDropdown3Value(null);
    setSelectedCombinations([]);
    modalCloseHandler();
  };

  const handleCopy = () => {
    if (title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle) {
      setLoading(true);
      const body = {
        farmList: selectedCombinations?.map((combination) => ({
          farmSampleId: combination.dropdown2Value,
          ffId: combination.dropdown3Value,
        })),
      };

      axios
        .post(FETCH_FUEL_COPIED_DATA, body)
        .then((response) => {
          copyHandler({
            data: response.data,
            combinations: selectedCombinations,
          });
          closeModal();
        })
        .catch(() => {
          toast(
            <CustomSnackbar
              type="error"
              message={MODAL_CONTENT.tractorVehicleCopyError}
            />,
            toastStyle,
          );
        })
        .finally(() => setLoading(false));
    } else if (title === BASELINE_CONTENT.fieldCropCopyModalTitle) {
      setLoading(true);
      const combinations = selectedCombinations?.map((combination) => ({
        fieldId: combination.dropdown1Value,
        sampleId: combination.dropdown2Value,
        cropId: combination.dropdown3Value,
      }));

      axios
        .post(FETCH_CROP_COPIED_DATA, combinations)
        .then((response) => {
          copyHandler({
            data: response.data,
            combinations: selectedCombinations,
          });
          closeModal();
        })
        .catch(() => {
          toast(
            <CustomSnackbar
              type="error"
              message={MODAL_CONTENT.fieldCropCopyError}
            />,
            toastStyle,
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const fetchDropdownValues = async (url) => {
    return axios
      .get(url)
      .then((response) => response.data)
      .catch(() =>
        toast(
          <CustomSnackbar
            type="error"
            message="Unable to fetch data, please try again"
          />,
          toastStyle,
        ),
      );
  };

  const extractDropdownData = (
    data,
    dropdown1Props,
    dropdown2Props,
    dropdown3Props,
  ) => {
    let _dropdown1List = [],
      _dropdown2List = [],
      _dropdown3List = [];

    data?.forEach((dropdown1Item) => {
      _dropdown1List.push({
        label: dropdown1Item[dropdown1Props.labelKey],
        value: dropdown1Item[dropdown1Props.labelKey],
      });

      dropdown1Item[dropdown2Props?.name]?.forEach((dropdown2Item) => {
        _dropdown2List.push({
          label: dropdown2Item[dropdown2Props.labelKey],
          value: dropdown2Item[dropdown2Props.labelKey],
        });

        dropdown2Item[dropdown3Props.name].forEach((dropdown3Item) => {
          _dropdown3List.push({
            label: dropdown3Item[dropdown3Props.labelKey],
            value: dropdown3Item[dropdown3Props.labelKey],
          });
        });
      });
    });

    return [
      getUniqueObjectsByKey(_dropdown1List, 'label'),
      getUniqueObjectsByKey(_dropdown2List, 'label'),
      getUniqueObjectsByKey(_dropdown3List, 'label'),
    ];
  };

  useEffect(() => {
    if (dropdownData) {
      if (title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle) {
        const [farmsList, yearsList, fuelsList] = extractDropdownData(
          dropdownData,
          farmsDataProps,
          farmyearsDataProps,
          fuelsDataProps,
        );

        farmsList.push(allFarmsOption);
        yearsList.push(allYearsOption);
        fuelsList.push(allFuelsOption);

        setDropdown1List(farmsList);
        setDropdown2List(yearsList);
        setDropdown3List(fuelsList);

        setDropdown1Value(allFarmsOption.value);
        setDropdown2Value(allYearsOption.value);
        setDropdown3Value(allFuelsOption.value);
      } else if (title === BASELINE_CONTENT.fieldCropCopyModalTitle) {
        const [fieldsList, yearsList, cropsList] = extractDropdownData(
          dropdownData,
          fieldsDataProps,
          fieldYearsDataProps,
          cropsDataProps,
        );

        fieldsList.push(allFieldsOption);
        yearsList.push(allYearsOption);
        cropsList.push(allCropsOption);

        setDropdown1List(fieldsList);
        setDropdown2List(yearsList);
        setDropdown3List(cropsList);

        setDropdown1Value(allFieldsOption.value);
        setDropdown2Value(allYearsOption.value);
        setDropdown3Value(allCropsOption.value);
      }
    }
  }, [dropdownData]);

  const getListWithActualData = (list = []) => {
    return list?.filter((dropdown1Item) => {
      let bottomLevelHasValue = false;
      dropdown1Item[dropdown2Props?.name].forEach((dropdown2Item) => {
        if (dropdown2Item[dropdown3Props.name]?.length > 0)
          bottomLevelHasValue = true;
      });

      return bottomLevelHasValue;
    });
  };

  useEffect(() => {
    if (open) {
      let url = '';
      if (title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle) {
        url = `${FETCH_FARM_COPY_ACROSS_DROPDOWN_DATA}?sampleId=${apiParams?.sampleId}&participantId=${apiParams?.participantId}&farmId=${apiParams?.farmId}&projectCycleId=${apiParams.projectCycleId}`;
        setLoading(true);
        fetchDropdownValues(url)
          .then((data) => setDropdownData(getListWithActualData(data)))
          .finally(() => setLoading(false));
      } else if (title === BASELINE_CONTENT.fieldCropCopyModalTitle) {
        url = `${FETCH_FIELD_COPY_ACROSS_DROPDOWN_DATA}?ignore=${apiParams?.sampleId}&participantId=${apiParams?.participantId}&projectCycleId=${apiParams?.projectId}`;
        setLoading(true);
        fetchDropdownValues(url)
          .then((data) => setDropdownData(getListWithActualData(data?.field)))
          .finally(() => setLoading(false));
      }
    }
  }, [open]);

  const formatListToDropdownData = (list, key) => {
    return list?.map((item) => ({ label: item[key], value: item[key] }));
  };

  const get2ndDropdownFilteredList = (dropdown1AllValues = false) => {
    let _dropdown2List = [];

    for (const dropdown1Item of dropdownData) {
      if (
        dropdown1AllValues ||
        dropdown1Item[dropdown1Props?.labelKey] === dropdown1Value
      ) {
        const _dropdown2Items = dropdown1Item[dropdown2Props?.name];
        for (const dropdown2Item of _dropdown2Items) {
          _dropdown2List.push({
            label: dropdown2Item[dropdown2Props.labelKey],
            value: dropdown2Item[dropdown2Props.labelKey],
          });
        }
      }
    }

    return getUniqueObjectsByKey(_dropdown2List, 'label');
  };

  const get3rdDropdownFilteredList = (
    dropdown1AllValues = false,
    dropdown2AllValues = false,
  ) => {
    let _dropdown3List = [];

    for (const dropdown1Item of dropdownData) {
      if (
        dropdown1AllValues ||
        dropdown1Item[dropdown1Props?.labelKey] === dropdown1Value
      ) {
        const _dropdown2Items = dropdown1Item[dropdown2Props?.name];
        for (const dropdown2Item of _dropdown2Items) {
          if (
            dropdown2AllValues ||
            dropdown2Item[dropdown2Props?.labelKey] === dropdown2Value
          ) {
            const _dropdown3Items = dropdown2Item[dropdown3Props.name];
            _dropdown3List.push(
              ...formatListToDropdownData(
                _dropdown3Items,
                dropdown3Props.labelKey,
              ),
            );
          }
        }
      }
    }

    return getUniqueObjectsByKey(_dropdown3List, 'label');
  };

  const resetDropdown3 = () => {
    if (title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle) {
      const fuelsList = get3rdDropdownFilteredList(
        dropdown1Value === allFarmsOption.value,
        dropdown2Value === allYearsOption.value,
      );
      fuelsList.push(allFuelsOption);
      setDropdown3List(fuelsList);

      const fuelsListHasCurrentValue =
        fuelsList.findIndex((fuel) => fuel.label === dropdown3Value) !== -1;
      if (!fuelsListHasCurrentValue) {
        setDropdown3Value(allFuelsOption.value);
      }
    } else if (title === BASELINE_CONTENT.fieldCropCopyModalTitle) {
      const cropsList = get3rdDropdownFilteredList(
        dropdown1Value === allFieldsOption.value,
        dropdown2Value === allYearsOption.value,
      );
      cropsList.push(allCropsOption);
      setDropdown3List(cropsList);

      const cropsListHasCurrentValue =
        cropsList.findIndex((fuel) => fuel.label === dropdown3Value) !== -1;
      if (!cropsListHasCurrentValue) {
        setDropdown3Value(allCropsOption.value);
      }
    }
  };

  const resetDropdown2 = () => {
    if (title === BASELINE_CONTENT.tractorOrVehicleCopyModalTitle) {
      const yearsList = get2ndDropdownFilteredList(
        dropdown1Value === allFarmsOption.value,
      );
      yearsList.push(allYearsOption);
      setDropdown2List(yearsList);

      const yearsListHasCurrentValue =
        yearsList.findIndex((year) => year.label === dropdown2Value) !== -1;
      if (!yearsListHasCurrentValue) {
        setDropdown2Value(allYearsOption.value);
      }
    } else if (title === BASELINE_CONTENT.fieldCropCopyModalTitle) {
      const yearsList = get2ndDropdownFilteredList(
        dropdown1Value === allFieldsOption.value,
      );
      yearsList.push(allYearsOption);
      setDropdown2List(yearsList);

      const yearsListHasCurrentValue =
        yearsList.findIndex((year) => year.label === dropdown2Value) !== -1;
      if (!yearsListHasCurrentValue) {
        setDropdown2Value(allYearsOption.value);
      }
    }
    resetDropdown3();
  };

  useEffect(() => {
    if (dropdown1Value) {
      resetDropdown2();
    }
  }, [dropdown1Value]);

  useEffect(() => {
    if (dropdown2Value) {
      resetDropdown3();
    }
  }, [dropdown2Value]);

  const addCombination = (dropdown1Item, dropdown2Item, dropdown3Item) => {
    const newCombination = {
      dropdown1Value: dropdown1Item[dropdown1Props.valueKey],
      dropdown1Label: dropdown1Item[dropdown1Props.labelKey],
      dropdown2Value: dropdown2Item[dropdown2Props.valueKey],
      dropdown2Label: dropdown2Item[dropdown2Props.labelKey],
      dropdown3Value: dropdown3Item[dropdown3Props.valueKey],
      dropdown3Label: dropdown3Item[dropdown3Props.labelKey],
    };
    setSelectedCombinations((prev) => [...prev, newCombination]);
  };

  const removeCombination = (combination) => {
    setSelectedCombinations((prev) =>
      prev.filter(
        (existingCombination) =>
          !(
            existingCombination.dropdown1Value === combination.dropdown1Value &&
            existingCombination.dropdown2Value === combination.dropdown2Value &&
            existingCombination.dropdown3Value === combination.dropdown3Value
          ),
      ),
    );
  };

  const isSelected = (dropdown1Item, dropdown2Item, dropdown3Item) => {
    return (
      selectedCombinations?.findIndex(
        (combination) =>
          combination.dropdown1Value ===
            dropdown1Item[dropdown1Props.valueKey] &&
          combination.dropdown2Value ===
            dropdown2Item[dropdown2Props.valueKey] &&
          combination.dropdown3Value === dropdown3Item[dropdown3Props.valueKey],
      ) !== -1
    );
  };

  const dropdown3DisabledItems = useMemo(() => {
    const dropdown3SelectedValues = selectedCombinations.map(
      (combination) => combination.dropdown3Label,
    );
    const selectedValueIndexes = [];
    const selectedValues = [];
    dropdown3List?.forEach((item, currentIndex) => {
      if (dropdown3SelectedValues.includes(item.label)) {
        selectedValueIndexes.push(currentIndex);
        selectedValues.push(item.label);
      }
    });
    return { values: selectedValues, indexes: selectedValueIndexes };
  }, [selectedCombinations, dropdown3List]);

  useEffect(() => {
    const combinationData = {
      data: dropdownData,
      dropdown1Props,
      dropdown2Props,
      dropdown3Props,
      dropdown1Value,
      dropdown2Value,
      dropdown3Value,
      dropdown1List,
      dropdown2List,
      dropdown3List,
      dropdown3DisabledItems,
      isSelected,
    };
    setValidCombinations(getValidCombinations(combinationData));
  }, [
    dropdownData,
    dropdown1Value,
    dropdown2Value,
    dropdown3Value,
    dropdown3DisabledItems,
  ]);

  const totalPages = Math.ceil(validCombinations.length / ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const paginatedData = validCombinations?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );

  const isCopyDataEmpty = () => {
    return (
      (dropdown1List?.length === 1 ||
        dropdown2List?.length === 1 ||
        dropdown3List?.length === 1) &&
      !loading
    );
  };

  /**
   * Updates the current page to the last page if the current page exceeds the total number of pages.
   * This ensures that the current page is always within the valid range of pages.
   * The effect runs whenever the total number of pages or the current page changes.
   */
  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage]);

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      onClose={closeModal}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <Backdrop
        sx={{ color: WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <DialogTitle
        sx={{
          ...DialogTitleStyle,
          ...flexSpaceBetween,
        }}>
        <div>{title}</div>
        <CloseIcon
          onClick={closeModal}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>

      <DialogContent
        sx={{
          ...DialogContentStyle,
          ...flexColumnStyle,
        }}>
        <CopyModalWrapper>
          <DropdownWrapper>
            <DropDown
              hasNoBottomMargin={true}
              value={dropdown1Value}
              width="100%"
              minWidth="12.5rem"
              onUpdate={(event) => setDropdown1Value(event.target.value)}
              setStateValue={() => {}}
              dropdownlist={dropdown1List}
              showLabelAsValue={true}
              {...dropdownStyleProps}
            />
            <DropDown
              hasNoBottomMargin={true}
              value={dropdown2Value}
              width="100%"
              minWidth="5.625rem"
              onUpdate={(event) => setDropdown2Value(event.target.value)}
              setStateValue={() => {}}
              dropdownlist={dropdown2List}
              showLabelAsValue={true}
              {...dropdownStyleProps}
            />
            <DropDown
              hasNoBottomMargin={true}
              value={dropdown3Value}
              width="100%"
              minWidth="8.125rem"
              onUpdate={(event) => setDropdown3Value(event.target.value)}
              setStateValue={() => {}}
              dropdownlist={dropdown3List}
              showLabelAsValue={true}
              disabledIndexes={dropdown3DisabledItems?.indexes}
              {...dropdownStyleProps}
            />
          </DropdownWrapper>

          {selectedCombinations.length > 0 && (
            <ChipWrapper>
              {selectedCombinations?.map((combination) => (
                <Chip key={uniqueId()} data-testid="chip">
                  <span
                    style={
                      closeChipSx
                    }>{`${combination.dropdown1Label} - ${combination.dropdown2Label} - ${combination.dropdown3Label}`}</span>
                  <CloseIcon
                    onClick={() => removeCombination(combination)}
                    sx={[closeIconSx, chipCloseIconsSx]}
                  />
                </Chip>
              ))}
            </ChipWrapper>
          )}

          {isCopyDataEmpty() && (
            <NoDataBox>{MODAL_CONTENT.noDataText}</NoDataBox>
          )}

          {!isEmpty(paginatedData) && (
            <CombinationsWrapper>
              {paginatedData?.map(
                ({
                  dropdown1Item,
                  dropdown2Item,
                  dropdown3Item,
                  selected,
                  disabled,
                }) => {
                  return (
                    <CombinationBox
                      key={`${dropdown1Item[dropdown1Props?.labelKey]}-${
                        dropdown2Item[dropdown2Props?.labelKey]
                      }-${dropdown3Item[dropdown3Props?.labelKey]}`}
                      selected={selected}
                      disabled={disabled}
                      onClick={() => {
                        if (!selected && !disabled) {
                          addCombination(
                            dropdown1Item,
                            dropdown2Item,
                            dropdown3Item,
                          );
                        }
                      }}>
                      {`${dropdown1Item[dropdown1Props?.labelKey]} - ${
                        dropdown2Item[dropdown2Props?.labelKey]
                      } - ${dropdown3Item[dropdown3Props?.labelKey]}`}
                    </CombinationBox>
                  );
                },
              )}
            </CombinationsWrapper>
          )}
          {!isCopyDataEmpty() && (
            <>
              <Divider sx={CopyContentDividerStyle} />
              <CopyPaginationWrapper>
                <IconButton
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}>
                  <ArrowBackIosIcon />
                </IconButton>
                <CopyPaginationText>{`${checkTernaryCondition(
                  totalPages === 0 && currentPage === 1,
                  0,
                  currentPage,
                )} of ${totalPages}`}</CopyPaginationText>
                <IconButton
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </CopyPaginationWrapper>
            </>
          )}
        </CopyModalWrapper>
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={closeModal}>
          {MODAL_CONTENT.cancelBtnLabel}
        </Button>
        <Button
          disableElevation
          disabled={selectedCombinations?.length === 0}
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          onClick={handleCopy}>
          {MODAL_CONTENT.copyBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CopyModal.propTypes = {
  open: PropTypes.bool,
  modalCloseHandler: PropTypes.func,
  title: PropTypes.string,
  apiParams: PropTypes.object,
  copyHandler: PropTypes.func,
};
export default CopyModal;
