export const PAGE_NOT_FOUND = {
  title: '404 Error',
  subHeading: 'We can’t find that page',
  text: 'Sorry, the page you are looking for doesn’t seem to exist.',
};

export const UNAUTHORIZED_ACCESS = {
  title: '403 Error',
  subHeading: 'This page requires authorization',
  text: 'Sorry, you don’t have permission for the page you are trying to access. If you believe you should be authorized please contact your administrator.',
};

export const UNAUTHENTICATED_ACCESS = {
  title: '401 Error',
  subHeading: 'This page requires authorization  ',
  text: 'Sorry, the page you are trying to view requires authorization credentials.',
};
