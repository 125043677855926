import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as DisabledDownloadIcon } from '../../../../../../assets/icons/DisabledDownloadIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/icons/downloadiconblue.svg';
import { PAGE_CONTENT } from '../../PdfViewerPage/PdfViewerPage.content';
import { CHECK_EXCEPTION_REPORT, GET_EXCEPTION_REPORT } from 'urls';
import {
  FooterWrapper,
  HeaderStyle,
  PdfDownloadButtonStyle,
  PDFViewerSx,
} from './PdfComponent.style';
import { secondaryButtonStyle } from '../PdfViewerPage.style';
import Footer from 'layout/footer/Footer.react';
import axios from 'axios';
import {
  BLOB_RESPONSE,
  fetchActivity,
  TOAST_MESSAGE,
} from './PdfComponent.content';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { downloadFileWithBlob } from 'utils/helper';
import { useState, useEffect } from 'react';
import { disabledButtonStyle } from 'components/FormComponents/FormStyles';

const PdfComponent = ({
  pdfUrl,
  projectName,
  projectCycleId,
  participantName,
  activityType,
}) => {
  const [zipBtnEnabled, setZipBtnEnabled] = useState(true);

  //To check whether file exists or not
  useEffect(() => {
    const checkBlobSize = async () => {
      try {
        const response = await axios.get(
          `${CHECK_EXCEPTION_REPORT}?projectName=${projectName}&participantFullName=${participantName}&projectCycleId=${projectCycleId}&enrolmentType=${fetchActivity(
            activityType,
          ).toUpperCase()}`,
        );
        setZipBtnEnabled(response?.data);
      } catch (error) {
        //intentionally setting to false
        setZipBtnEnabled(false);
      }
    };
    checkBlobSize();
  }, []);

  const handleDownloadClick = () => {
    window.open(pdfUrl, '_blank');
  };

  const downloadCSVSheet = () => {
    const reqBody = {
      participantFullName: participantName,
      projectCycleId,
      projectName,
      enrolmentType: fetchActivity(activityType),
    };
    axios
      .post(GET_EXCEPTION_REPORT, reqBody, BLOB_RESPONSE)
      .then((response) => {
        const blob = new Blob([response?.data], {
          type: 'application/zip',
        });

        if (response?.data === null || blob.size === 0) {
          displayToast('error', TOAST_MESSAGE.NO_DATA_ERROR);
        } else {
          downloadFileWithBlob(
            blob,
            `${projectName}_${participantName}_${activityType}_exception_report.zip`,
          );
        }
      })
      .catch(() => {
        displayToast('error', TOAST_MESSAGE.DOWNLOAD_ERROR);
      });
  };

  return (
    <>
      <HeaderStyle>
        <Button sx={[secondaryButtonStyle]} onClick={handleDownloadClick}>
          <DownloadIcon style={PdfDownloadButtonStyle} />
          {PAGE_CONTENT.downloadPdf}
        </Button>
        {zipBtnEnabled ? (
          <Button sx={[secondaryButtonStyle]} onClick={downloadCSVSheet}>
            <DownloadIcon style={PdfDownloadButtonStyle} />

            {PAGE_CONTENT.exception_report}
          </Button>
        ) : (
          <Button
            sx={disabledButtonStyle}
            onClick={downloadCSVSheet}
            disabled={!zipBtnEnabled}>
            <DisabledDownloadIcon style={PdfDownloadButtonStyle} />

            {PAGE_CONTENT.exception_report}
          </Button>
        )}
      </HeaderStyle>
      <PDFViewerSx>
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}#toolbar=0`}
            width="40%"
            style={{
              border: 'none',
            }}
          />
        )}
      </PDFViewerSx>
      <FooterWrapper>
        <Footer
          isParticipantLanding={false}
          isParticipantMenu={false}
          isInActivityPage={false}
        />
      </FooterWrapper>
    </>
  );
};

PdfComponent.propTypes = {
  pdfUrl: PropTypes.string,
  projectName: PropTypes.string,
  projectCycleId: PropTypes.string,
  participantName: PropTypes.string,
  activityType: PropTypes.string,
};

export default PdfComponent;
