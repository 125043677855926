import { isNotNill } from 'utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';

export const PAGE_CONTENT = {
  form_heading: 'Enter farm information',
  planned_and_historical_practices: 'Planned and Historical Practices',
  IN_PROGRESS: 'In Progress',
  PARTICIPANT_CONTRIBUTOR: 'PARTICIPANT_CONTRIBUTOR',
  COMPLETED: 'Completed',
  farm_name_label: 'Farm name',
  farm_name_placeholder: 'Type the name of the farm',
  farm_name_primary_error_message: 'A farm name is required.',
  farm_name_repeated_error_msg:
    'The farm name entered already exists. Please enter a new farm name.',
  FSA_farm_ID_label: 'Farm FSA ID',
  FSA_farm_placeholder: 'Type farm FSA ID',
  FSA_farm_ID_tooltip:
    'Ensure your response is consistent with the information submitted to the USDA.',
  FSA_farm_ID_primary_error_message: 'A FSA ID is required.',
  primary_operator_name_label: 'Primary operator name',
  primary_operator_name_placeholder: 'Type name of the primary operator',
  primary_operator_name_primary_error_message:
    'A primary operator name is required.',
  total_farm_acreage_label: 'Total farm area',
  total_farm_acreage_placeholder: 'Type area',
  total_farm_sub_label: 'acres',
  total_farm_acreage_primary_error_message: 'A total farm area is required.',
  row: 'row',
  udsa_label:
    'Is this farm USDA-certified organic or USDA-certified transitioning organic?',
  udsa_primary_error_message:
    'The status of USDA-certified organic selection is required.',
  csaf_label:
    'Have Climate Smart Agriculture and Forestry (CSAF) practices been implemented on this farm?',
  csaf_primary_error_message:
    'The implementation status of CSAF practices selection is required.',
  funding_main_label:
    'Has this farm received any of the following forms of funding for the implementation of CSAF practices?',
  federal_funding_label: 'Federal funding',
  federal_funding_primary_error_message:
    'The status of federal funding received selection is required.',
  state_local_funding_label: 'State + local funding',
  state_local_funding_primary_error_message:
    'The status of state + local funding received selection is required.',
  non_profit_funding_label: 'Non-profit funding',
  non_profit_funding_primary_error_message:
    'The status of non-profit funding received selection is required.',
  market_incentives_label: 'Market incentives',
  market_incentives_primary_error_message:
    'The status of market incentives received selection is required.',
  fms_label: 'Do you use a Farm Management System (FMS) on this farm?',
  fms_error_message: 'The use of an FMS on this farm selection is required.',
  fms_list_label: 'Provide the Farm Management System(s) used below.',
  fms_list_placeholder: 'Type name of FMS',
  fms_list_primary_error_message:
    'If yes was selected for use of FMS then an FMS name is required.',
  add_fms_button_label: 'FMS',
  fms_tooltip_content:
    "A farm management system is a tool that helps farmers organize their activities, keep track of their resources, and make better decisions to improve their farm's productivity. Examples include John Deere, AgriEdge, and Climate Fieldview.",
  soil_sampling_label: 'Has soil sampling been performed on this farm?',
  soil_sampling_primary_error_message:
    'The status of soil sampling performed selection is required.',
  agronomic_advisor_used_or_not_label:
    'Is an agronomic advisor used on this farm?',
  agronomic_advisor_used_or_not_primary_error_message:
    'The status of agronomic advisor used selection is required.',
  agronomist_organization_label: 'Agronomist organization',
  agronomist_organization_placeholder: 'Type name of agronomist organization',
  agronomist_organization_primary_error_message:
    'If yes was selected for agronomic advisor used then a agronomist organization name is required.',
  total_farm_acerage_specification_label:
    'Out of the total farm acreage, please specify the following:',
  cropland_acerage_placeholder: 'Cropland acreage',
  cropland_acerage_label: 'acres are used as cropland',
  cropland_acerage_primary_error_message: 'A cropland acreage is required.',
  cropland_acerage_heading_label: 'How many acres used as cropland?',
  livestock_acerage_placeholder: 'Livestock acreage',
  livestock_acerage_label: 'acres have livestock present',
  livestock_acerage_primary_error_message: 'A livestock acreage is required.',
  livestock_button_label: 'Livestock',
  forest_acerage_placeholder: 'Forest acreage',
  forest_acerage_label: 'acres are considered forest-land',
  forest_acerage_primary_error_message: 'A forest acreage is required.',
  cover_crops_label: 'Have cover crops been implemented on this farm?',
  cover_crops_primary_error_message:
    'The implementation status of cover crops selection is required.',
  implemented_since_label: 'Implemented since',
  implemented_cover_crops_primary_error_message:
    'The implementation of cover crops date is required.',
  tillage_label:
    'Have no-till or minimum tillage been implemented on this farm?',
  tillage_primary_error_message:
    'The implementation status of no-till or minimum tillage selection is required.',
  implemented_tillage_primary_error_message:
    'The implementation of no-till or minimum tillage date is required.',
  variable_split_label:
    'Has variable or split-rate fertilizer been implemented?',
  farm_submit_button_label: 'Save and continue to field enrollment',
  udsa_tooltip:
    'USDA‐certified organic means that the farm has been certified by an accredited organic certifying agent or is transitioning to USDA‐certified organic by not using any of the prohibited substances. Yes means that some or all of the farm is certified organic or transitioning to certified organic. No means that no part of the farm is certified organic or transitioning to certified organic.',
  csaf_tooltip: (
    <div>
      CSAF practices should be considered if they’ve been implemented in the
      past 10 years or since the current primary operator took control
      (whichever time period is shorter).
      <br></br>
      <br></br>
      Learn more about CSAF practices{' '}
      <a
        href="https://www.nrcs.usda.gov/sites/default/files/2023-01/CSAF%20Mitigation%20Activities_2023.pdf"
        target="_blank"
        rel="noreferrer">
        here
      </a>
      .
    </div>
  ),
  federal_funding_tooltip:
    'Federal funds are defined as being from Projects including, but not limited to, those from the Natural Resources Conservation Service ((NRCS), including through Environmental, Quality Incentives Project (EQIP), Conservation Stewardship Project (CSP), Regional Conservation Partnership Project (RCPP), or related Projects), the Farm Service Agency Conservation Reserve Project (CRP), as well as funds from other USDA Projects or other federal agencies.',
  state_local_funding_tooltip:
    'State or local funds are those from state departments of agriculture or other state agencies, local water quality districts or local agencies.',
  non_profit_funding_tooltip:
    'Non‐profit funds are those offered directly from a non‐profit organization to a producer.',
  market_incentives_tooltip:
    'Market incentives include premiums paid by a commodity buyer or by a consumer based on branding or labeling as a climate‐smart commodity.',
  success: 'success',
  success_msg: 'Form details saved successfully.',
  error_msg:
    'Form details failed to save due to technical error. Please try again.',
  update_msg: ' was successfully updated.',
  error: 'error',
  yes: 'Yes',
  acerage_width: '50%',
  fsa_id_width: '22%',
  medium: 'medium',
  contained: 'contained',
  twenty_three_percent: '23%',
  ninety_five_percent: '95%',
  hundered_percent: '100%',
  margin_right: '1.625rem',
  zero_point_two_rem: '0.2rem',
  display_flex: 'flex',
  zero_rem: '0rem',
  completed: 'Completed',
  in_progress: 'In Progress',
  not_started: 'Not Started',
  fsaId_type_label:
    'Is this FSA ID associated with an Individual or an Entity?',
  fsaId_type_required_error: 'An FSA ID association selection is required.',
  fsaId_type_tooltip: (
    <span>
      Ensure your response is consistent with the information provided in Box 2B
      of the{' '}
      <a
        href="https://www.farmers.gov/sites/default/files/form-ad2047-10-28-2021-v01.pdf"
        target="_blank"
        rel="noreferrer">
        Customer Data Worksheet
      </a>{' '}
      submitted to the USDA.
    </span>
  ),
  entity_name_label: 'Entity name',
  entity_name_placeholder: 'Type name of the entity',
  entity_name_required_error: 'A entity name is required.',
  entity_name_tooltip: (
    <span>
      Please ensure that the Entity Name reported below is consistent with the
      information provided in Part A Box 1 of the{' '}
      <a
        href="https://www.farmers.gov/sites/default/files/2020-09/strp/CCC-902E%20Farm%20Operating%20Plan%20for%20Entities_v3.pdf"
        target="_blank"
        rel="noreferrer">
        Farm Operating Plan
      </a>{' '}
      for an Entity submitted to the USDA. If there are multiple entities
      associated with this FSA ID, include all that apply in the text box below.
    </span>
  ),
  first_name_label: 'First name',
  first_name_placeholder: 'Type first name',
  first_name_required_error: 'A first name is required.',
  first_name_tooltip: (
    <span>
      Please ensure that the first and last names reported below are consistent
      with the information provided in Part A Box 1 of the Farm{' '}
      <a
        href="https://www.fsa.usda.gov/Assets/USDA-FSA-Public/usdafiles/State-Offices/New-Jersey/pdfs/CCC0902I_190416V01.pdf"
        target="_blank"
        rel="noreferrer">
        Operating Plan for an Individual
      </a>{' '}
      submitted to the USDA.
    </span>
  ),
  last_name_label: 'Last name',
  last_name_placeholder: 'Type last name',
  last_name_required_error: 'A last name is required.',
  mailing_address_label: 'Farm mailing address',
  mailing_address_placeholder: 'Mailing address',
  mailing_address_required_error: 'A farm mailing address is required.',
  city_label: 'City',
  city_placeholder: 'City',
  city_required_error: 'City is required.',
  county_label: 'County',
  county_placeholder: 'County',
  county_required_error: 'County required.',
  state_label: 'State',
  state_placeholder: 'State',
  state_required_error: 'State required.',
  zip_code_label: 'Zip code',
  zip_code_placeholder: 'Type zip code',
  zip_code_required_error: 'Zip code required.',
  is_usda_certified_label:
    'Are any of the fields that will be enrolled in the project currently USDA‐certified organic or transitioning to USDA‐certified organic?',
  is_usda_certified_required_error:
    'The status of farm’s fields USDA-certified organic selection is required.',
  csaf_practices_label:
    'To what extent were these CSAF practices implemented on this farm?',
  csaf_practices_placeholder: 'Select option',
  csaf_practices_required_error:
    'The extent of the CSAF practices implemented is required.',
  livestock_type_label: 'Provide the top 3 types of livestock',
  livestock_type_placeholder: 'Select livestock',
  livestock_type_required_error: 'Atleast 1 livestock selection is required',
  livestock_heading_label: 'Are you raising livestock on this farm?',
  livestock_error_state:
    'At least one type of livestock (with headcount) is required since you answered “Yes” above.',
  head_count_label: 'Head count',
  head_count_placeholder: 'Type amount',
  head_count_required_error: 'Head count is required',
  cropland_acerage_secondary_error_message:
    'A cropland area must be greater than 0.',
  cropland_acerage_third_error_message:
    "Cropland area can't exceed total farm area. Please adjust as necessary.",
  livestock_acerage_secondary_error_message:
    "Livestock area can't exceed total farm area. Please adjust as necessary.",
  forest_acerage_secondary_error_message:
    "Forest area can't exceed total farm area. Please adjust as necessary.",
  total_cropland_and_forest_area_error_message:
    "Total sum of cropland and forest areas can't exceed total farm area. Please adjust as necessary.",
  warning: 'warning',
  add_livestock_btn: '+ Add livestock',
  carbon_rights_label:
    'Have the carbon rights associated with this farm been transferred to other party(ies)?',
  carbon_rights_error: 'A carbon rights association selection is required.',
  fieldsAreaExceedingFarmAreaError:
    'Total sum of field areas added exceeds the total farm area. Please adjust farm area as necessary.',
  deleteModalCancelText: 'Cancel',
  deleteModalAcceptText: 'Delete',
  deleteSuccessSuffix: 'was successfully deleted.',
  owner_disclaimer_text:
    'Individuals added as participant contributors will receive an email with an access link to this farm’s enrollment instance where they can enter farm and field info on your behalf.',
  close: 'close.',
  entity: 'Entity',
  someone_else: 'Someone else',
  participant_owner: 'Participant owner',
  add_participant_contributors_label: 'Assign participant contributor',
  add_participant_contributor_heading: 'Add participant contributor',
  deleteErrorSuffix:
    "couldn't be deleted due to a technical error. Please try again.",
  deletePropsType: 'farm',
  NOTE: 'Note: ',
  ADD_PARTICIPANT_CONTRIBUTORS_PLACEHOLDER:
    'Select name to add existing participant contributors',
  FARM_LOCKED: 'Form locked',
  FORM_UNLOCKED: 'Form unlocked',
  DESABLE_SWITCH_TOOLTIP:
    'This farm has been locked for editing by another user.',
};

export const deleteModalInit = {
  open: false,
  title: '',
  subtitle: '',
  deleteProps: null,
};

export const FALSE = false;

export const YesOrNoOptions = [
  {
    itemLabel: 'Yes',
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    isDisabled: false,
  },
];

export const FsaIdOptions = ['Participant owner', 'Someone else'];

export const FsaIdTypes = [
  {
    itemLabel: 'Participant owner',
    isDisabled: false,
  },
  {
    itemLabel: 'Entity',
    isDisabled: false,
  },
  {
    itemLabel: 'Someone else',
    isDisabled: false,
  },
];

export const YesNoOrIdontknowOptions = [
  {
    itemLabel: 'Yes',
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    isDisabled: false,
  },
  {
    itemLabel: "I don't know",
    isDisabled: false,
  },
];

export const formFieldsNames = {
  farmName: 'farmName',
  fsaId: 'fsaId',
  fsaIdType: 'fsaIdType',
  entityName: 'entityName',
  firstName: 'firstName',
  lastName: 'lastName',
  mailingAddress: 'mailingAddress',
  city: 'city',
  county: 'county',
  state: 'state',
  zipCode: 'zipCode',
  isUSDACertified: 'isUSDACertified',
  primaryOperatorName: 'primaryOperatorName',
  totalFarmAcreage: 'totalFarmAcreage',
  /* organicStatus: 'organicStatus',
  csafExperience: 'csafExperience',
  csafPractices: 'csafPractices',
  csafFederalFunding: 'csafFederalFunding',
  csafStateFunding: 'csafStateFunding',
  csafNonProfitFunding: 'csafNonProfitFunding',
  csafMarketIncentives: 'csafMarketIncentives', */
  fmsUse: 'fmsUse',
  fmsList: 'fmsList',
  soilSampling: 'soilSampling',
  agronomistUse: 'agronomistUse',
  agronomistUseOrganization: 'agronomistUseOrganization',
  croplandAcerage: 'croplandAcerage',
  /*  livestockAcerage: 'livestockAcerage',
  forestAcerage: 'forestAcerage', */
  carbonRightsAssociated: 'carbonRightsAssociated',
  isLivestockRaised: 'isLivestockRaised',
};

export const formModal = {
  farmName: ' ',
  fsaId: '',
  fsaIdType: '',
  entityName: '',
  firstName: '',
  lastName: '',
  mailingAddress: '',
  city: '',
  county: '',
  state: '',
  zipCode: '',
  isUSDACertified: '',
  totalFarmAcreage: '',
  /*  organicStatus: '',
  csafExperience: '',
  csafPractices: '',
  csafFederalFunding: '',
  csafStateFunding: '',
  csafNonProfitFunding: '',
  csafMarketIncentives: '', */
  fmsUse: '',
  fmsList: [],
  soilSampling: '',
  agronomistUse: '',
  agronomistUseOrganization: '',
  croplandAcerage: '',
  /*   livestockAcerage: '',
  forestAcerage: '', */
  isApiPopulated: false,
  carbonRightsAssociated: '',
  csafUpdatedBySystem: false,
  isLivestockRaised: '',
};

export const errorInitialState = {
  farmNameRequiredError: false,
  fsaIdRequiredError: false,
  fsaIdTypeRequiredError: false,
  entityNameRequiredError: false,
  firstNameRequiredError: false,
  lastNameRequiredError: false,
  mailingAddressRequiredError: false,
  cityRequiredError: false,
  countyRequiredError: false,
  stateRequiredError: false,
  zipCodeRequiredError: false,
  zipCodeInvalidError: false,
  isUSDACertifiedRequiredError: false,
  totalFarmAcreageRequiredError: false,
  /* organicStatusRequiredError: false,
  csafExperienceRequiredError: false,
  csafPracticesRequiredError: false,
  csafFederalFundingRequiredError: false,
  csafStateFundingRequiredError: false,
  csafNonProfitFundingRequiredError: false,
  csafMarketIncentivesRequiredError: false, */
  fmsUseRequiredError: false,
  fmsListRequiredError: false,
  soilSamplingRequiredError: false,
  agronomistUseRequiredError: false,
  agronomistUseOrganizationRequiredError: false,
  croplandAcerageRequiredError: false,
  livestockAcerageRequiredError: false,
  forestAcerageRequiredError: false,
  croplandAcerageGreaterThanZeroError: false,
  croplandAcerageGreaterThanTotalFarmError: false,
  forestAcerageGreaterThanTotalFarmError: false,
  livestockAcerageGreaterThanTotalFarmError: false,
  croplandAndForestAcerageSumGreaterThanFarmAreaError: false,
  carbonRightsAssociatedError: false,
  liveStockRequiredError: false,
  livestockTypeError: false,
};

export const validateFsaIdType = (formData) => {
  return (
    (formData.fsaIdType?.trim() === 'Someone else' &&
      formData.firstName?.trim() &&
      formData.lastName?.trim()) ||
    (formData.fsaIdType?.trim() === 'Entity' && formData.entityName?.trim()) ||
    formData.fsaIdType?.trim() === 'Participant owner' ||
    formData.fsaIdType?.trim() === ''
  );
};

/* export const validateCsafExperience = (formData) => {
  return (
    formData.csafExperience?.trim() === "I don't know" ||
    ((formData.csafExperience?.trim() === 'No' ||
      (formData.csafExperience?.trim() === 'Yes' &&
        formData.csafPractices?.trim())) &&
      (formData.csafExperience?.trim() === 'No' ||
        (formData.csafExperience?.trim() === 'Yes' &&
          formData.csafFederalFunding?.trim())) &&
      (formData.csafExperience?.trim() === 'No' ||
        (formData.csafExperience?.trim() === 'Yes' &&
          formData.csafStateFunding?.trim())) &&
      (formData.csafExperience?.trim() === 'No' ||
        (formData.csafExperience?.trim() === 'Yes' &&
          formData.csafNonProfitFunding?.trim())) &&
      (formData.csafExperience?.trim() === 'No' ||
        (formData.csafExperience?.trim() === 'Yes' &&
          formData.csafMarketIncentives?.trim())))
  );
};
 */
/**
 *
 * @param formData has the web form data
 * @param livestockTypes is required for a validation check
 * @param additionalValidationFlag is a flag which can be used to pass additional validation checks from parent.
 * @returns
 */
export const validateFormFields = (
  formData,
  livestockTypes,
  additionalValidationFlag,
  uniqueName,
) => {
  const filteredLivestockTypes = livestockTypes.filter((livestockType) => {
    return livestockType.livestockTypeValue !== '';
  });

  let liveStockDataValidated = false;
  if (formData.isLivestockRaised?.trim() === PAGE_CONTENT.yes) {
    liveStockDataValidated =
      filteredLivestockTypes.length > 0 &&
      filteredLivestockTypes.every((livestockType) => {
        return isNotNill(livestockType.headCountValue);
      });
  } else {
    liveStockDataValidated = true;
  }

  return (
    uniqueName &&
    (typeof formData.farmName === 'string'
      ? formData.farmName?.trim()
      : formData.farmName?.label?.trim()) &&
    (typeof formData.fsaId === 'string'
      ? formData.fsaId?.trim()
      : formData.fsaId?.label?.trim()) &&
    validateFsaIdType(formData) &&
    formData.mailingAddress?.trim() &&
    formData.city?.trim() &&
    formData.county?.trim() &&
    formData.state?.trim() &&
    formData.zipCode?.trim() &&
    formData.zipCode?.trim().length === 5 &&
    additionalValidationFlag &&
    formData.totalFarmAcreage?.trim() &&
    /*  formData.organicStatus?.trim() &&
    (formData.organicStatus?.trim() === 'No' ||
      (formData.organicStatus?.trim() === 'Yes' &&
        formData.isUSDACertified?.trim())) &&
    formData.csafExperience?.trim() &&
    validateCsafExperience(formData) && */
    formData.fmsUse?.trim() &&
    (formData.fmsUse?.trim() === 'No' ||
      (formData.fmsUse?.trim() === 'Yes' &&
        formData.fmsList?.every((value) => value !== ''))) &&
    formData.soilSampling?.trim() &&
    formData.agronomistUse?.trim() &&
    (formData.agronomistUse?.trim() === 'No' ||
      (formData.agronomistUse?.trim() === 'Yes' &&
        formData.agronomistUseOrganization?.trim())) &&
    formData.croplandAcerage?.trim() &&
    parseInt(formData.totalFarmAcreage?.trim()) >=
      parseInt(formData.croplandAcerage?.trim()) &&
    parseFloat(formData.croplandAcerage?.trim()) > 0 &&
    /*     formData.livestockAcerage?.trim() &&
    parseInt(formData.totalFarmAcreage?.trim()) >=
      parseInt(formData.livestockAcerage?.trim()) &&
    formData.forestAcerage?.trim() &&
    parseInt(formData.totalFarmAcreage?.trim()) >=
      parseInt(formData.forestAcerage?.trim()) &&
    parseInt(formData.totalFarmAcreage?.trim()) >=
      parseInt(formData.forestAcerage?.trim()) +
        parseInt(formData.croplandAcerage?.trim()) && */
    formData.carbonRightsAssociated?.trim() &&
    liveStockDataValidated
  );
};

export const checkFieldIsNotEmpty = (value) => {
  if (value !== null && value !== undefined) {
    return value?.trim() && FALSE;
  } else {
    return FALSE;
  }
};

export const convertYesNoIntoBoolean = (value) => {
  if (value === 'Yes') return true;
  else if (value === 'No') return false;
};

export const convertBooleanToYesNo = (value) => {
  if (value === true) return 'Yes';
  else if (value === false) return 'No';
  else return '';
};

export const regexMatchForAcres = (value) => {
  return value.match(/^\d*(\.\d{0,4})?$/) || value === '';
};

export const stringToArray = (value) => {
  if (value === null || value === undefined) return [];
  else return value.split(',');
};

export const csafPracticesOptions = [
  { label: 'Never used', value: 'Never used' },
  {
    label: 'Used on less than 25% of the operation',
    value: 'Used on less than 25% of the operation',
  },
  {
    label: 'Used on 25-50% of the operation',
    value: 'Used on 25-50% of the operation',
  },
  {
    label: 'Used on 51-75% of the operation',
    value: 'Used on 51-75% of the operation',
  },
  {
    label: 'Used on 75% or more of the operation',
    value: 'Used on 75% or more of the operation',
  },
];

export const livestockTypeInitialState = [
  {
    id: uniqueId(),
    livestockTypeValue: '',
    headCountValue: '',
    livestockTypeError: false,
    headCountError: false,
  },
];

export const farmEnrolment = [
  {
    FARM_AREA: [
      {
        dataElementId: 15,
        dataElementName: 'Farm area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Farm enrollment',
      },
    ],
  },
  {
    CROPLAND_AREA: [
      {
        dataElementId: 16,
        dataElementName: 'Cropland area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Farm enrollment',
      },
    ],
  },
];
