import { getAuth } from 'firebase/auth';

export const getCurrentUserData = () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  return currentUser;
};

export function getDisplayName() {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  return `${currentUser?.email}`;
}
