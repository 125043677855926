import styled from 'styled-components';

export const HeaderStyle = styled.header`
  display: flex;
  justify-content: end;
  height: 1.75rem;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const PDFViewerSx = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  border: none;
`;

export const PdfDownloadButtonStyle = {
  marginRight: '0.4rem',
};

export const FooterWrapper = styled.div`
  padding-top: 15.5px;
`;
