import { checkTernaryCondition } from 'utils/helper';

export const MODAL_CONTENT = {
  heading: 'Tillage',
  updated_heading: 'Add Tillage',
  tillagePracticeField: 'tillagePractice',
  tillagePracticePlaceholder: 'Select tillage practice',
  tillagePracticeLabel: 'Tillage practice',
  tillageDatesField: 'tillageDates',
  noTillageOption: 'No tillage',
  reducedTill: 'Reduced tillage',
  noTill: 'No tillage',
  duplicate_date_error:
    'Date selected has already been applied. Please select a different date.',
  tillage_tooltip_msg:
    'To change any of the disabled fields after saving, you must delete and re-enter the record.',
  tillageDatePickerLabel: 'Tillage date(s)',
  tillageDatePickerPlaceholder: 'Select Date',
  addTillageDateLinkText: ' Tillage date',
  surfaceDisturbanceField: 'surfaceDisturbance',
  surfaceDisturbanceLabel: 'Surface disturbance',
  surfaceDisturbancePlaceholder: 'Type percentage',
  depthOfTillageField: 'depthOfTillage',
  depthOfTillageLabel: 'Depth of tillage',
  depthOfTillagePlaceholder: 'Type depth',
  depthOfTillageErrorMsg: 'Depth of tillage should be greater than 0',
  depthOfTillageErrorMsg2:
    'You selected deep tillage (deeper than 10 in) but you entered a tillage depth less than 10 in. Please update your tillage depth.',
  depthOfTillageErrorMsg3:
    'You selected medium tillage (5-10 in) but you entered a tillage depth outside of these bounds. Please update your tillage depth.',
  depthOfTillageErrorMsg4:
    'You selected shallow tillage (1-4 in) but you entered a tillage depth greater than 4 in. Please update your tillage depth.',
  surfaceDisturbanceErrorMsg:
    'Surface disturbance should not be greater than 100',
  unique_combination_error_heading:
    'Please review the prior entries and update the details on the form to submit a new unique entry.',
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
  inch_unit: 'in',
  percent_unit: '%',
  tillage_date_error:
    'Tillage date can only occur within the baseline year and up to 1 year prior.',
  invalid_date: 'Invalid date',
  tillage_type_deep_practice: 'Deep tillage (deeper than 10 in)',
  tillage_note_title: 'Note:',
  tillage_reduced_note:
    'Because you selected “Reduced till” above, you cannot select “no till” as a tillage practice here.',
  tillage_noTill_note:
    'Because you selected “No till” above, you cannot select “Reduced till” as a tillage practice here.',
  MEDIUM_TILLAGE: 'Medium tillage (5 - 10 in)',
  SHALLOW_TILLAGE: 'Shallow tillage (1 - 4 in)',
  DEEP_TILLAGE: 'Deep tillage (deeper than 10 in)',
};

export const initialTillageModalData = {
  tillagePractice: '',
  customTillagePractice: '',
  tillageDates: [null],
  surfaceDisturbance: '',
  depthOfTillage: '',
};

export const initialTillageModalErrors = {
  tillagePractice: false,
  tillageDates: { flag: false, indexes: [] },
  surfaceDisturbance: false,
  depthOfTillage: false,
  deepTillageError: false,
  mediumTillageError: false,
  shallowTillageError: false,
};
export const formFieldNames = {
  tillagePractice: 'tillagePractice',
  customTillagePractice: 'customTillagePractice',
  tillageDates: 'tillageDates',
  surfaceDisturbance: 'surfaceDisturbance',
  depthOfTillage: 'depthOfTillage',
};
export const findTillageId = (dropdownlist, tillagePractice) => {
  return dropdownlist.find(
    (tillageType) => tillageType.label === tillagePractice,
  )?.value;
};
export function checkTillagePracticeValidations(
  value,
  formValue,
  tillageTypeDropdown,
  MODAL_CONTENT,
  includesZero = true,
) {
  return (
    (+formValue?.tillagePractice ===
      +findTillageId(tillageTypeDropdown, MODAL_CONTENT.DEEP_TILLAGE) &&
      checkTernaryCondition(includesZero, value >= 0, value > 0) &&
      value <= 10) ||
    (+formValue?.tillagePractice ===
      +findTillageId(tillageTypeDropdown, MODAL_CONTENT.MEDIUM_TILLAGE) &&
      checkTernaryCondition(
        includesZero,
        value < 5 || value > 10,
        (value > 0 && value < 5) || value > 10,
      )) ||
    (+formValue?.tillagePractice ===
      +findTillageId(tillageTypeDropdown, MODAL_CONTENT.SHALLOW_TILLAGE) &&
      checkTernaryCondition(
        includesZero,
        value < 1 || value > 4,
        (value > 0 && value < 1) || value > 4,
      ))
  );
}
