import { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import {
  MenuSx,
  menuItemDisabledSx,
  menuItemSx,
  IconButtonSx,
} from './CustomOptionsButton.style';
import { Divider } from '@mui/material';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { ReactComponent as EditIconWithHoverEffect } from '../../assets/icons/EditIconWithHoverEffect.svg';
import { checkTernaryCondition } from 'utils/helper';
import { ACTIVITY_TYPE } from './ActivityOptions/ActivityOptions.constants';
import {
  MENU_ITEM_INDICATORS,
  menuOptions,
} from './SimpleOptionsButton.constant';

const SimpleOptionsButton = ({
  editId,
  onEdit,
  onDelete,
  removeOrDelete,
  enterBaselineOptionDisabled,
  onEnterBaselineClick,
  enterBaselineLabel,
  hasContract,
  addContactText,
  onContractClick,
  reviewEnrollmentLabel,
  onClickReviewEnrollment,
  reviewBaselineLabel,
  onClickReviewBaseline,
  isInitiateEnrollmentEnabled,
  isReviewEnrollmentEnabled,
  isReviewBaselineReportingEnabled,
  isInitiateBaselineReportingEnabled,
  isAddContractEnabled,
  customOptions = [],
  setInactive,
  simpleOptionsSx,
  onUpdateAccountStatus = () => null,
  enrollOptionDisabled = false,
  onEnroll = () => null,
  reviewBaselineDisabled = false,
  disableIconButton = false,
  hasEditOption = true,
  hasUpdateStatusAccount = false,
  hasDeleteOption = true,
  editIcon = false,
  horizontalIcon = false,
  separatorLine = false,
  editOptionDisabled = false,
  updateStatusAccountOptionDisabled = false,
  deleteOptionDisabled = false,
  dataTestId = 'simple-options-button',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /**
   * @description handle click event of the icon button
   * @param {Event} event
   * @return {void}
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  /**
   * @description method to close the menu
   * @returns {void}
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * @description method that returns button label
   * @returns {String}
   */
  const getButtonLabel = () => {
    if (setInactive) {
      return ACTIVITY_TYPE.SET_INACTIVE;
    } else if (removeOrDelete) {
      return ACTIVITY_TYPE.REMOVE;
    } else return ACTIVITY_TYPE.DELETE;
  };

  /**
   * @description This function is used to handle the menu item click event
   * @param {String} menuItem
   * @returns {void}
   */
  const menutItemsOnClickHandler = (menuItem) => {
    switch (menuItem) {
      case MENU_ITEM_INDICATORS.EDIT:
        onEdit(editId);
        break;
      case MENU_ITEM_INDICATORS.UPDATE_USER_STATUS:
        onUpdateAccountStatus();
        break;
      case MENU_ITEM_INDICATORS.DELETE:
        onDelete();
        break;
      case MENU_ITEM_INDICATORS.INITIATE_ENROLLMENT:
        onEnroll();
        break;
      case MENU_ITEM_INDICATORS.REVIEW_ENROLLMENT:
        onClickReviewEnrollment();
        break;
      case MENU_ITEM_INDICATORS.REVIEW_BASELINE_REPORTING:
        onClickReviewBaseline();
        break;
      case MENU_ITEM_INDICATORS.INITIATE_BASELINE_REPORTING:
        onEnterBaselineClick();
        break;
      case MENU_ITEM_INDICATORS.HAS_ENABLED_ADD_CONTRACT:
        onContractClick();
        break;
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        data-testid={dataTestId}
        disabled={disableIconButton}
        id="basic-button"
        aria-controls={checkTernaryCondition(open, 'basic-menu', undefined)}
        aria-haspopup="true"
        aria-expanded={checkTernaryCondition(open, 'true', undefined)}
        onClick={handleClick}
        sx={{
          ...IconButtonSx,
          ...simpleOptionsSx,
          opacity: checkTernaryCondition(disableIconButton, 0.6, 1),
        }}>
        {checkTernaryCondition(
          editIcon,
          <EditIconWithHoverEffect />,
          checkTernaryCondition(
            horizontalIcon,
            <MoreHorizIcon />,
            <MoreVertIcon />,
          ),
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={MenuSx}>
        {hasEditOption && (
          <MenuItem
            disabled={editOptionDisabled}
            sx={menuItemSx}
            onClick={() => menutItemsOnClickHandler(MENU_ITEM_INDICATORS.EDIT)}>
            {menuOptions.edit}
          </MenuItem>
        )}
        {hasUpdateStatusAccount && (
          <MenuItem
            disabled={updateStatusAccountOptionDisabled}
            sx={checkTernaryCondition(
              updateStatusAccountOptionDisabled,
              menuItemDisabledSx,
              menuItemSx,
            )}
            onClick={() =>
              menutItemsOnClickHandler(MENU_ITEM_INDICATORS.UPDATE_USER_STATUS)
            }>
            {menuOptions.updateUserStatus}
          </MenuItem>
        )}
        {hasDeleteOption && (
          <MenuItem
            disabled={deleteOptionDisabled}
            sx={menuItemSx}
            onClick={() =>
              menutItemsOnClickHandler(MENU_ITEM_INDICATORS.DELETE)
            }>
            {getButtonLabel()}
          </MenuItem>
        )}
        {separatorLine && <Divider />}
        {isInitiateEnrollmentEnabled && (
          <MenuItem
            disabled={enrollOptionDisabled}
            sx={menuItemSx}
            onClick={() =>
              menutItemsOnClickHandler(MENU_ITEM_INDICATORS.INITIATE_ENROLLMENT)
            }>
            {menuOptions.initiateEnrollment}
          </MenuItem>
        )}
        {isReviewEnrollmentEnabled && (
          <MenuItem
            sx={menuItemSx}
            onClick={() =>
              menutItemsOnClickHandler(MENU_ITEM_INDICATORS.REVIEW_ENROLLMENT)
            }>
            {reviewEnrollmentLabel}
          </MenuItem>
        )}
        {isReviewBaselineReportingEnabled && (
          <MenuItem
            disabled={reviewBaselineDisabled}
            sx={menuItemSx}
            onClick={() =>
              menutItemsOnClickHandler(
                MENU_ITEM_INDICATORS.REVIEW_BASELINE_REPORTING,
              )
            }>
            {reviewBaselineLabel}
          </MenuItem>
        )}
        {isInitiateBaselineReportingEnabled && (
          <MenuItem
            disabled={enterBaselineOptionDisabled}
            sx={menuItemSx}
            onClick={() =>
              menutItemsOnClickHandler(
                MENU_ITEM_INDICATORS.INITIATE_BASELINE_REPORTING,
              )
            }>
            {enterBaselineLabel}
          </MenuItem>
        )}
        {hasContract && isAddContractEnabled && (
          <MenuItem
            sx={menuItemSx}
            onClick={() =>
              menutItemsOnClickHandler(
                MENU_ITEM_INDICATORS.HAS_ENABLED_ADD_CONTRACT,
              )
            }>
            {addContactText}
          </MenuItem>
        )}

        {customOptions.map((option) => {
          return (
            !option.hidden && (
              <MenuItem
                key={uniqueId()}
                disabled={option.disabled ?? false}
                sx={menuItemSx}
                onClick={() => {
                  option.clickHandler();
                  handleClose();
                }}>
                {option.itemLabel}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

SimpleOptionsButton.propTypes = {
  editId: PropTypes.string,
  onEdit: PropTypes.func,
  onUpdateAccountStatus: PropTypes.func,
  onDelete: PropTypes.func,
  removeOrDelete: PropTypes.bool,
  enrollOptionDisabled: PropTypes.bool,
  onEnroll: PropTypes.func,
  enterBaselineOptionDisabled: PropTypes.bool,
  onEnterBaselineClick: PropTypes.func,
  enterBaselineLabel: PropTypes.string,
  separatorLine: PropTypes.bool,
  editOptionDisabled: PropTypes.bool,
  updateStatusAccountOptionDisabled: PropTypes.bool,
  deleteOptionDisabled: PropTypes.bool,
  simpleOptionsSx: PropTypes.object,
  hasContract: PropTypes.bool,
  addContactText: PropTypes.string,
  onContractClick: PropTypes.func,
  reviewEnrollmentLabel: PropTypes.string,
  onClickReviewEnrollment: PropTypes.func,
  reviewBaselineLabel: PropTypes.string,
  onClickReviewBaseline: PropTypes.func,
  isInitiateEnrollmentEnabled: PropTypes.bool,
  isReviewEnrollmentEnabled: PropTypes.bool,
  isReviewBaselineReportingEnabled: PropTypes.bool,
  isInitiateBaselineReportingEnabled: PropTypes.bool,
  isAddContractEnabled: PropTypes.bool,
  reviewBaselineDisabled: PropTypes.bool,
  customOptions: PropTypes.array,
  hasEditOption: PropTypes.bool,
  hasUpdateStatusAccount: PropTypes.bool,
  hasDeleteOption: PropTypes.bool,
  editIcon: PropTypes.bool,
  horizontalIcon: PropTypes.bool,
  setInactive: PropTypes.bool,
  disableIconButton: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export default SimpleOptionsButton;
