import React, { useEffect, useMemo, useState } from 'react';
import {
  DropBoxStyle,
  DragAndDropTitle,
  DragAndDropText,
  Subtext,
  DialogBoxButtonStyle,
  AcceptedFileItem,
} from '../../../AddContractModal/AddContractModal.style';
import { FileName, FileNameText } from './UploadSoilSamplingModal.style';
import { SmallUploadWrapper, UploadHelpText } from '.././ActivitiesTab.style';
import DialogBox from 'components/DialogBox/DialogBox.react';
import { useDropzone } from 'react-dropzone';
import uuid from 'react-uuid';

import axios from 'axios';

import {
  getError,
  maxFilesError,
} from '../../../AddContractModal/AddContractModal.content';
import {
  EMPTY_STATE_TEXT_COLOR,
  NEUTRAL_BACKGROUND_COLOR,
  RED,
} from 'theme/GlobalColors';
import { FETCH_PROJECT_CYCLES, UPLOAD_SOIL_SAMPLING_DATA } from 'urls';
import {
  acceptedFilesInfo,
  MODAL_CONTENT,
} from './UploadSoilSamplingModal.content';
import LightTooltip from 'components/LightTooltip';
import PropTypes from 'prop-types';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { checkTernaryCondition, convertDateFormat } from 'utils/helper';
import Loader from 'components/Loader/Loader.react';
import CustomDeleteIcon from './CustomDeleteIcon.react';
const UploadSoilSamplingModal = ({
  dialogState,
  dialogStateHandler,
  projectId,
}) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [projectCycles, setProjectCycles] = useState([]);
  const [selectedProjectCycleId, setSelectedProjectCycleId] = useState(null);
  const [defaultProjectCycleId, setDefaultProjectCycleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFiles && { maxFiles: acceptedFilesInfo.maxFiles }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
      multiple: false,
    }),
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);

  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const isMaxFilesLimitExceeded = useMemo(
    () => uploadedFiles.length + rejectedFiles.length > 1,
    [uploadedFiles, rejectedFiles],
  );
  const clearUploadFileDetailsOnClose = () => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setUploadError({ flag: false, message: '' });
  };
  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler([files[files.length - 1]]);
    }
  };
  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
  };
  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
    setRejectedFiles([]);
  }, [acceptedFiles]);
  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);
  useEffect(() => {
    if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
    }
  }, [rejectedFiles]);

  useEffect(() => {
    if (isMaxFilesLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0]?.errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [isMaxFilesLimitExceeded]);
  useEffect(() => {
    if (dialogState) {
      clearUploadFileDetailsOnClose();
    }
  }, [dialogState]);
  const onClose = () => {
    dialogStateHandler((prevState) => {
      return !prevState;
    });
  };
  const onSubmit = () => {
    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      formData.append('soilSamplingReportFile', uploadedFiles[0].file);
      if (!uploadError.flag) {
        // Axios call
        setLoading(true);
        axios
          .post(
            `${UPLOAD_SOIL_SAMPLING_DATA}/${checkTernaryCondition(
              selectedProjectCycleId === null,
              defaultProjectCycleId,
              selectedProjectCycleId,
            )}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
          .then(() => {
            onClose();
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  useEffect(() => {
    setIsSubmitDisabled(uploadError.flag);
  }, [uploadError]);
  const fetchProjectCycles = () => {
    setLoading(true);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;
        const cyclesData = data.map((cycle) => {
          const endYear = new Date(cycle.cycleEndDate).getFullYear();
          if (endYear === new Date().getFullYear()) {
            setDefaultProjectCycleId(cycle.projectCycleId);
          }
          return {
            label: `${endYear} (${convertDateFormat(
              cycle.cycleStartDate,
            )}-${convertDateFormat(cycle.cycleEndDate)})`,
            value: cycle.projectCycleId,
            cycleYear: endYear,
          };
        });
        setProjectCycles(cyclesData);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchProjectCycles();
  }, []);
  return (
    <DialogBox
      isOpen={dialogState}
      title={MODAL_CONTENT.title}
      isError={uploadError.flag}
      modalTitleSx={{ color: RED, flexGrow: 1 }}
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      acceptCtnLabel={MODAL_CONTENT.uploadButtonLabel}
      declineCtnLabel={MODAL_CONTENT.cancelButtonLabel}
      focusButtonPadding="0.53rem 0.9rem"
      buttonSX={DialogBoxButtonStyle}
      btnDisabled={isSubmitDisabled || uploadedFiles.length === 0}
      buttonGap="0.5rem"
      dialogActions>
      <Loader loading={loading} zIndex={99999} />
      <div>
        <DropBoxStyle>
          <DragAndDropTitle
            isError={
              uploadError.flag || isMaxFilesLimitExceeded
            }></DragAndDropTitle>
        </DropBoxStyle>

        {uploadError.flag === true ||
        isMaxFilesLimitExceeded ||
        uploadedFiles.length === 0 ? (
          <SmallUploadWrapper height={'2.625rem'} padding={'0.625rem'}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} data-testid="fileDropzone" />
              <DragAndDropText>
                {MODAL_CONTENT.dragAndDropText}
                <Subtext>{MODAL_CONTENT.browseText}</Subtext>.
              </DragAndDropText>
            </div>
          </SmallUploadWrapper>
        ) : (
          <SmallUploadWrapper
            height={'2.625rem'}
            padding={'0.625rem'}
            background={NEUTRAL_BACKGROUND_COLOR}>
            <div>
              <DragAndDropText>
                {MODAL_CONTENT.dragAndDropText}
                <Subtext color={EMPTY_STATE_TEXT_COLOR}>
                  {MODAL_CONTENT.browseText}
                </Subtext>
                .
              </DragAndDropText>
            </div>
          </SmallUploadWrapper>
        )}

        {acceptedFilesInfo.infoTextList.map((infoText) => {
          return (
            <UploadHelpText
              key={infoText}
              invisible={uploadError.flag || isMaxFilesLimitExceeded}>
              {infoText}
            </UploadHelpText>
          );
        })}
        {uploadError.flag && (
          <UploadHelpText iserror={true}>{uploadError.message}</UploadHelpText>
        )}
        {uploadedFiles.map((fileItem, index) => (
          <AcceptedFileItem key={fileItem.id}>
            <LightTooltip title={fileItem.file.name}>
              <FileName isError={isMaxFilesLimitExceeded}>
                <FileNameText>{fileItem.file.name}</FileNameText>
              </FileName>
            </LightTooltip>
            <CustomDeleteIcon
              deleteFile={deleteFile}
              index={index}
              setFileType={setUploadedFiles}></CustomDeleteIcon>
          </AcceptedFileItem>
        ))}
        {rejectedFiles.map(({ file, id }, index) => (
          <AcceptedFileItem key={id}>
            <LightTooltip title={file.name}>
              <FileName isError={true}>
                <FileNameText>{file.name}</FileNameText>
              </FileName>
            </LightTooltip>
            <CustomDeleteIcon
              deleteFile={deleteFile}
              index={index}
              setFileType={setRejectedFiles}></CustomDeleteIcon>
          </AcceptedFileItem>
        ))}
        <DropDown
          label={MODAL_CONTENT.projectCycleLabel}
          width={'14.5rem'}
          height={'2rem'}
          value={
            selectedProjectCycleId !== null
              ? selectedProjectCycleId
              : defaultProjectCycleId
          }
          onUpdate={(event) => {
            setSelectedProjectCycleId(event.target.value);
          }}
          paddingForDropdownSelect={'0.406rem 1.438rem 0.406rem 0.5rem'}
          valueWidth={'14rem'}
          valueHeight={'1.188rem'}
          showLabelAsValue={true}
          dropdownlist={projectCycles}></DropDown>
      </div>
    </DialogBox>
  );
};

UploadSoilSamplingModal.propTypes = {
  dialogState: PropTypes.bool,
  dialogStateHandler: PropTypes.func,
  projectId: PropTypes.number,
};

export default UploadSoilSamplingModal;
