import { TabsStyle } from './ActivityReporting.style';
import { ReactComponent as YellowStatus } from '../../../assets/icons/yellowStatus.svg';
import { ReactComponent as GreenStatus } from '../../../assets/icons/greenStatus.svg';
import { ReactComponent as NotStartedStatus } from '../../../assets/icons/notStartedIcon.svg';
import { ReactComponent as DisabledIcon } from '../../../assets/icons/disabled.svg';
import { REPORTING_STATUS } from 'utils/config';

export const PAGE_CONTENT = {
  home: 'Home',
  completed: 'Completed',
  accepted: 'Accepted',
  in_progress: 'In Progress',
  activity_reporting: 'Activity reporting',
  heading: 'Establish Activity Reporting',
  leftSectionHeading: 'Farms',
  success: 'success',
  success_msg: 'Form details saved successfully.',
  error: 'error',
  error_msg:
    'Form details failed to save due to technical error. Please try again.',
  farm: 'Farm',
  main_container_heading: ' - Establish activity reporting ',
  empty_farm_label: 'Tractors / vehicles fuel (0)',
  empty_crop_label: 'Crop (0)',
  activity: 'Activity',
  crop: 'Crop',
  baseline: 'baseline',
  unsavedChangesPromptMsg:
    "You've made updates that haven't been saved yet. Would you like to save before closing?",
  operations_title: 'Operations data',
  proof_of_implementation: 'Proof of implementation',
  submit_complete_modal_title: 'All fields activity reporting is complete',
  submit_complete_modal_content:
    'You’re ready to review and submit or you may continue to edit fields that are complete.',
  continue_btn_label: 'Continue to edit farms / fields',
  review_btn_label: 'Review and submit',
  cancel_btn_label: 'Cancel',
  project: 'project',
  back: 'Back',
  PARTICIPANT_OWNER: 'PARTICIPANT_OWNER',
  PARTICIPANT_CONTRIBUTOR: 'PARTICIPANT_CONTRIBUTOR',
  expandAllButton: 'Expand all',
  collapseButton: 'Collapse all',
  PARTICIPANT: 'PARTICIPANT',
  FORM_UNLOCKED: 'Form unlocked',
  FORM_LOCKED: 'Form locked',
};

export const getTabYearStatuses = (status) => {
  switch (status) {
    case 'In Progress':
      return <YellowStatus />;
    case 'Completed':
      return <GreenStatus />;
    case 'Disabled':
      return <DisabledIcon />;
    default:
      return <NotStartedStatus />;
  }
};

export const tabsTitle = (title, status, phase) => {
  return (
    <TabsStyle>
      {title}
      {phase === 'project' && getTabYearStatuses(status)}
    </TabsStyle>
  );
};

export const mockEnrolmentYears = [
  {
    yearValue: '2024',
    sampleId: 7,
    type: 'farm',
    status: 'Not Started',
    previousStatus: 'Not Started',
    phase: 'project',
    displayTitle: 'Operations data',
  },
];

export const mockData = {
  participantId: 55,
  projectName: 'test new22',
  firstName: 'test',
  lastName: '25 last',
  email: 'last25@678.in',
  phone: '5790009876',
  mailingAddress1: 'Texas',
  preferredCommunicationMethod: 'Text',
  projectParticipantMotivation: [
    {
      projectParticipantMotivationId: 113,
      internalProjectId: 12,
      participantId: 55,
      contractId: null,
      clientId: 100,
      participationMotivationId: 1,
      participationMotivationScore: 1,
      isActive: 1,
      createdDate: '2024-03-25T01:08:22.089+00:00',
      updatedDate: '2024-03-25T01:09:31.593+00:00',
    },
    {
      projectParticipantMotivationId: 114,
      internalProjectId: 12,
      participantId: 55,
      contractId: null,
      clientId: 100,
      participationMotivationId: 2,
      participationMotivationScore: 2,
      isActive: 1,
      createdDate: '2024-03-25T01:08:22.103+00:00',
      updatedDate: '2024-03-25T01:09:31.607+00:00',
    },
    {
      projectParticipantMotivationId: 115,
      internalProjectId: 12,
      participantId: 55,
      contractId: null,
      clientId: 100,
      participationMotivationId: 3,
      participationMotivationScore: 3,
      isActive: 1,
      createdDate: '2024-03-25T01:08:22.124+00:00',
      updatedDate: '2024-03-25T01:09:31.620+00:00',
    },
    {
      projectParticipantMotivationId: 116,
      internalProjectId: 12,
      participantId: 55,
      contractId: null,
      clientId: 100,
      participationMotivationId: 4,
      participationMotivationScore: 4,
      isActive: 1,
      createdDate: '2024-03-25T01:08:22.137+00:00',
      updatedDate: '2024-03-25T01:09:31.634+00:00',
    },
  ],
  farmDetails: [
    {
      farmId: 278,
      farmName: 'newFarmAdded',
      farmStatus: 'Completed',
      mainStatus: 'In Progress',
      isDeleted: 0,
      formValidationFlag: true,
      enrollmentInstanceID: 44,
      farmYearStatus: [
        {
          sampleId: 79,
          dateId: 2023,
          formStatus: 'Not Started',
          phase: 'baseline',
        },
        {
          sampleId: 80,
          dateId: 2022,
          formStatus: 'Not Started',
          phase: 'baseline',
        },
        {
          sampleId: 81,
          dateId: 2021,
          formStatus: 'Not Started',
          phase: 'baseline',
        },
      ],
      fieldDetails: [
        {
          fieldId: 76,
          clientId: 100,
          farmId: 27,
          fieldName: 'Field 1',
          fsaTractId: '1',
          fsaFieldId: '1',
          formStatus: 'Completed',
          fieldBoundaries: [
            {
              longitude: -97.52677708877715,
              latitude: 35.479149587270875,
            },
            {
              longitude: -97.52759248031768,
              latitude: 35.47773421667824,
            },
            {
              longitude: -97.52466350807342,
              latitude: 35.47785653363909,
            },
          ],
          totalFieldArea: 5.04,
          createdDate: null,
          updatedDate: '2024-03-25T01:09:26.427+00:00',
          isActive: 1,
          fieldStatus: 'In Progress',
          mainStatus: 'In Progress',
          enrollmentInstanceID: 44,
          fieldYearStatus: [
            {
              sampleId: 302,
              dateId: 2022,
              formStatus: 'Not Started',
              phase: 'baseline',
            },
            {
              sampleId: 303,
              dateId: 2021,
              formStatus: 'Not Started',
              phase: 'baseline',
            },
            {
              sampleId: 301,
              dateId: 2023,
              formStatus: 'In Progress',
              phase: 'baseline',
            },
          ],
          enrolmentStatus: 'APPROVED',
          baselineStatus: 'NOT_APPROVED',
        },
      ],
      enrolmentStatus: 'APPROVED',
      baselineStatus: 'NOT_APPROVED',
      fsaid: '11',
    },
  ],
  city: 'Oklahoma City',
  state: 'Texas',
  county: 'Bexar County',
  zip: '1',
  participantStatus: 'Completed',
  participantProjectStatus: 'Approved',
  baselineEnrollmentStatus: 'Enrollment In Progress',
  hearAboutProject:
    'Field days and town halls, Digital communications and resources',
  motivation: null,
  isDeleted: 0,
  profileDeleted: 0,
  participantCreatedDate: '2024-03-25T01:07:37.128+00:00',
  participantProfileToBeDisabled: false,
  isCopied: false,
  activityReportingStatus: null,
};

export const autoSaveTargetKeys = {
  farmOperationsData: 'Farm operations data',
  proofOfImplementation: 'Proof of implementation',
  fieldOperationsData: 'Field operations data',
  fieldImplementedActivities: 'Field implemented activities',
  fieldInfo: 'Field info',
  farmInfo: 'Farm info',
  fieldYear: 'Field year',
};

export const projectActivitiesMapping = {
  'Residue + tillage management': 'Residue + tillage management',
  'Cover crop': 'Cover crop',
  'Edge of field practices': 'Edge of field',
  'Nutrient management': 'Nutrient management',
  'Soil carbon amendment': 'Soil carbon amendment',
};

export const projectActivitiesUiToApiMapping = {
  'Residue + tillage management': 'Residue + tillage management',
  'Cover crop': 'Cover crop',
  'Edge of field': 'Edge of field practices',
  'Nutrient management': 'Nutrient management',
  'Soil carbon amendment': 'Soil carbon amendment',
};

export const activityInstanceStatus = {
  approved: 'APPROVED',
  notApproved: 'NOT_APPROVED',
};
export const proofOfImplementation = [
  {
    ESTIMATED_IMPLEMENTATION_COST: [
      {
        dataElementId: 23,
        dataElementName: 'Estimated implementation cost',
        uomId: 25,
        numeratorUomId: 17,
        denominatorUomId: 16,
        uomName: '$ per ac',
        uomNameFull: 'US dollars per acre',
        uomNameDisplay: '$ per ac',
        uomType: 'ratio',
        uiModal: 'Proof of implementation',
      },
    ],
  },
];
export const STATUS_LIST = [
  REPORTING_STATUS.NOT_STARTED,
  REPORTING_STATUS.IN_PROGRESS,
];

export const FIELD_DATA_INIT = {
  id: 0,
  name: '',
};
