export const TOAST_MESSAGE = {
  NO_DATA_ERROR: 'No exception found.',
  DOWNLOAD_ERROR: 'Error downloading the file.',
};

//Mocking PDF file
export const MOCK_PDF = {
  PDF_URL: 'https://example.com/dummy-pdf-url.pdf',
  PROJECT_NAME: 'Sample Project',
  PROJECT_CYCLE_ID: '12345',
  PARTICIPANT_NAME: 'John Doe',
};

export const ENROLMENT_MOCK_OBJECT = {
  enrolmentType: 'Participant',
  participantFullName: 'John Doe',
  projectCycleId: '12345',
  projectName: 'Sample Project',
};

export const ACTIVITY_MOCK_OBJECT = {
  enrolmentType: 'Activity',
  participantFullName: 'John Doe',
  projectCycleId: '12345',
  projectName: 'Sample Project',
};

export const BASELINE_MOCK_OBJECT = {
  enrolmentType: 'Baseline',
  participantFullName: 'John Doe',
  projectCycleId: '12345',
  projectName: 'Sample Project',
};

export const ENROLLMENT = 'Enrollment';
export const BASELINE_REPORTING = 'Baseline reporting';
export const ACTIVITY_REPORTING = 'Activity reporting';
export const PARTICIPANT = 'Participant';
export const BASELINE = 'Baseline';
export const ACTIVITY = 'Activity';

export const fetchActivity = (activityType) => {
  /* istanbul ignore else */
  if (activityType === ENROLLMENT) {
    return PARTICIPANT;
  } else if (activityType === BASELINE_REPORTING) {
    return BASELINE;
  } else if (activityType === ACTIVITY_REPORTING) {
    return ACTIVITY;
  }
};

export const BLOB_RESPONSE = { responseType: 'blob' };
