import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  FORM_CONTENT,
  validateEmail,
} from './ParticipantContributorsForm.content';
import InputField from 'components/FormComponents/InputField/InputField.react';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import {
  ButtonStyleForEditParticipantModal,
  CheckBoxStyle,
  checkboxStyleForInput,
  closeIconSx,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FarmProjectError,
  FarmProjectTitleWrapper,
  FarmsTitleStyle,
  FarmTitleWrapper,
  IconButtonStyle,
  InputWrapper,
  ProjectTitleWrapper,
  tertiaryButtonStyle,
  TitleWrapper,
} from './ParticipantContributorsForm.style';
import CloseIcon from '@mui/icons-material/Close';
import {
  communicationMethodList,
  getCommnicationMethodList,
  STYLE_CONSTANTS,
  validateCommunicationMethod,
} from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from '../ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import { modalActionButtonDefaultStyle } from 'theme/GlobalStyles';
import {
  FocusedInputSx,
  primaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { useForm } from 'hooks/useForm';
import { useEffect, useState } from 'react';
import {
  checkTernaryCondition,
  formatPhoneNumber,
  replaceSpecialCharactersWithSingleCharacter,
  ternaryBooleanHandler,
  validatePhone,
} from 'utils/helper';
import {
  addFarmContributor,
  fetchProfileParticipantContributor,
  fetchAddContributorFarmAndProject,
} from './ParticipantContributorsForm.funtions';
import { FETCH_PROJECT_LIFECYCLES } from 'urls';
import axios from 'axios';

const formModal = {
  participantFirstName: '',
  participantLastName: '',
  participantEmail: '',
  participantPhone: '',
  primaryCommunication: communicationMethodList,
  farmProjectAssignment: [],
};
const errorInitialState = {
  participantFirstNameError: false,
  participantLastNameError: false,
  participantEmailError: false,
  emailInValidError: false,
  participantPhoneError: false,
  phoneInValidError: false,
  emptyPhoneNumberOnEditError: false,
  primaryCommunicationError: false,
  farmProjectAssignmentError: false,
};

const EditParticipantContributorModal = ({
  isParticipantEditModalOpen,
  setIsParticipantEditModalOpen,
  participantId,
  refreshHandler,
  participantOwnerId,
  internalProjectId,
}) => {
  const {
    formValue: participantForm,
    formFieldChangeHandler,
    customFieldChangeHandler,
    nestedFieldChangeHandler,
    setFormValue,
  } = useForm(formModal);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [farmProjectAssignmentList, setFarmProjectAssignmentList] = useState(
    [],
  );
  const [selectedSubItems, setSelectedSubItems] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editErrorState, setEditErrorState] = useState({
    errorInitialState,
  });
  const [projectCycleId, setProjectCycleId] = useState(null);

  const editModalHasErrors = Object.values(editErrorState).includes(true);

  const editModalCloseHandler = () => {
    setIsFormEdited(false);
    setEditErrorState(errorInitialState);
    setIsParticipantEditModalOpen(false);
    setFormValue(formModal);
  };

  const handleEmailChangeForEditContributor = (e) => {
    formFieldChangeHandler(e);
    let errorStateUpdates = {
      participantEmailError: false,
      emailInValidError: false,
    };
    setEditErrorState((prev) => ({ ...prev, ...errorStateUpdates }));
    setIsFormEdited(true);
  };

  const validateProjectSelectionForEditModal = (
    selectedFarmId,
    selectedProjectIds,
  ) => {
    if (
      (selectedFarmId !== undefined && selectedProjectIds.length === 0) ||
      selectedCategories.length === 0
    ) {
      setEditErrorState((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: true,
      }));
    } else
      setEditErrorState((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: false,
      }));
  };

  const farmContributorList = farmProjectAssignmentList.filter(
    (name) => name.farmName !== null || '' || undefined,
  );

  const handlePhoneChangeForEditModal = (e) => {
    formFieldChangeHandler(e);
    let errorStateUpdates = {
      participantPhoneError: false,
      phoneInValidError: false,
      emptyPhoneNumberOnEditError: false,
    };
    setEditErrorState((prev) => ({ ...prev, ...errorStateUpdates }));
    setIsFormEdited(true);
  };

  const handleCategoryChangeForEditModal = (farm) => {
    const isCategorySelected = selectedCategories.includes(farm.farmId);
    setSelectedCategories((oldState) => {
      const isCategorySelected = oldState.includes(farm.farmId);
      const updatedSelectedCategories = isCategorySelected
        ? oldState.filter((cat) => cat !== farm.farmId)
        : [...oldState, farm.farmId];
      return updatedSelectedCategories;
    });
    // Update selectedSubItems
    setSelectedSubItems((prevSelectedSubItems) => {
      const updatedSelectedSubItems = { ...prevSelectedSubItems };
      if (isCategorySelected) {
        // If farmId is being deselected, remove it from  edit modal selectedSubItems
        delete updatedSelectedSubItems[farm.farmId];
      } else {
        // If farmId is being selected, add or update its projects in edit modal selectedSubItems
        const farmProjects = farm.projects.map((project) => project.projectId);
        updatedSelectedSubItems[farm.farmId] = farmProjects;
      }
      return updatedSelectedSubItems;
    });
    setIsFormEdited(true);
    if (isCategorySelected) {
      setEditErrorState((prevState) => ({
        ...prevState,
        farmProjectAssignmentError: true,
      }));
    }
  };

  useEffect(() => {
    fetchProfileParticipantContributor(participantId, participantOwnerId).then(
      (data) => {
        setSelectedCategories(
          data.farmProjectAssignment.map((farmNames) => farmNames.farmId),
        );

        setSelectedSubItems(
          data.farmProjectAssignment.reduce((acc, farm) => {
            acc[farm.farmId] = farm.projects.map(
              (project) => project.projectId,
            );
            return acc;
          }, {}),
        );
      },
    );
  }, [isParticipantEditModalOpen, participantId, participantOwnerId]);

  const handleSubItemChangeForEditModal = (farm, project) => {
    const currentSubItems = selectedSubItems[farm] || [];
    const isProjectSelected = currentSubItems.includes(project.projectId);
    const updatedSubItems = { ...selectedSubItems };
    if (isProjectSelected) {
      updatedSubItems[farm] = currentSubItems.filter(
        (item) => item !== project.projectId,
      );
    } else {
      updatedSubItems[farm] = [...currentSubItems, project.projectId];
    }
    /** Code to handle select and unselect parent check box based on edit modal sub items */
    const selectedFarm = farmContributorList.find(
      (item) => item.farmId == farm,
    );
    if (selectedFarm.projects.length === updatedSubItems[farm].length) {
      handleCategoryChangeForEditModal(selectedFarm);
    } else {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== farm));
    }
    /** End of Code : to handle select and unselect parent check box based on edit modal sub items */
    setSelectedSubItems({
      ...selectedSubItems,
      [farm]: updatedSubItems[farm],
    });
    setIsFormEdited(true);
  };

  useEffect(() => {
    fetchAddContributorFarmAndProject(participantOwnerId).then((data) =>
      setFarmProjectAssignmentList(data),
    );
  }, []);

  useEffect(() => {
    if (isParticipantEditModalOpen) {
      setEditErrorState(errorInitialState);
      setFormValue(formModal);
    }
  }, [isParticipantEditModalOpen]);

  useEffect(() => {
    fetchProfileParticipantContributor(participantId, participantOwnerId).then(
      (data) => {
        setFormValue({
          participantFirstName: data.participantFirstName,
          participantLastName: data.participantLastName,
          participantEmail: data.participantEmail,
          participantPhone: data.phone,
          primaryCommunication: getCommnicationMethodList(
            data.primaryCommunication,
          ),
          farmProjectAssignment: data.farmProjectAssignment,
        });
      },
    );
  }, [isParticipantEditModalOpen]);

  useEffect(() => {
    if (isFormEdited) {
      setEditErrorState({
        ...editErrorState,
        primaryCommunicationError: !validateCommunicationMethod(
          participantForm.primaryCommunication,
        ),
      });
    }
  }, [participantForm.primaryCommunication]);

  const fetchProjectCycles = () => {
    axios
      .get(FETCH_PROJECT_LIFECYCLES, {
        params: {
          internalProjectId: internalProjectId,
          participantId: participantOwnerId,
        },
      })
      .then((response) => {
        setProjectCycleId(response?.data[0]?.projectCycleId);
      });
  };
  useEffect(() => {
    fetchProjectCycles();
  }, [participantOwnerId]);

  const submitHandlerForEditModal = async ({ participantForm }) => {
    let hasFarmProjectErrorForEditModal = false;
    if (selectedCategories.length === 0) {
      hasFarmProjectErrorForEditModal = true;
    }
    setEditErrorState({
      ...editErrorState,
      participantFirstNameError: ternaryBooleanHandler(
        !participantForm.participantFirstName.trim(),
      ),
      participantLastNameError: ternaryBooleanHandler(
        !participantForm.participantLastName.trim(),
      ),
      participantEmailError: ternaryBooleanHandler(
        !participantForm.participantEmail.trim(),
      ),
      participantPhoneError: ternaryBooleanHandler(
        !participantForm.participantPhone.trim(),
      ),
      primaryCommunicationError: !validateCommunicationMethod(
        participantForm.primaryCommunication,
      ),
      farmProjectAssignmentError: hasFarmProjectErrorForEditModal,
    });
    const editModalFormError = () => {
      if (
        participantForm.participantFirstName.trim() &&
        participantForm.participantLastName.trim() &&
        validateEmail(participantForm.participantEmail) &&
        participantForm.participantEmail.trim()
      ) {
        return true;
      } else {
        return false;
      }
    };

    addFarmContributor({
      isFormEdited,
      participantForm,
      participantOwnerId,
      selectedCategories,
      selectedSubItems,
      participantId,
      refreshHandler,
      projectCycleId,
    });
    if (editModalFormError()) {
      editModalCloseHandler();
    }
    if (editModalHasErrors) {
      setIsParticipantEditModalOpen(false);
    }
  };

  useEffect(() => {
    selectedCategories.forEach((selectedFarmId) => {
      const selectedProjectIds = selectedSubItems[selectedFarmId] || [];
      validateProjectSelectionForEditModal(selectedFarmId, selectedProjectIds);
      nestedFieldChangeHandler(
        'farmProjectAssignment',
        selectedFarmId,
        selectedProjectIds,
      );
    });
  }, [selectedCategories, selectedSubItems]);

  const hasError = editErrorState.farmProjectAssignmentError;

  useEffect(() => {
    if (participantForm.participantPhone === '' && isFormEdited) {
      setEditErrorState({
        ...editErrorState,
        emptyPhoneNumberOnEditError: true,
      });
    }
  }, [participantForm.participantPhone]);

  return (
    <Dialog
      disableRestoreFocus
      open={isParticipantEditModalOpen}
      sx={DialogStyle}
      onClose={() => setIsParticipantEditModalOpen(false)}
      scroll={FORM_CONTENT.paper}
      aria-labelledby={FORM_CONTENT.aria_labelledby}
      aria-describedby={FORM_CONTENT.aria_describedby}>
      <TitleWrapper>
        <DialogTitle sx={DialogTitleStyle}>
          {FORM_CONTENT.edit_participant_contributor_label}
        </DialogTitle>
        <IconButton sx={IconButtonStyle} onClick={editModalCloseHandler}>
          <CloseIcon sx={closeIconSx} />
        </IconButton>
      </TitleWrapper>
      <DialogContent sx={DialogContentStyle}>
        <InputWrapper>
          <InputField
            isDisabled={true}
            width="16.25rem"
            minWidth="16.75rem"
            isRequired
            category="name-field"
            label={FORM_CONTENT.first_name_label}
            value={participantForm.participantFirstName}
            onUpdate={(e) => {
              formFieldChangeHandler(e);
              e.target.value !== '' &&
                setEditErrorState({
                  ...editErrorState,
                  participantFirstNameError: false,
                });
              setIsFormEdited(true);
            }}
            name="participantFirstName"
            placeholder={FORM_CONTENT.first_name_placeholder}
            primaryError={editErrorState.participantFirstNameError}
            primaryErrorMessage={FORM_CONTENT.first_name_required_error}
            labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            maxLength={100}
            customInputSx={InputSx}
          />
          <InputField
            isDisabled={true}
            width="16.25rem"
            minWidth="16.75rem"
            isRequired
            category="name-field"
            name="participantLastName"
            value={participantForm.participantLastName}
            onUpdate={(e) => {
              formFieldChangeHandler(e);
              e.target.value !== '' &&
                setEditErrorState({
                  ...editErrorState,
                  participantLastNameError: false,
                });
              setIsFormEdited(true);
            }}
            placeholder={FORM_CONTENT.last_name_placeholder}
            primaryError={editErrorState.participantLastNameError}
            primaryErrorMessage={FORM_CONTENT.last_name_required_error}
            maxLength={100}
            labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
            labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
            customInputSx={{ ...InputSx, ...FocusedInputSx }}
            label={FORM_CONTENT.last_name_label}
          />
        </InputWrapper>
        <InputField
          isDisabled={true}
          label={FORM_CONTENT.email_label}
          width="34rem"
          isRequired
          name="participantEmail"
          category="email-field"
          value={participantForm.participantEmail}
          onUpdate={handleEmailChangeForEditContributor}
          onBlurHandler={(e) => {
            setEditErrorState({
              ...editErrorState,
              emailInValidError: checkTernaryCondition(
                e.target.value.trim(),
                !validateEmail(e.target.value),
                false,
              ),
            });
          }}
          placeholder={FORM_CONTENT.email_placeholder}
          primaryError={editErrorState.participantEmailError}
          primaryErrorMessage={FORM_CONTENT.email_invalid_error}
          secondaryError={editErrorState.emailInValidError}
          secondaryErrorMessage={FORM_CONTENT.email_invalid_domain_error}
          maxLength={100}
          labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          customInputSx={{ ...InputSx, ...FocusedInputSx }}
        />
        <InputField
          customInputSx={{ ...InputSx, ...FocusedInputSx }}
          customStyles={{ width: '100%' }}
          width="32%"
          isRequired
          label={FORM_CONTENT.phone_label}
          name="participantPhone"
          value={formatPhoneNumber(participantForm.participantPhone)}
          onUpdate={handlePhoneChangeForEditModal}
          onBlurHandler={(event) => {
            if (event.target.value !== '') {
              const value = replaceSpecialCharactersWithSingleCharacter(
                /[()\-\s]/g,
                event.target.value,
                '',
              );
              setEditErrorState({
                ...editErrorState,
                phoneInValidError: !validatePhone(value),
              });
            }
          }}
          placeholder={FORM_CONTENT.phone_placeholder}
          primaryError={editErrorState.participantPhoneError}
          primaryErrorMessage={FORM_CONTENT.phone_number_required_error}
          maxLength={100}
          category="phone-field"
          secondaryError={editErrorState.phoneInValidError}
          secondaryErrorMessage={FORM_CONTENT.phone_invalid_error}
          thirdError={editErrorState.emptyPhoneNumberOnEditError}
          thirdErrorMessage={FORM_CONTENT.phone_number_required_error}
          labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          customPattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
        />
        <CheckboxFormGroup
          list={participantForm.primaryCommunication}
          label={FORM_CONTENT.communication_method_label}
          instructionText={FORM_CONTENT.communication_method_helptext}
          primaryError={editErrorState.primaryCommunicationError}
          checkboxPadding={'0rem 0.5625rem'}
          errorMessage={FORM_CONTENT.communication_method_label_error}
          onClickHandler={(methodIndex) => {
            setIsFormEdited(true);
            customFieldChangeHandler(
              'primaryCommunication',
              participantForm.primaryCommunication.map((method, index) => ({
                ...method,
                checked: checkTernaryCondition(
                  methodIndex === index,
                  !method.checked,
                  method.checked,
                ),
              })),
            );
          }}
          gap="1.625rem"
          name="primaryCommunication"
          labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
          titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
        />
        <FarmProjectTitleWrapper iserror={hasError}>
          Farm and project assignment
        </FarmProjectTitleWrapper>
        <FarmsTitleStyle>
          {farmContributorList.map((farm, farmIndex) => {
            const selectedCategoriesCheckedValues = selectedCategories.includes(
              farm.farmId,
            );
            const keyIndex = farmIndex;
            return (
              <div key={keyIndex}>
                <label>
                  <FarmTitleWrapper>
                    <Checkbox
                      id={farm.farmId}
                      onChange={() => handleCategoryChangeForEditModal(farm)}
                      checked={selectedCategoriesCheckedValues}
                      style={checkboxStyleForInput}
                      sx={CheckBoxStyle}
                    />
                    {farm.farmName}
                  </FarmTitleWrapper>
                  {farm.projects.map((project, projectIndex) => {
                    const selectedSubItemsCheckedValues = selectedSubItems[
                      farm.farmId
                    ]?.includes(project.projectId);
                    const isProjectChecked =
                      selectedCategoriesCheckedValues &&
                      selectedSubItemsCheckedValues;
                    const keyIndex = projectIndex;
                    return (
                      <div key={keyIndex}>
                        <label>
                          <ProjectTitleWrapper>
                            <Checkbox
                              key={selectedSubItemsCheckedValues}
                              id={`${project.projectId}`}
                              checked={isProjectChecked}
                              style={checkboxStyleForInput}
                              onChange={() =>
                                handleSubItemChangeForEditModal(
                                  farm.farmId,
                                  project,
                                )
                              }
                              sx={CheckBoxStyle}
                            />
                            {project.projectName ? project.projectName : null}
                          </ProjectTitleWrapper>
                        </label>
                      </div>
                    );
                  })}
                </label>
              </div>
            );
          })}
        </FarmsTitleStyle>
        {hasError && (
          <FarmProjectError iserror={hasError}>
            {FORM_CONTENT.farm_and_project_assignment_error}
          </FarmProjectError>
        )}
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          onClick={editModalCloseHandler}
          sx={[tertiaryButtonStyle, modalActionButtonDefaultStyle]}>
          {FORM_CONTENT.cancelBtn}
        </Button>

        <Button
          sx={[
            ButtonStyleForEditParticipantModal,
            primaryButtonStyle('4.5px 15px'),
          ]}
          onClick={() => submitHandlerForEditModal({ participantForm })}
          disabled={editModalHasErrors || !isFormEdited}>
          {FORM_CONTENT.edit_participant_contributor_label_save_text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditParticipantContributorModal;

EditParticipantContributorModal.propTypes = {
  isParticipantEditModalOpen: PropTypes.bool.isRequired,
  setIsParticipantEditModalOpen: PropTypes.func.isRequired,
  participantId: PropTypes.number.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  participantOwnerId: PropTypes.number.isRequired,
  internalProjectId: PropTypes.number.isRequired,
};
