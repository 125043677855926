import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { Button, Tab, Tabs } from '@mui/material';
import { useStyles } from 'theme/GlobalStyles';
import {
  DARK_CHARCOAL,
  GLOBAL_BLUE,
  TABS_HIGHLIGHT_BORDER,
} from 'theme/GlobalColors';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LoaderProvider } from 'contextAPI/loaderContext';
import { useActivityContext } from 'containers/ActivityListContainer';
import TabPanel from 'components/Tabber/TabPanel.react';

import {
  HeadingStyle,
  BackButtonStyle,
  SubHeadingStyle,
  TitleStyle,
  ResponseStyle,
  TabberWrapperStyle,
  ButtonWrapper,
  secondaryButtonStyle,
  primaryButtonStyle,
  TitleContainer,
} from './PdfViewerPage.style';
import { PAGE_CONTENT } from './PdfViewerPage.content';
import { ReopenCustomModal } from '../ReOpenTemplate';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/backarrow.svg';
import { enrollmentReOpen } from 'pages/Origination/Org_Project/Participants/EnrollmentReview';
import {
  LABELS,
  baselineApproveAndReopenActivityContent,
  enrollmentApproveAndReopenBaselineContent,
  getActionIdFromActionCode,
} from 'pages/Origination/Org_Project/Participants/Participants.content';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { baselineReOpen } from '../BaselineReview';
import { activityReOpen } from '../ActivityReview';
import ApproveModal from '../ActivityReportingPOIReviewComp/ApproveModal';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import Loader from 'components/Loader/Loader.react';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { GET_POI_ACCEPT_STATUS } from 'urls';
import axios from 'axios';
import DialogBox from 'components/DialogBox/DialogBox.react';
import dayjs from 'dayjs';
import { modalSubTitle } from './PdfViewerPage.function';

const PdfViewerPage = ({
  activityType,
  activityDate,
  heading,
  subHeadingArray,
  currentTab,
  setCurrentTab,
  tabList,
  isApproveDisabled,
  setIsApproveDisabled,
  onClickBackButton,
  formId,
}) => {
  const { TabberBox } = useStyles();
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [projectYear, setProjectYear] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    activityType === PAGE_CONTENT.activity_reporting && fetchPOIAcceptStatus();
  }, []);

  const {
    reopenProps,
    setReopenProps,
    participantId,
    projectId,
    activity,
    statusMappingList,
    reviewEnrollmentProps,
    loading,
    closeMenu,
    setLoading,
    projectCycleId,
    activityDataList,
  } = useActivityContext();
  const {
    approveEnrollmentOrBaseline,
    reopenEnrollmentOrBaseline,
    updateParticipantStatus,
  } = useContext(LifeCycleContext);
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);
  if (isEmpty(statusMappingList)) return null;

  const fetchPOIAcceptStatus = () => {
    axios
      .get(
        `${GET_POI_ACCEPT_STATUS}?participantId=${participantId}&projectId=${projectId}`,
      )
      .then((res) => setIsApproveDisabled(!res.data))
      .catch(() => {
        /* Do API error handling here to avoid Sonar issue */
      });
  };

  const handleTabChange = (_, selectedTab) => {
    if (selectedTab === 1) {
      setIsApproveDisabled(true);
    } else {
      activityType === PAGE_CONTENT.activity_reporting &&
        fetchPOIAcceptStatus();
    }
    setCurrentTab(selectedTab);
  };
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `participant-page-tab-${index}`,
    };
  };

  const reopenActivity = (activityType, participantId, projectId) => {
    if (activityType === PAGE_CONTENT.enrollment) {
      return enrollmentReOpen(participantId, projectId);
    } else if (activityType === PAGE_CONTENT.baselineReporting) {
      return baselineReOpen(participantId, projectId);
    } else if (activityType === PAGE_CONTENT.activity_reporting) {
      return activityReOpen(participantId, projectId);
    }
  };

  const handleReopenButtonClick = () => {
    Promise.resolve(
      setReopenProps({
        ...reopenActivity(activityType, participantId, projectId),
        modalData: { flag: true },
        instanceId: activity.enrollmentId,
        actionCode: 1,
        contractId: activity.associatedContractId,
        formId: formId,
        projectCycleId: projectCycleId,
      }),
      setAreUiActionInProgress((prev) => prev + 1),
    ).then(() => {
      if (activityType === PAGE_CONTENT.enrollment) {
        if (
          getActionIdFromActionCode('ENROLL-SUBMIT', statusMappingList) ===
          activity.actionId
        ) {
          updateParticipantStatus({
            contractId: activity.associatedContractId,
            projectId,
            participantId,
            instanceId: activity.enrollmentId,
            actionId: getActionIdFromActionCode(
              'ENROLL-REVIEW',
              statusMappingList,
            ),
            projectCycleId: projectCycleId,
          });
        }
      } else if (activityType === PAGE_CONTENT.baselineReporting) {
        setReopenProps({
          ...reviewEnrollmentProps.reOpenProp,
          instanceId: reviewEnrollmentProps.instanceId,
          projectCycleId: reviewEnrollmentProps.projectCycleId,
          modalData: { flag: true },
          actionCode: reviewEnrollmentProps.actionCode,
          contractId: reviewEnrollmentProps.contractId,
          formId: formId,
          baselineActionId: reviewEnrollmentProps.baselineActionId,
        });
      } else if (activityType === PAGE_CONTENT.activity_reporting) {
        if (
          getActionIdFromActionCode('ACTIVITY-SUBMIT', statusMappingList) ===
          activity.actionId
        ) {
          reopenActivity(activityType, participantId, projectId);
        }
      }
    });

    closeMenu();
  };

  const handleApproveButtonClick = () => {
    setProjectYear(activityDate?.slice(-4));
    const baselineReportingRowData = activityDataList
      ?.filter((data) => data.activityType === 'Baseline reporting')
      .reverse()[0];
    const activityReportingRowData = activityDataList
      ?.filter((data) => data.activityType === 'Activity reporting')
      .reverse()[0];
    approveEnrollmentOrBaseline({
      ...reviewEnrollmentProps,
      setLoading: setLoading,
      formId: formId,
      actionId: getActionIdFromActionCode('ENROLL-APPROVE', statusMappingList),
      baselineActionId: getActionIdFromActionCode(
        'BASELINE-APPROVE',
        statusMappingList,
      ),
      activityActionId: getActionIdFromActionCode(
        'ACTIVITY-APPROVE',
        statusMappingList,
      ),
      setReviewEnrollmentProps: () => {},
      statusMappingList,
      baselineReportingRowData,
      activityReportingRowData,
    }).then(() => {
      setIsApproveModal(true);
    });
  };

  // Approve handler on click of approve btn from pdf viewer page
  function handleApprove() {
    const baselineReportingSubmittedData = activityDataList
      ?.filter((data) => data.activityType === 'Baseline reporting')
      .find(
        (data) =>
          getActionIdFromActionCode('BASELINE-REVIEW', statusMappingList) ===
            data.actionId ||
          getActionIdFromActionCode('BASELINE-SUBMIT', statusMappingList) ===
            data.actionId,
      );

    const activityReportingSubmitOrReviewData = activityDataList
      ?.filter((data) => data.activityType === 'Activity reporting')
      .find(
        (data) =>
          getActionIdFromActionCode('ACTIVITY-REVIEW', statusMappingList) ===
            data.actionId ||
          getActionIdFromActionCode('ACTIVITY-SUBMIT', statusMappingList) ===
            data.actionId,
      );

    handleModalClose();

    // Note: Here we have a baseline in submitted state and enrollment is about to be approved, hence we show a modal to reopen baseline so that the approved enrollment goes into that baseline
    if (
      baselineReportingSubmittedData &&
      reviewEnrollmentProps.title === LABELS.reviewEnrollmentLabel
    ) {
      reviewEnrollmentProps.firstSubmitBtnCb();
      reopenEnrollmentOrBaseline(reviewEnrollmentProps);
      setReopenProps({
        ...baselineReOpen(
          +reviewEnrollmentProps.participantId,
          reviewEnrollmentProps.projectId,
        ),
        ishandleApprove: true,
        instanceId: 0,
        handleApprove: handleApproveButtonClick,
        projectCycleId: reviewEnrollmentProps.projectCycleId,
        ...enrollmentApproveAndReopenBaselineContent,
        modalData: { flag: true },
        contractId: reviewEnrollmentProps.contractId,
        formId: baselineReportingSubmittedData.formId,
        customReopenRadioLabel: LABELS.reopen_and_approve_enrollment,
      });
      setAreUiActionInProgress((prev) => prev + 1);
    }
    // Note: Here we have a activity in submitted state and baseline is about to be approved, hence we show a modal to reopen activity reporting so that the approved baseline goes into that activity reporting
    else if (
      activityReportingSubmitOrReviewData &&
      reviewEnrollmentProps.title === LABELS.reviewBaselineLabel
    ) {
      reviewEnrollmentProps.firstSubmitBtnCb();
      reopenEnrollmentOrBaseline(reviewEnrollmentProps);
      setReopenProps({
        ...activityReOpen(
          +reviewEnrollmentProps.participantId,
          reviewEnrollmentProps.projectId,
        ),
        ishandleApprove: true,
        instanceId: 0,
        handleApprove: handleApproveButtonClick,
        projectCycleId: reviewEnrollmentProps.projectCycleId,
        ...baselineApproveAndReopenActivityContent,
        modalData: { flag: true },
        contractId: reviewEnrollmentProps.contractId,
        formId: activityReportingSubmitOrReviewData.formId,
        customReopenRadioLabel: LABELS.reopen_activity_approve_baseline,
      });
      setAreUiActionInProgress((prev) => prev + 1);
    } else {
      handleApproveButtonClick();
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  if (loading) {
    return <Loader loading={loading} zIndex={999999} />;
  }

  return (
    <>
      <HeadingStyle>
        <BackButtonStyle onClick={onClickBackButton}>
          <BackArrow fill={GLOBAL_BLUE} style={{ height: 16, width: 16 }} />{' '}
          {PAGE_CONTENT.back}
        </BackButtonStyle>
        <div>{heading}</div>
      </HeadingStyle>
      <SubHeadingStyle>
        {subHeadingArray.map((item) => (
          <TitleContainer key={item.title}>
            <TitleStyle>{item.title}:</TitleStyle>
            <ResponseStyle
              title={checkTernaryCondition(
                item?.response?.length > 22,
                item.response,
                '',
              )}>
              {item.response}
            </ResponseStyle>
          </TitleContainer>
        ))}
      </SubHeadingStyle>
      <div>
        <Box sx={TabberWrapperStyle}>
          <TabberBox>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { backgroundColor: TABS_HIGHLIGHT_BORDER },
              }}
              aria-label="page-tabs">
              {tabList.map((tab, index) => (
                <Tab
                  sx={{
                    textTransform: 'none',
                    width: 'unset',
                    '&.active-tab': {
                      color: DARK_CHARCOAL,
                    },
                  }}
                  className={classNames({
                    'active-tab': currentTab === index,
                  })}
                  key={tab.title}
                  label={tab.title}
                  {...tabProps(index)}
                />
              ))}
            </Tabs>
            <ButtonWrapper>
              <Button
                sx={[secondaryButtonStyle]}
                onClick={handleReopenButtonClick}>
                {PAGE_CONTENT.reopen}
              </Button>
              <Button
                sx={[primaryButtonStyle]}
                disabled={isApproveDisabled}
                onClick={handleModalOpen}>
                {PAGE_CONTENT.APPROVE}
              </Button>
            </ButtonWrapper>
          </TabberBox>
        </Box>
      </div>
      {isApproveModal && (
        <ApproveModal
          projectYear={projectYear}
          activityType={activityType}
          isDialogOpen={isApproveModal}
          setIsDialogOpen={setIsApproveModal}
          onClose={() => {
            setIsApproveModal(false);
            onClickBackButton();
          }}
        />
      )}
      {tabList.map((tab, index) => (
        <TabPanel
          key={tab.title}
          value={currentTab}
          index={index}
          style={{ height: 'calc(100vh - 16em)' }}
          boxHeight="100%">
          {tab.component}
        </TabPanel>
      ))}
      <LoaderProvider>
        <ReopenCustomModal
          reopenProps={reopenProps}
          setReopenProps={setReopenProps}
          statusMappingList={statusMappingList}
          onClose={onClickBackButton}
          data-testid="reopen-modal"
        />
      </LoaderProvider>
      <DialogBox
        dialogActions
        isOpen={isModalOpen}
        onConfirm={handleApprove}
        onCancel={handleModalClose}
        declineCtnLabel={PAGE_CONTENT.CANCEL}
        acceptCtnLabel={PAGE_CONTENT.APPROVE}
        title={`${PAGE_CONTENT?.APPROVE} ${activityType?.toLowerCase()} ?`}
        subtitle={modalSubTitle(
          activityType?.toLowerCase(),
          dayjs(activityDate).year(),
        )}
      />
    </>
  );
};

PdfViewerPage.propTypes = {
  heading: PropTypes.string,
  subHeadingArray: PropTypes.array,
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  tabList: PropTypes.array,
  isApproveDisabled: PropTypes.bool,
  onClickBackButton: PropTypes.func,
  setIsApproveDisabled: PropTypes.func,
  activityType: PropTypes.string,
  activityDate: PropTypes.string,
  formId: PropTypes.number,
};

export default PdfViewerPage;
