import {
  DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT,
  DOWNLOAD_ENROLLMENT_BASELINE_REPORT,
  DOWNLOAD_ENROLLMENT_REPORT,
} from 'urls';
import { checkTernaryCondition } from 'utils/helper';
import {
  MenuOrReviewContent,
  MenuProjectEnrollmentWrapper,
  SubHeadingWrapperParent,
} from './ParticipantPages.style';
import { PARTICIPANT_CONTRIBUTOR } from 'utils/config';
import { Typography } from '@mui/material';

export const LANDING_PAGE_CONTENT = {
  contained: 'contained',
  success: 'success',
  medium: 'medium',
  begin_enrolment_btn_label: 'Begin Enrollment',
  welcome_text: 'Welcome, ',
  content: 'Thanks for your interest in participating in the {{project_name}}',
  enrollment_closed_heading: 'Enrollment is closed',
  enrollment_closed_content:
    'You’re no longer able to access {{project_name}} enrollment because enrollment period has closed. Please contact {{support_email}} if you need assistance.',
  support_email: 'support@clearcarbon.earth',
  baseline_landing_content:
    'Ready to start establishing your baseline for {{project_name}}.',
  baseline_begin_btn: 'Begin field baseline reporting',
  baseline_completed_heading:
    '{{year1}} - {{year2}} baseline reporting is closed',
  baseline_completed_content:
    'You’re no longer able to access {{year1}} - {{year2}} baseline reporting for {{project_name}} because baseline reporting period has closed. Please contact {{support_email}} if you need assistance.',
  activity_landing_content:
    'Ready to start reporting on your project activities for {{project_name}}.',
  activity_begin_btn: 'Begin activity reporting',
  activity_completed_heading: '{{year}} activity reporting is closed',
  activity_completed_content:
    'You’re no longer able to access {{year}} activity reporting for {{project_name}} because the reporting period has closed. Please contact {{support_email}} if you need assistance.',
  ENROLLMENT_PROGRESS_STATUS: 'Enrollment In Progress',
  REQUESTED: 'Requested',
  INTERESTED: 'Interested',
  enrollmentEnrolledStatus: 'Enrolled',
  enrollmentModuleName: 'enrollment',
  baselineModuleName: 'baseline',
  activityModuleName: 'activity',
  contributorModuleName: 'contributor',
  baselineEnrollmentType: 'Baseline',
  headingType: 'heading',
  contentType: 'content',
  activityProgressStatus: 'Enrollment In Progress',
  not_started: 'Not started',
  in_progress: 'In Progress',
  completed: 'Completed',
  enrollment_access_denied: 'ENROLLMENT_ACCESS_DENIED',
  no_participantId_with_minus_one: 'No participant found with the given ID -1',
  api_fail_status_401: 401,
  api_fail_status_417: 417,
  reporting: 'reporting',
  submit: 'Submit',
  confirmMessage: 'Once ready, confirm your attestation and submit',
  below: 'below.',
  yourEnrollment: 'your enrollment',
};

export const MENU_PAGE_CONTENT = {
  begin_enrollment_heading:
    'To enroll you must complete each section below beginning with your Profile.',
  return_to_home_text: 'Return to home',
  section_footer:
    'You may save and return to where you left off at any point during your enrollment.',
  participant_profile: 'Profile',
  farm_enrollment: 'Farms',
  fields_enrollment: 'Fields',
  welcome_back_label: 'Welcome back, ',
  welcome_back_content:
    'We’ve saved where you left off. You may continue your progress or make edits to completed sections to finish enrollment.',
  enrollment_label: 'enrollment',
  download_reponse_label: 'Download responses',
  submit_btn: 'Submit enrollment',
  contained: 'contained',
  success: 'success',
  medium: 'medium',
  pdf_download_failed: 'Failed to download responses, Please try again',
  baseline_heading: '{{project_name}} baseline reporting',
  initiate_baseline_sub_heading:
    'Establish the fields for baseline reporting by clicking below.',
  initiate_activity_sub_heading:
    'Establish the fields for activity reporting by clicking below.',
  baseline_content:
    'We saved where you left off so you can continue to complete the fields for baseline reporting',
  ENROLLMENT: 'Enrollment',
  BASELINE_REPORTING: 'Baseline reporting',
  ACTIVITY_REPORTING: 'Activity reporting',
  baseline_review_content:
    'Please review the form and make any edits. When ready select Submit baseline reporting. ',
  submit_baseline_btn_label: 'Submit baseline reporting',
  baseline_complete_content:
    'Thanks for your time and effort. You’re baseline reporting is officially submitted for the {{project_name}}. ',
  activity_complete_content:
    'Thanks for your time and effort. You’re activity reporting is officially submitted for the {{project_name}}. ',
  requestEnrollmentTitle: 'Need to enroll more farms and fields?',
  requestEnrollmentLink: 'Request additional enrollment',
  requestEnrollmentToastMessage:
    'Your request for an additional enrollment instance has been sent.',
  requestEnrollmentFailedToastMessage:
    'Your request for an additional enrollment instance failed to send. Please try again.',
  activity_reporting: 'Activity Reporting',
  activity_reporting_heading: '{{project_name}} activity reporting',
  activity_review_content:
    'Please review the form and make any edits. When ready select Submit activity reporting. ',
  submit_activity_btn_label: 'Submit activity reporting',
  activity_content:
    'We saved where you left off so you can continue to complete the fields for activity reporting',
  baselineUrlEnrollmentType: 'BASELINE',
  activityUrlEnrollmentType: 'ACTIVITY',
  submit: 'Submit',
  BACK: 'Back',
  RETURN_TO_PARTICIPANT_ACTIVITY: 'Return to participant activity',
};

export const FINAL_PAGE_CONTENT = {
  REVIEW_HEADING: 'Almost done!',
  REVIEW_CONTENT:
    'Please review the completed forms below and make any necessary changes before selecting Submit enrollment.',
  FINISHED_PAGE_HEADING: 'Congrats!',
  FINISHED_PAGE_CONTENT_FIRST_PART:
    'Thanks for your time and effort. You’re now officially enrolled in the',
  FINISHED_PAGE_CONTENT_SECOND_PART: 'project',
  PARTICIPANT_PROFILE: 'Participant Profile',
  ERROR: 'error',
  ENROLLMENT_FAILED_ERROR_MESSAGE:
    'Participant enrollment failed, Please  try again',
  ACTIVITY_FINISHED_PAGE_CONTENT_FIRST_PART: 'Thanks for your time and effort.',
  ACTIVITY_FINISHED_PAGE_CONTENT_SECOND_PART:
    'You’re activity reporting for the {{project_name}} project will be reviewed and we’ll notify you by email upon approval.',
  CONTRIBUTOR_FINISHED_PAGE_CONTENT_FIRST_PART:
    'Thanks for your time and effort.',
  CONTRIBUTOR_FINISHED_PAGE_CONTENT_SECOND_PART:
    'Your assigned farm is now submitted for enrollment in the {{project_name}}.',
  LANDING_PAGE_SUB_HEADING_ONE:
    'Please continue with the step(s) indicated below.',
  LANDING_PAGE_SUB_HEADING_TWO: 'Thank you for entering all required data.',
  LANDING_PAGE_SUB_HEADING_THREE:
    'Please review and finalize {{participantName}} {{moduleName}} for the {{projectName}} {{projectCycle}} project cycle year',
  CONGRATS_PAGE_SUB_HEADING:
    'Thank you for entering all required data for {{participantName}}.Please review and approve the data submitted in their participant activity page ',
};

export const getSubHeading = (status, projectName, userRoles) => {
  switch (status) {
    case 'Interested':
      return MENU_PAGE_CONTENT.begin_enrollment_heading;
    case 'Enrollment In Progress':
      return MENU_PAGE_CONTENT.welcome_back_content;
    case 'Enrolled':
      return getFinishedPageContent(userRoles, projectName);
  }
};

const getFinishedPageContent = (userRoles, projectName) => {
  if (userRoles?.includes(PARTICIPANT_CONTRIBUTOR)) {
    return (
      <>
        {FINAL_PAGE_CONTENT.CONTRIBUTOR_FINISHED_PAGE_CONTENT_FIRST_PART}
        <br />
        {FINAL_PAGE_CONTENT.CONTRIBUTOR_FINISHED_PAGE_CONTENT_SECOND_PART.replace(
          '{{project_name}}',
          projectName,
        )}
      </>
    );
  }
  return (
    <>
      {FINAL_PAGE_CONTENT.FINISHED_PAGE_CONTENT_FIRST_PART}
      <SubHeadingWrapperParent>
        <MenuOrReviewContent
          maxWidth="39rem"
          title={checkTernaryCondition(
            projectName?.length > 51,
            projectName,
            '',
          )}>
          {projectName}
        </MenuOrReviewContent>
        <Typography>
          {FINAL_PAGE_CONTENT.FINISHED_PAGE_CONTENT_SECOND_PART}
        </Typography>
      </SubHeadingWrapperParent>
    </>
  );
};

export const getBaselineStatus = (overAllStatus, farmStatus) => {
  if (overAllStatus === null || overAllStatus === 'Interested') {
    return null;
  } else if (
    overAllStatus === 'Enrollment In Progress' &&
    !farmStatus?.isCompleted
  ) {
    return 'In Progress';
  } else {
    return 'Completed';
  }
};
export const getActivityStatus = (overAllStatus, farmStatus) => {
  if (overAllStatus === null || overAllStatus === 'Interested') {
    return null;
  } else if (
    overAllStatus === LANDING_PAGE_CONTENT.activityProgressStatus &&
    !farmStatus
  ) {
    return 'In Progress';
  } else {
    return 'Completed';
  }
};

export const getBaselineSubHeading = (
  isOveralStatusCompleted,
  status,
  projectName,
) => {
  if (isOveralStatusCompleted) {
    return checkTernaryCondition(
      status === 'Enrollment In Progress' || status === null,
      MENU_PAGE_CONTENT.baseline_review_content,
      MENU_PAGE_CONTENT.baseline_complete_content.replace(
        '{{project_name}}',
        projectName,
      ),
    );
  } else {
    return checkTernaryCondition(
      status === null || status === 'Interested',
      MENU_PAGE_CONTENT.initiate_baseline_sub_heading,
      MENU_PAGE_CONTENT.baseline_content,
    );
  }
};
export const getActivitySubHeading = (
  isOveralStatusCompleted,
  status,
  projectName,
) => {
  if (isOveralStatusCompleted) {
    return checkTernaryCondition(
      status === LANDING_PAGE_CONTENT.activityProgressStatus,
      MENU_PAGE_CONTENT.activity_review_content,
      <>
        {FINAL_PAGE_CONTENT.ACTIVITY_FINISHED_PAGE_CONTENT_FIRST_PART}
        <br />
        {FINAL_PAGE_CONTENT.ACTIVITY_FINISHED_PAGE_CONTENT_SECOND_PART.replace(
          '{{project_name}}',
          projectName,
        )}
      </>,
    );
  } else {
    return checkTernaryCondition(
      status === null || status === 'Interested',
      MENU_PAGE_CONTENT.initiate_activity_sub_heading,
      MENU_PAGE_CONTENT.activity_content,
    );
  }
};
export const getMenuPageSubmitButtonLabel = (moduleName) => {
  switch (moduleName) {
    case 'enrollment':
      return MENU_PAGE_CONTENT.submit_btn;
    case 'baseline':
      return MENU_PAGE_CONTENT.submit_baseline_btn_label;
    case 'activity':
      return MENU_PAGE_CONTENT.submit_activity_btn_label;
  }
};

export const getMarginTop = (moduleName) => {
  switch (moduleName) {
    case 'enrollment':
      return '1.5rem';
    case 'baseline':
      return '3.25rem';
    case 'activity':
      return '3.25rem';
  }
};

export const getDownloadHandlerApiUrl = (moduleName) => {
  switch (moduleName) {
    case 'enrollment':
      return DOWNLOAD_ENROLLMENT_REPORT;
    case 'baseline':
      return DOWNLOAD_ENROLLMENT_BASELINE_REPORT;
    case 'activity':
      return DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT;
  }
};

export const getProjectNameHeading = (
  moduleName,
  enrollmentStatus,
  baselineEnrollmentStatus,
  activityReportingStatus,
  projectName,
  projectNameHeading,
) => {
  if (moduleName === 'enrollment') {
    if (enrollmentStatus !== 'Enrolled') {
      return (
        <MenuProjectEnrollmentWrapper>
          {projectName} {MENU_PAGE_CONTENT.enrollment_label}
        </MenuProjectEnrollmentWrapper>
      );
    }
  } else if (moduleName === 'baseline') {
    if (
      baselineEnrollmentStatus === 'Enrollment In Progress' ||
      baselineEnrollmentStatus === null ||
      baselineEnrollmentStatus === 'Interested'
    ) {
      return (
        <MenuProjectEnrollmentWrapper>
          {projectNameHeading}
        </MenuProjectEnrollmentWrapper>
      );
    }
  } else if (moduleName === 'activity') {
    if (
      activityReportingStatus === LANDING_PAGE_CONTENT.activityProgressStatus ||
      activityReportingStatus === null ||
      activityReportingStatus === 'Interested'
    ) {
      return (
        <MenuProjectEnrollmentWrapper>
          {projectNameHeading}
        </MenuProjectEnrollmentWrapper>
      );
    }
  }
};

export const getEnrollmentType = (moduleName) => {
  switch (moduleName) {
    case 'enrollment':
      return 'Participant';
    case 'baseline':
      return 'Baseline';
    case 'activity':
      return 'Activity';
  }
};
