import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import axios from 'axios';
import { FETCH_AUDIT_LOG_DETAILS } from 'urls';
import { uniqueId } from 'utils/uniqueIdGenerator';

import {
  covertDateToLocalTimeZone,
  formatUTCToLocal,
} from '../../utils/helper';

import {
  CellValueWrapper,
  DataGridWrapper,
  FilterHeader,
  MainWrapper,
  RowCellWrapper,
  TextWrapper,
  datagridSx,
} from '../../components/NewApplicationAuditLogComp/NewApplicationAuditLogComp.style';
import { PAGE_CONTENT, SortColValues } from './ViewDetailedAuditLog.content';

import { TablePaginationMenuListStyle } from 'theme/GlobalStyles';
import { SortWrapper } from 'pages/OriginationProjectList/OriginationProjectList.style';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { handleArrowColor } from './ViewDetailedAuditLog.style';
import LightTooltip from 'components/LightTooltip';

const customCell = (value1, value2, color1, color2, cellClickHandler) => {
  return (
    <RowCellWrapper direction={PAGE_CONTENT.column} onClick={cellClickHandler}>
      <LightTooltip title={value1}>
        <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
          {value1 ? value1 : '--'}
        </CellValueWrapper>
        <TextWrapper fontSize="0.75rem" fontWeight={400} color={color2}>
          {value2}
        </TextWrapper>
      </LightTooltip>
    </RowCellWrapper>
  );
};

const ViewDetailedAuditLog = ({
  disableVirtualization,
  participantId,
  projectId,
  projectCycleId,
}) => {
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [sortFilter, setSortFilter] = useState({
    column: SortColValues.createdDate,
    order: 'DESC',
  });
  const [auditData, setAuditData] = useState([]);

  useEffect(() => {
    fetchAuditData();
  }, [sortFilter, pageState.page, pageState.pageSize]);

  const fetchAuditData = (enableLoader = true) => {
    setPageState((old) => ({ ...old, isLoading: enableLoader }));
    const projectIdToNewestToOldest = {
      pageNumber: pageState.page,
      pageSize: pageState.pageSize,
    };
    const newPayload = {
      ...projectIdToNewestToOldest,
      sortFilter,
    };
    axios
      .post(
        `${FETCH_AUDIT_LOG_DETAILS}?participantId=${participantId}&projectId=${projectId}&projectCycleId=${projectCycleId}`,
        newPayload,
      )
      .then((response) => {
        setAuditData(
          response.data.content.map((item) => {
            return {
              id: uniqueId(),
              batchId: item?.batchId,
              acres: item?.acres,
              time: item?.createdDate,
              email: item?.email,
              user: item?.user,
              phase: item?.phase,
              status: item?.status,
            };
          }),
        );
        setPageState((old) => ({
          ...old,
          isLoading: false,
          total: response?.data?.totalElements,
        }));
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleSortClick = (params) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    setSortFilter((prevState) => ({
      column: SortColValues[params.field],
      order: prevState.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };
  const columns = [
    {
      field: PAGE_CONTENT.phase_field,
      headerName: PAGE_CONTENT.phase,
      disableColumnMenu: true,
      width: 17.33,
      flex: 1,
      sortable: false,
      renderCell: (params) => customCell(params?.row?.phase),
    },
    {
      field: PAGE_CONTENT.object_field,
      disableColumnMenu: true,
      width: 17.33,
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>
            {PAGE_CONTENT.batchId}
          </TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                data-testid="Object-up-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                data-testid="Object-down-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => customCell(params?.row?.batchId),
    },
    {
      field: PAGE_CONTENT.acres_field,
      headerName: PAGE_CONTENT.acres,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      align: '',
      width: 17.33,
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>{PAGE_CONTENT.acres}</TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                fontSize="small"
                data-testid="Acres-up-arrow"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                data-testid="Acres-down-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => customCell(params?.row?.acres),
    },
    {
      field: PAGE_CONTENT.status_field,
      headerName: PAGE_CONTENT.status,
      disableColumnMenu: true,
      width: 17.33,
      flex: 1,
      sortable: false,
      renderCell: (params) => customCell(params?.row?.status),
    },
    {
      field: PAGE_CONTENT.created_date_field,
      headerName: PAGE_CONTENT.time,
      disableColumnMenu: true,
      sortable: false,
      width: 17.33,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>{PAGE_CONTENT.time}</TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                data-testid="Time-up-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            ) : (
              <ArrowDownward
                data-testid="Time-down-arrow"
                fontSize="small"
                sx={handleArrowColor(params.field, sortFilter.column)}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) =>
        customCell(
          covertDateToLocalTimeZone(params?.row?.time).slice(0, 10),
          formatUTCToLocal(params?.row?.time),
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },
    {
      field: PAGE_CONTENT.user_name,
      headerName: PAGE_CONTENT.user,
      disableColumnMenu: true,
      sortable: false,
      width: 17.33,
      flex: 1,
      renderCell: (params) =>
        customCell(
          params?.row?.user,
          params?.row?.email,
          PAGE_CONTENT.backgroundColor,
          PAGE_CONTENT.cool_gray,
        ),
    },
  ];

  return (
    <MainWrapper>
      <DataGridWrapper>
        <DataGrid
          headerHeight={40}
          disableVirtualization={disableVirtualization || false}
          disableSelectionOnClick
          loading={pageState.isLoading}
          sx={{ ...datagridSx, marginTop: 0 }}
          rowCount={pageState.total}
          columns={columns}
          rows={auditData}
          page={pageState.page - 1}
          paginationMode={PAGE_CONTENT.pagination_mode}
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage + 1 }))
          }
          pageSize={pageState.pageSize}
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          pagination
          rowsPerPageOptions={PAGE_CONTENT.default_pagination_options}
          componentsProps={{
            pagination: {
              labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
              SelectProps: TablePaginationMenuListStyle,
            },
          }}
        />
      </DataGridWrapper>
    </MainWrapper>
  );
};

ViewDetailedAuditLog.propTypes = {
  disableVirtualization: PropTypes.bool,
  participantId: PropTypes.string,
  projectId: PropTypes.string,
  projectCycleId: PropTypes.string,
};

export default ViewDetailedAuditLog;
