import { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { ActivityActionButtonSx, MenuSx } from '../CustomOptionsButton.style';
import { useActivityContext } from 'containers/ActivityListContainer';
import RecruitmentMenuItems from '../ActivityMenuItem/RecruitmentMenuItem.react';
import EnrollmentMenuItems from '../ActivityMenuItem/EnrollmentMenuItem.react';
import BaselineReportingMenuItems from '../ActivityMenuItem/BaselineReportingMenuItem.react';
import { ACTIVITY_TYPE } from './ActivityOptions.constants';
import { ActivityReportingMenuItems } from '../ActivityMenuItem/ActivityReportingMenuItems.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkTernaryCondition, hasAnyRequiredAccess } from 'utils/helper';
import {
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
} from 'utils/config';
import OutreachMenuItems from '../ActivityMenuItem/OutreachMenuItem.react';

export const ActivityOptionsButton = ({ horizIcon = false }) => {
  const { isClosed, activityType, anchorEl, setAnchorEl } =
    useActivityContext();
  const open = Boolean(anchorEl);
  const { userRoles } = useContext(userAuthRolesContext);
  // Handle button click
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }, []);

  // Handle menu close
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // Close menu when isClosed changes to true
  useEffect(() => {
    isClosed && handleClose();
  }, [isClosed, handleClose]);

  const disableCheck = () => {
    const accessibleActivityTypes = [
      ACTIVITY_TYPE.OUTREACH,
      ACTIVITY_TYPE.CONTRACTING,
      ACTIVITY_TYPE.ENROLLMENT,
      ACTIVITY_TYPE.BASELINE_REPORTING,
      ACTIVITY_TYPE.ACTIVITY_REPORTING,
    ];

    if (hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER])) {
      return [ACTIVITY_TYPE.OUTREACH, ACTIVITY_TYPE.CONTRACTING].includes(
        activityType,
      );
    } else {
      return (
        accessibleActivityTypes.includes(activityType) &&
        hasAnyRequiredAccess(userRoles, [PARTICIPANT, PARTICIPANT_CONTRIBUTOR])
      );
    }
  };
  // Determine which menu items to render based on activityType
  const renderMenuItems = () => {
    switch (activityType) {
      case ACTIVITY_TYPE.OUTREACH:
        return <OutreachMenuItems />;
      case ACTIVITY_TYPE.CONTRACTING:
        return <RecruitmentMenuItems />;
      case ACTIVITY_TYPE.ENROLLMENT:
        return <EnrollmentMenuItems />;
      case ACTIVITY_TYPE.BASELINE_REPORTING:
        return <BaselineReportingMenuItems />;
      case ACTIVITY_TYPE.ACTIVITY_REPORTING:
        return <ActivityReportingMenuItems />;
      default:
        return null;
    }
  };

  return (
    <>
      <IconButton
        disabled={disableCheck()}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          ...ActivityActionButtonSx,
          opacity: checkTernaryCondition(disableCheck(), 0.6, 1),
        }}>
        {checkTernaryCondition(horizIcon, <MoreHoriz />, <MoreVertIcon />)}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={MenuSx}>
        {renderMenuItems()}
      </Menu>
    </>
  );
};

ActivityOptionsButton.propTypes = {
  horizIcon: PropTypes.bool,
};
