const constructWebsocketUrl = () => {
  const backendBaseUrl = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : window.location.origin;
  return backendBaseUrl.replace('https', 'wss');
};
export const BASE_URL = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/api/v1`
  : `/api/v1`;

export const WEBSOCKET_URL = `${constructWebsocketUrl()}/api/v1`;

export const NOTIFICATION_URL = `${WEBSOCKET_URL}/ws`;

export const WEBSOCKET_URL_STATUS = `${WEBSOCKET_URL}/check-status`;

export const REGISTRY = `${BASE_URL}/project-dropdown/registry`;

export const METHODOLOGY = `${BASE_URL}/project-dropdown/methodology`;

export const COUNTRY = `${BASE_URL}/project-dropdown/country`;

export const REGION = `${BASE_URL}/project-dropdown/region`;

export const STATUS = `${BASE_URL}/project-dropdown/new/status`;

export const PRICE_ASSUMPTION_TYPE = `${BASE_URL}/project-dropdown/price-assumption-group`;

export const REGISTRY_METHODOLOGY = `${BASE_URL}/project-registry/methodology`;

export const PROJECT = `${BASE_URL}/project`;

export const ORIGINATION = `${BASE_URL}/origination`;

export const GENERAL_SETTING_PAGE = `${ORIGINATION}/project/general-settings-page?internalProjectId=`;

export const DESIGN_SETTING_PAGE = `${ORIGINATION}/project/project-design-settings-page?internalProjectId=`;

export const PROJECT_NEW = `${BASE_URL}/project/new`;

export const PROJECT_CONTAINS = `${BASE_URL}/project-registry/contains-ids`;

export const PROJECT_TYPES = `${BASE_URL}/project-registry/type`;

export const PROJECT_REGISTRY_ID = `${BASE_URL}/project-registry`;

export const PROJECT_TAGS = `${BASE_URL}/project-dropdown/tags`;

export const PROJECT_ALL_TAGS = `${BASE_URL}/project-dropdown/all-tags`;

export const VENDOR_LIST = `${BASE_URL}/vendor/vendorOrganization`;

export const VENDOR_DETAIL = `${BASE_URL}/vendor/getAllVendorWIthContacts`;

export const VENDOR_SUBMIT = `${BASE_URL}/vendor/submitVendor`;

export const VENDOR_REMOVE = `${BASE_URL}/vendor/removeContact`;

export const STAKEHOLDERS_PROFILES = `${BASE_URL}/vendor/profile`;

export const STAKEHOLDER_ORG_CHECK = `${BASE_URL}/vendor/validate/stakeholder-organization`;

export const VENDOR_PROJECT_DETAILS = `${BASE_URL}/vendor/projectDetails`;

export const VENDOR_PROJECT_TABLE_COLUMN = `${BASE_URL}/vendor/list-deep-dive`;

export const PROJECT_CATALOG = `${BASE_URL}/project/catalog`;

export const VENDOR_BY_ID = `${BASE_URL}/vendor/getVendor`;

export const FILE_SNAPSHOT = `${BASE_URL}/file-snapshot`;

export const FILE_UPLOAD = `${BASE_URL}/upload`;

export const FILE_DOWNLOAD = `${BASE_URL}/download`;

export const LOOKER_SIGNED_URL = `${BASE_URL}/looker/signed-url`; // get signed looker embed url

export const Run_CALCULATION = `${BASE_URL}/calculate`;

export const RUN_CALC = `${ORIGINATION}/calculations/trigger/calculation-branch-implementation`;

export const COUNTRY_COLUMN = `${PROJECT}/list?column=COUNTRY`;

export const PROJECT_TYPE_COLUMN = `${PROJECT}/list?column=PROJECT_TYPE`;

export const PROJECT_DEVELOPERS_COLUMN = `${PROJECT}/list?column=PROJECT_DEVELOPER`;

export const PROJECT_NAME_COLUMN = `${PROJECT}/list?column=PROJECT_NAME`;

export const INTERNAL_REVIEW_STATUS_COLUMN = `${PROJECT}/list?column=INTERNAL_REVIEW_STATUS`;

export const STAKEHOLDER_NAME_COLUMN = `${BASE_URL}/vendor/list?column=STAKEHOLDER_NAME`;

export const STAKEHOLDER_TYPE_COLUMN = `${BASE_URL}/vendor/list?column=STAKEHOLDER_TYPE`;

export const CATEGORY_OF_TRANSACTION = `${BASE_URL}/contract/category-of-purchase`;

export const STAKEHOLDER_CONTAINS = `${BASE_URL}/vendor/containsContractType`;

export const BENEFIT_SHARE_ASSUMPTION = `${BASE_URL}/contract/benefit-share-assumption-type`;

export const COST_STRUCTURE = `${BASE_URL}/contract/cost-structure`;

export const PROJECT_TYPEAHEAD = `${BASE_URL}/project/contains`;

export const ASSOCIATED_TYPE = `${BASE_URL}/vendor/investor-or-financer`;

export const TRANSACTION_PURCHASE_SUBMIT = `${BASE_URL}/contract/purchase`;

export const TRANSACTION_PURCHASE_DETAILS = `${BASE_URL}/contract/purchase`;

export const SALE_PROJECT_CONTAINS = `${BASE_URL}/project/contractStatusContracted`;

export const VINTAGE_DATES = `${BASE_URL}/contract/vintage-dates`;

export const TRANSACTION_SALE_SUBMIT = `${BASE_URL}/contract/sale`;

export const TRANSACTION_SALE_DETAILS = `${BASE_URL}/contract/sale`;

export const AVAILABLE_QUANTITY = `${BASE_URL}/contract/sale/availableMTCO2ePerProject/`;

export const DELIVERY_DETAILS_SUBMIT = `${BASE_URL}/contract/sale/delivery-detail/validate`;

export const ROW_LEVEL_VALIDATION = `${BASE_URL}/contract/sale/delivery-detail/row/validate`;

export const COLUMN_LIST = `${PROJECT}/list`;

export const LOGIN = `${BASE_URL}/login`;

export const TRANSACTION_LIST_API = `${BASE_URL}/contract/list`;

export const TAGS_COLUMN = `${PROJECT}/list?column=TAGS`;

export const CHANGE_LOG = `${BASE_URL}/project/getProjectChangeLog`;

export const PROJECT_SEARCH_FIELDS = `${BASE_URL}/search/project/search-fields`;

export const PROJECT_SEARCH = `${BASE_URL}/search/project`;

export const TRANSACTION_SEARCH_FIELDS = `${BASE_URL}/search/transaction/search-fields`;

export const TRANSACTION_SEARCH = `${BASE_URL}/search/transaction`;

export const DELETE_PROJECT = `${PROJECT}/delete`;

export const DELETE_STAKEHOLDER = `${BASE_URL}/vendor/delete`;

export const DELETE_TRANSACTION = `${BASE_URL}/contract/delete`;

export const NOTIFICATION_LOGS = `${BASE_URL}/fileUploadNotification/get-all-notifications`;

export const DISMISS_NOTIFICATION = `${BASE_URL}/fileUploadNotification/mark-viewed`;

export const HELP_DOC_DOWNLOAD = `${BASE_URL}/download/doc`;

export const SETTINGS = `${ORIGINATION}/settings`;

export const CSRF_TOKEN_ENDPOINT = `${ORIGINATION}/csrf/token`;

export const AUDIT_LOG = `${BASE_URL}/audit`;

export const TRANSACTION_NAME_COLUMN = `${BASE_URL}/contract/list-dropdown-values?transactionFilterType=TRANSACTION_NAME`;

export const TRANSACTION_STAKEHOLDER_NAME_COLUMN = `${BASE_URL}/contract/list-dropdown-values?transactionFilterType=STAKEHOLDER_NAME`;

export const TRANSACTION_TYPE_COLUMN = `${BASE_URL}/contract/list-dropdown-values?transactionFilterType=TYPE`;

export const TRANSACTION_STATUS_COLUMN = `${BASE_URL}/contract/list-dropdown-values?transactionFilterType=STATUS`;

export const ORIGINATION_TAG_LIST = `${BASE_URL}/origination/tag`;

export const ORIGINATION_COUNTRY_LIST = `${BASE_URL}/origination/project/countries`;

export const CREATE_ORIGINATION_PROJECT = `${BASE_URL}/origination/project`;

export const DUPLICATE_ORIGINATION_PROJECT = `${CREATE_ORIGINATION_PROJECT}/search`;

export const PROJECT_ORIGINATION_LIST = `${CREATE_ORIGINATION_PROJECT}/list`;
export const DELETE_ORIGINATION_PROJECT = `${ORIGINATION}/project`;

export const FETCH_PARTICIPANTS = `${ORIGINATION}/participant/fetchAllParticipants`;

export const PARTICIPANTS_LIST = `${ORIGINATION}/participant/list`;

export const PARTICIPANT_EMAIL_DETAILS = `${ORIGINATION}/participant/re-enrol`;

export const SEND_BASELINE_REOPEN_EMAIL = `${ORIGINATION}/email/reopen-baseline/sendEmail`;

export const SEND_ENROLMENT_REOPEN_EMAIL = `${ORIGINATION}/email/reopen-enrolment/sendEmail`;

export const SEND_ACTIVITY_EMAIL = `${ORIGINATION}/email/activity/sendRequestEmail`;

export const ADD_NEW_FIELD = `${ORIGINATION}/geospatial/field-boundaries`;

export const ADD_PARTICIPANT = `${ORIGINATION}/participant`;

export const UPDATE_PARTICIPANT_STATUS = `${ORIGINATION}/participant/enroll/update-status`;

export const FETCH_PARTICIPANT_NAMES = `${ORIGINATION}/participant/fetchAllParticipants`;

export const BOUNDARY_SHAPE_FILE_UPLOAD = `${ORIGINATION}/participant/upload/boundary-file`;

export const VALIDATE_FIELD_SPATIAL_DATA = `${ORIGINATION}/geospatial/validate-field-spatial-data`;

export const FETCH_PARTICIPANT_DETAILS = `${ORIGINATION}/participant`;

export const FETCH_POSSIBLE_USER_STATUS = `${ORIGINATION}/participant/get-participant-user-statuses-drop-down`;

export const FETCH_POSSIBLE_ACTIVITY_STATUS = `${ORIGINATION}/participant/getParticipantAccountActivity`;

export const UPDATE_ACCOUNT_STATUS = `${ORIGINATION}/participant/updateParticipantAccountStatus`;

export const FETCH_VIEW_ORIGINATION_PROJECT = `${BASE_URL}/origination/project`;

export const DELETE_PARTICIPANT = `${ORIGINATION}/participant`;

export const ORIGINATION_PROJECT_NAME_COLUMN = `${CREATE_ORIGINATION_PROJECT}/attribute/project`;

export const ORIGINATION_PROJECT_TYPE_COLUMN = `${CREATE_ORIGINATION_PROJECT}/attribute/type`;

export const ORIGINATION_PROJECT_STATUS_COLUMN = `${CREATE_ORIGINATION_PROJECT}/attribute/status`;

export const FETCH_CLIMATE_PROGRAMS = `${CREATE_ORIGINATION_PROJECT}/climatePrograms`;

export const MAIN_AUDIT_LOG = `${ORIGINATION}/audit`;

export const MODULE_ROLES = `${ORIGINATION}/user/roles`;

export const USER_CHECK_EMAIL = `${ORIGINATION}/user/checkEmail/`;

export const ADD_NEW_USER = `${ORIGINATION}/user/addUser`;

export const EDIT_USER = `${ORIGINATION}/user/editUser`;

export const REMOVE_USER = `${ORIGINATION}/user/removeUser/`;

export const GET_USER_DETAILS = `${ORIGINATION}/user/getUser/`;
export const AUDIT_LOG_FILTER_USER = `${ORIGINATION}/audit/attribute/user`;

export const AUDIT_LOG_FILTER_MODULE = `${ORIGINATION}/audit/attribute/module`;

export const AUDIT_LOG_FILTER_ACTION = `${ORIGINATION}/audit/attribute/action`;

export const AUDIT_LOG_FILTER_CATEGORY = `${ORIGINATION}/audit/attribute/category`;

export const AUDIT_LOG_FILTER_OBJECT = `${ORIGINATION}/audit/attribute/object`;

export const USER_LIST = `${ORIGINATION}/user/list`;

export const USER_ROLES = `${ORIGINATION}/user/roles/registered`;

export const PARTICIPANT_PROFILE = `${ORIGINATION}/participant/getParticipantProfile`;

export const FETCH_PARTICIPANT_PROFILE_ACTIVITY = `${PARTICIPANT_PROFILE}?enrollmentType=ACTIVITY`;

export const SUBMIT_PARTICIPANT_GENERAL_INFO = `${ORIGINATION}/participant/enroll`;

export const PARTICIPANT_GENERAL_FARM_INFO = `enroll/farms-info`;

export const FETCH_GENERAL_FARM_INFO = `${ORIGINATION}/participant/farm`;

export const EDIT_GENERAL_FARM_INFO = `${ORIGINATION}/participant/farm`;

export const FARM_INFO_BASE = `${ORIGINATION}/participant/farm`;

export const FIELD_INFO_BASE = `${ORIGINATION}/participant/field`;

export const FETCH_PARTICIPANT_ACTIVITY = `${ORIGINATION}/participant`;
export const GENERATE_ORIGINATION_PROJECT_REPORT = `${ORIGINATION}/project/report`;
export const GENERATE_ORIGINATION_PARTICIPANT_PROJECT_ENROLLMENT_REPORT = `${ORIGINATION}/participant`;

export const GENERATE_ORIGINATION_PROJECT_ENROLLMENT_REPORT = `${ORIGINATION}/project/report/enrollment`;

export const PARTICIPANT_REPORT_FIRST_PART = `${ORIGINATION}/participant`;

export const FETCH_PARTICIPANT_RELATED_PROJECTS = `related-projects`;

export const GENERATE_ORIGINATION_PARTICIPANT_REPORT = `report`;

export const FETCH_PROJECT_REPORT_LIST_FIRST_PART = `${BASE_URL}/origination/project`;

export const FETCH_ALL_PROJECT_REPORTS = `${ORIGINATION}/project/reports/getAll`;

export const FETCH_PARTICIPANTS_FOR_REPORT = `${ORIGINATION}/participant/fetch-all-participants-reports`;

export const FETCH_PROJECT_REPORT_LIST_SECOND_PART = `reports`;

export const FETCH_PARTICIPANT_REPORT_LIST_SECOND_PART = `reports`;

export const CHECK_DUPLICATE_EMAIL = `${ORIGINATION}/check/email`;

export const UPDATE_LAST_ACTIVITY = `${ORIGINATION}/user/updateUserLastActivity`;

export const DELETE_ORIGINATION_REPORT = `${ORIGINATION}/project/reports`;

export const GET_BASELINE_FIELD_SAMPLE_ID = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-baseline`;

export const FETCH_FOSSIL_FUEL_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-all-fossil`;

export const FETCH_GRAZING_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/livestock-grazing`;

export const FETCH_MANURE_MANAGEMENT_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/manure-management`;

export const FETCH_LIVESTOCK_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-all-livestock`;

export const FETCH_FERTILIZER_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/getFertilizerType`;

export const FETCH_FERTILIZER_APPLICATION_METHOD = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/getFertilizerApplicationMethod`;

export const FETCH_MANURE_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/getManureType`;

export const FETCH_IRRIGATION_METHOD_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/getIrrigationMethod`;

export const SUBMIT_BASELINE_FIELD_INFO = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/baseline-field-info`;

export const SUBMIT_ACTIVITY_FIELD_INFO = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/project-activity`;

export const EDIT_BASELINE_FIELD_INFO = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/edit-baseline-field`;

export const EDIT_ACTIVITY_FIELD_INFO = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/edit-project-activity-field`;

export const FETCH_CROP_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-all-crops`;

export const FETCH_SOIL_AMENDMENTS_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/soil-amendments`;

export const FETCH_TILLAGE_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/getTillageType`;

export const FETCH_COVER_CROP_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-all-cover-crops`;

export const FETCH_COVER_CROP_PLANNED_USAGE__DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/cover-crop-planned-usage`;

export const FETCH_COVER_CROP_TERMINATION_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/cover-crop-termination-type`;

export const FETCH_GENERAL_FIELD_INFO = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-general-field`;

export const EDIT_GENERAL_FIELD_INF0 = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/edit-general-field`;

export const FETCH_ORIGINATION_PARTICIPANT_LIST = `${ORIGINATION}/participants`;

export const FETCH_PARTICIPANT_CALCULATION_REPORT_DETAILS = `${ORIGINATION}/task/calculation/report`;

export const UPDATE_PARTICIPANT_ENROLLMENT_STATUS = `${ORIGINATION}/participant/enroll/update-participant-status`;

export const GET_PARTICIPANT_INFO = `${ORIGINATION}/participant/profile/info`;

export const GET_BASELINE_FIELD_DATA = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-baseline-field`;

export const GET_PARTICIPANT_PROJECT_LIST = `${ORIGINATION}/participant/get-projects-for-enrolling?participantId`;

export const CHECK_PARTICIPANT_PROJECT = `${ORIGINATION}/participant/check-for-participant`;
export const SEND_REQUEST_EMAIL = `${ORIGINATION}/email/sendEmail`;

export const SEND_BASELINE_REQUEST_EMAIL = `${ORIGINATION}/email/baseline/sendEmail`;

export const REPORT_EXCEL_EXPORT_FIRST_PART = `${ORIGINATION}/project`;

export const REPORT_EXCEL_EXPORT_SECOND_PART = `report/exportExcel`;

export const CHECK_IS_PARTICIPANT_LINKED = `${ORIGINATION}/participant/check-if-participant-linked`;

export const GET_ROLE_AND_PERMISSIONS = `${ORIGINATION}/user/app/role`;

export const FETCH_AVAILABLE_FARMS = `${ORIGINATION}/participant/getAllAvailableFarm`;

export const UPLOAD_CLIENT_LOGO = `${ORIGINATION}/settings/upload-logo`;

export const DOWNLOAD_ENROLLMENT_REPORT = `${ORIGINATION}/participant/enrollment/:participnatId/report`;

export const FETCH_MOTIVATION_LIST = `${ORIGINATION}/participant/profile/motivation`;

export const FETCH_STATE_LIST = `${ORIGINATION}/participant/profile/state`;

export const FETCH_COUNTY_LIST = `${ORIGINATION}/participant/profile/county`;

export const FETCH_ACTIVITY_DROPDOWN_LIST = `${ORIGINATION}/participant/enroll/project-activities`;

export const FETCH_PROJECT_CALCULATION_REPORT = `${ORIGINATION}/task/calculation/report/projectCalculationReport`;

export const FETCH_PROJECT_CALCULATION_REPORT_SUMMARY = `${ORIGINATION}/task/calculation/report/projectCalculationReportGeneralInfo`;

export const FETCH_TOTAL_FIELD_AREA = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/getTotalFieldArea`;

export const FETCH_ALL_CALCULATIONS_LABEL = `${ORIGINATION}/task/calculation/report/getAllCalculationLabels`;

export const DOWNLOAD_ENROLLMENT_BASELINE_REPORT = `${ORIGINATION}/participant/enrollment/baseline/:participnatId/:projectId/report`;

export const DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT = `${ORIGINATION}/participant/enrollment/activity/:participnatId/:projectId/report`;

export const ADD_CONTRACT = `${ORIGINATION}/participant/contract`;

export const FETCH_BASELINE_FARM_YEARS = `${ORIGINATION}/participant/farm/baseline-years`;

export const FETCH_BASELINE_FARM_DATA = `${ORIGINATION}/participant/farm/baseline`;

export const SUBMIT_BASELINE_FARM = `${ORIGINATION}/participant/farm/baseline`;

export const SUBMIT_ACTIVITY_FARM = `${ORIGINATION}/participant/farm/activity`;

export const FETCH_ACTIVITY_LIST = `${ORIGINATION}/participant/baseline/project-activity`;

export const FETCH_EDGE_OF_FIELD_PRACTICE_TYPE = `${ORIGINATION}/participant/enroll/getEdgeOfFieldPracticesType`;

export const FETCH_EDGE_OF_FIELD_SPICES_TYPE = `${ORIGINATION}/participant/enroll/getAllSpeciesCategory`;

export const FETCH_ALL_ACTIVITIES = `${ORIGINATION}/project/activities`;

export const FETCH_ALL_COMMERCIALIZATION_APPROACHES = `${ORIGINATION}/project/commercialization-approaches`;

export const FETCH_ALL_STANDARDS_BODY = `${ORIGINATION}/project/standard-bodies`;

export const FETCH_ALL_PROJECT_TYPES = `${ORIGINATION}/project/project-types`;

export const FETCH_ALL_PROJECT_ACTIVITY_CATEGORIES = `${ORIGINATION}/project/project-activity-categories`;

export const FETCH_FARM_COPY_ACROSS_DROPDOWN_DATA = `${ORIGINATION}/participant/farm/copy-across-years`;

export const FETCH_AUDIT_LOG_DETAILS = `${ORIGINATION}/participant/detailed-audit-logs`;

export const FETCH_FUEL_COPIED_DATA = `${ORIGINATION}/participant/farm/get-details-for-copied`;

export const FETCH_FIELD_COPY_ACROSS_DROPDOWN_DATA = `${ORIGINATION}/field/previous-field-information`;

export const FETCH_CROP_COPIED_DATA = `${ORIGINATION}/field`;

export const SUBMIT_SUPPORT_MODAL = `${ORIGINATION}/supportModel/createRequest`;

export const SUBMIT_SUPPORT_MODAL_GLOBAL = `${ORIGINATION}/supportModel/createRequestGlobal`;

export const FETCH_CONTRACT_PDF = `${ORIGINATION}/participant/getContractPdfSignedUrl?contractPdfBucketId=`;

export const GET_PROJECT_FOR_PARTICIPANTS = `${ORIGINATION}/participant/get-projects-for-participant`;

export const FETCH_SUMMARY_GRAPH_DATA = `${ORIGINATION}/task/calculation/report/getProjectSummaryGraph?internalProjectId=`;
export const GET_FARM_AND_FIELDS_FOR_PARTICIPANTS = (participantId) =>
  `${ORIGINATION}/participant/${participantId}/summary/get-details`;

export const FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST = `${ORIGINATION}/participant/project-activities`;
export const REQUEST_ADDITIONAL_ENROLLMENT_FROM_BASELINE = `${ORIGINATION}/email/sendEmail/additionalInstances`;

export const CHECK_LINKED_PARTICIPANTS = `${ORIGINATION}/participant/searchForParticipantsLinked`;

export const FETCH_APPROVED_PARTICIPANTS = `${ORIGINATION}/participant/fetchAllApprovedParticipants`;

export const ADD_MULTIPLE_PARTICIPANTS = `${ORIGINATION}/participant/addMultipleParticipants`;

export const INITIATE_NEW_ENROLLMENT_INSTANCE = `${ORIGINATION}/participant/enrollment-instance`;

export const UPLOAD_PARTICIPANTS_FILE = `${ORIGINATION}/participants/scheduler`;

export const GET_PARTICIPANT_JOB_STATUS = `${ORIGINATION}/participants/scheduler`;

export const FETCH_PROJECT_LIST = `${ORIGINATION}/project/list-projects`;

export const FETCH_METHODOLOGY_LIST = `${ORIGINATION}/project/reference-methodology-list`;

export const PORTFOLIO_KPI = `${ORIGINATION}/portfolio/kpi`;

export const FETCH_GHG_INSIGHTS = `${ORIGINATION}/portfolio/ghg-insights`;
export const FETCH_ALL_USER_ACTIONS = `${ORIGINATION}/participant/getAllUserActions`;

export const GET_ACTIVITY_FLAG = `${ORIGINATION}/field/get-activity-flag`;
export const UPDATE_ACTIVITY_FLAG = `${ORIGINATION}/field/update-activity-flag`;

export const UPDATE_PARTICIPANT_ACTIVITY_STATUS = `${ORIGINATION}/participant/updateUserActivityLog`;

export const GENERATE_BASELINE_INSTANCE_ID = `${ORIGINATION}/participant/farm/createReportingFormInstance`;

export const UPDATE_BASELINE_ACTVITY_STATUS = `${ORIGINATION}/participant/farm/updateReportingFormActivityLog`;

export const UPDATE_PARTICIPANT = `${ORIGINATION}/participant/profile/update`;

export const FETCH_PROJECT_SUMMARY_PROGRESS_BAR_DATA = `${ORIGINATION}/project/summary-progress-tracker/`;

export const FETCH_ACTIVITY_TAB_DATA = `${ORIGINATION}/activityTab/fetch-activity-tab-data`;

export const FETCH_LANDING_PAGE_DATA = `${ORIGINATION}/participant/fetch-participant-landing-page-data`;

export const FETCH_PROJECT_MAP_DATA = `${ORIGINATION}/portfolio/project-maps`;

export const FETCH_PARTICIPANT_NOTIFICATIONS = `${ORIGINATION}/participants/scheduler/status`;

export const UPDATE_JOB_STATUS = `${ORIGINATION}/participants/scheduler/status`;

export const UPDATE_USER_MODULES = `${ORIGINATION}/user/update-module`;

export const FETCH_RESIDUE_MANAGEMENT_REMOVAL_METHOD = `${ORIGINATION}/participant/enroll/crop-residue-managements`;

export const FETCH_ALL_CROP_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-all-crops-with-production-purpose?productionPurpose`;

export const FETCH_MANAGEMENT_TYPE_DROPDOWN = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/get-crop-planned-management-type`;

export const FETCH_PROJECT_GHG_INSIGHTS = `${ORIGINATION}/project/ghg-insights`;

export const FETCH_TILLAGE_SYSTEM_TYPE = `${ORIGINATION}/participant/enroll/get-all-tillage-system-type`;

export const FETCH_DISTURBANCE_TYPE = `${ORIGINATION}/participant/enroll/get-all-disturbance-type?tillageSystemType`;

export const FETCH_PARTICIPANT_GHG_INSIGHTS = `${ORIGINATION}/participant/ghg-insights`;

export const FETCH_SOIL_SAMPLING_DATA = `${ORIGINATION}/project/get-all-soil-sample-data`;

export const DOWNLOAD_SOIL_SAMPLING_DATA = `${ORIGINATION}/project/download-soil-sample-csv-file`;

export const UPLOAD_SOIL_SAMPLING_DATA = `${ORIGINATION}/project/upload-soil-sample-data`;

export const FETCH_FARM_INFO_LIST = `${ORIGINATION}/participant/farms`;

export const BULK_ADD_OR_EDIT_FARMS = `${ORIGINATION}/participant/farm/bulk-edit`;

export const FETCH_TILLAGE_SYSTEM_TYPE_BY_ID = `${ORIGINATION}/participant/enroll/get-tillage-system-disturbance/`;

export const ADD_PARTICIPANT_CONTRIBUTOR = `${ORIGINATION}/participant/contributor/add-participant-contributor`;

export const FETCH_EXISITING_PARTICIPANT_CONTRIBUTORS = `${ORIGINATION}/participant/contributor/get-participant-contributors-typeahead`;

export const FETCH_FARMS_FOR_PARTICIPANT = `${ORIGINATION}/participant/contributor/get-farm-for-contributor`;

export const FETCH_PARTICIPANT_CONTRIBUTORS_DATA = `${ORIGINATION}/participant/contributor/get-participant-contributor-list`;

export const FETCH_LAND_USE_TYPES = `${ORIGINATION}/participant/land-use-type`;

export const FETCH_INCENTIVES_OF_A_PARTICIPANT = `${ORIGINATION}/participant`;

export const FETCH_PROJECT_PARTICIPANT_INCENTIVES = `${ORIGINATION}/project`;

export const FETCH_PROJECT_PARTICIPANT_ACREAGE = `${ORIGINATION}/participant/graph`;

export const SEND_PARTICIPANT_ACTIVITY_EMAIL = `${ORIGINATION}/email/sendParticipantEnrollmentEmail`;

export const FETCH_REVIEW_PDF = `${ORIGINATION}/participant/enroll/get-participant-activity-info`;
export const FETCH_PROJECT_ACTIVITIES = (projectId) =>
  `${ORIGINATION}/project/${projectId}/project-activities`;

export const FETCH_ACTIVITY_ACREAGE = `${ORIGINATION}/field/field-area-details`;

export const SEND_PROFILE_PARTICIPANT_CONTRIBUTOR = `${ORIGINATION}/participant/contributor/profile-participant-contributor`;

export const FETCH_PROFILE_PARTICIPANT_CONTRIBUTOR = (
  participantId,
  participantOwnerId,
) =>
  `${ORIGINATION}/participant/contributor/profile-participant-contributor?participantId=${participantId}&participantOwnerId=${participantOwnerId}`;

export const FETCH_ADD_CONTRIBUTOR_FARM_AND_PROJECT = (participantOwnerId) =>
  `${ORIGINATION}/participant/contributor/get-farm-project-assignment?participantOwnerId=${participantOwnerId}`;

export const FETCH_ALL_CONTRIBUTORS = `${ORIGINATION}/participant/contributor/get-all-participant-contributors`;

export const REMOVE_PARTICIPANT_CONTRIBUTOR = `${ORIGINATION}/participant/contributor/remove-farm-access-from-contributor`;

export const POI_INIT_FILE_UPLOAD = `${ORIGINATION}/image/proof-of-implementation/upload`;

export const FETCH_POI_TAB_DATA = `${ORIGINATION}/proof-of-implementation`;

export const FETCH_POI_ACTIVITIES = `${ORIGINATION}/proof-of-implementation/activities-implemented`;

export const SUBMIT_POI_TAB_DATA = `${ORIGINATION}/proof-of-implementation`;

export const DOWNLOAD_POI_FILE = `${ORIGINATION}/proof-of-implementation/download/:poiFileId`;

export const FETCH_PROJECT_LIFE_CYCLE = `${ORIGINATION}/project-lifecycle`;

export const DELETE_POI_FILE = `${ORIGINATION}/proof-of-implementation`;

export const SEND_ACTIVITY_REOPEN_EMAIL = `${ORIGINATION}/email/reopen-activity/sendEmail`;

export const FETCH_PROJECT_PARTICIPANT_CONTRIBUTORS = `${ORIGINATION}/participant/contributor/list`;
export const GET_PROJECT_LIFE_CYCLE_LIST = `${ORIGINATION}/project-lifecycle/schedule`;

export const FETCH_PROJECT_CYCLES = `${ORIGINATION}/project-lifecycle/schedule`;

export const FETCH_PROJECT_LIFECYCLES = `${ORIGINATION}/project-lifecycle/schedule`;

export const FETCH_POI_REPORT_DATA = `${ORIGINATION}/proof-of-implementation/report`;

export const POI_FILTER_DROPDOWN = `${ORIGINATION}/proof-of-implementation/get-filter-dropdowns/`;

export const FETCH_RECRUITMENT_GOALS = `${ORIGINATION}/project/project-recruitment-goals-graph`;

export const GET_POI_ACCEPT_STATUS = `${ORIGINATION}/proof-of-implementation/getPoiAcceptStatus`;

export const FETCH_PROJECT_SUMMARY_REPORTING = (
  projectId,
  projectCycleId,
  acreage,
) =>
  `${ORIGINATION}/project/${projectId}/project-summary-reporting?projectCycleId=${projectCycleId}&acreage=${acreage}`;

export const ADD_PIPELINE_ACREAGE = `${ORIGINATION}/participant/addPipelineAcreage`;

export const FETCH_FILTER_ACCOUNT_STATUS = `${ORIGINATION}/participant/getAccountStatusFilter`;

export const FETCH_ACTIVATED_CONTRIBUTORS = `${ORIGINATION}/participant/contributor/active-account-status`;

export const FETCH_PIPELINE_ACREAGE = `${ORIGINATION}/participant/pipeline-acres`;

export const ACCEPT_POI_STATUS = `${ORIGINATION}/proof-of-implementation/accept`;

export const FLAG_POI_DATA = `${ORIGINATION}/proof-of-implementation/flag`;

export const ACCEPT_ALL_POI_DATA = `${ORIGINATION}/proof-of-implementation/accept-all`;

export const GET_CONTRACT_DETAILS = `${ORIGINATION}/participant/get-contract-details`;

export const ADD_FARM = `${ORIGINATION}/participant/{participantId}/enroll/farms-info`;

export const SESSION_LOGIN = `${ORIGINATION}/session/login`;
export const SESSION_LOGOUT = `${ORIGINATION}/session/logout`;
export const FETCH_UOM_LIST = `${ORIGINATION}/field/get-uom-details`;

export const SUPPORT_MODEL_API_INIT = `${ORIGINATION}/supportModel`;

export const GET_FAQ_PDF = `${SUPPORT_MODEL_API_INIT}/getFaqPdf`;
export const GET_USER_GUIDE_PDF = `${SUPPORT_MODEL_API_INIT}/getUserGuidePdf`;
export const GET_VIDEO_TUTORIALS = `${SUPPORT_MODEL_API_INIT}/getVideoTutorialByRole`;
export const GET_LATEST_ACTIVITY_STATUS = `${ORIGINATION}/participant/contributor/get-latest-activity-data`;

export const GET_EXCEPTION_REPORT = `${ORIGINATION}/participant/download/exception-report`;

export const UPDATE_FARM_STATUS = `${ORIGINATION}/participant/farm/lock-unlock-farm`;

export const CHECK_EXCEPTION_REPORT = `${ORIGINATION}/participant/check-exception-report`;

export const FETCH_USER_LOCK_DETAILS = `${ORIGINATION}/participant/farm/user-lock-details`;
