import {
  DARK_CHARCOAL,
  GRAYISH_BLUE,
  NEUTRAL_BACKGROUND_COLOR,
  NEW_DARK_GREEN,
  WHITE,
} from 'theme/GlobalColors';
import styled from 'styled-components';
import { checkboxClasses } from '@mui/material';
export const formControlLabelStyle = (
  minWidth,
  hasTooltipOnItem,
  applyTextTransform,
) => {
  return {
    color: DARK_CHARCOAL,
    minWidth: minWidth,
    labelPlacement: 'start',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
    ...(applyTextTransform && {
      '& .MuiFormControlLabel-label': {
        textTransform: 'lowercase',
        '&::first-letter': {
          textTransform: 'capitalize',
        },
      },
    }),
    ...(hasTooltipOnItem && {
      '&.MuiFormControlLabel-root': {
        marginRight: '0',
        alignItems: 'flex-start',
        padding: '0',
      },
      '& .MuiButtonBase-root': {
        padding: '1px 9px',
      },
    }),
  };
};
export const CheckBoxStyle = (
  checkBoxFillColor,
  checkboxPadding,
  backgroundColor,
) => {
  return {
    color: GRAYISH_BLUE,
    backgroundColor: backgroundColor || WHITE,
    padding: checkboxPadding,
    '&:hover': {
      color: DARK_CHARCOAL,
      backgroundColor: NEUTRAL_BACKGROUND_COLOR,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
    [`&.${checkboxClasses.checked}`]: {
      color: checkBoxFillColor || NEW_DARK_GREEN,
    },
  };
};
export const Container = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  gap: 0.25rem;
`;
export const ItemToolTipWrapper = styled.span`
  margin-left: 0.25rem;
`;

export const LabelTextStyle = {
  fontSize: '0.875rem',
};
export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: nowrap;
`;
export const DividerStyle = {
  width: '15rem',
  height: '1px',
  paddingTop: '0.5rem',
};
