import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
  DialogContentStyle,
  DialogTitleStyle,
  ModalDialogStyle,
  closeIconSx,
  ButtonWrapper,
} from './ImageModal.style';
import CloseIcon from '@mui/icons-material/Close';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { TextWrapper } from 'components/FormComponents/FormStyles';
import { WHITE } from 'theme/GlobalColors';
import { ReactComponent as AddIcon } from '../../../../../../assets/icons/add.svg';
import { ReactComponent as RemoveIcon } from '../../../../../../assets/icons/remove.svg';
import { PAGE_CONTENT } from './ImageModalComp.content';
import { checkTernaryCondition } from 'utils/helper';

const ImageModalComp = ({ src, isModalOpen = { flag: false }, onClose }) => {
  const modalCloseHandler = () => {
    onClose?.({ flag: false });
  };
  const [zoomLevel, setZoomLevel] = useState(0.7);

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) =>
      prevZoomLevel > 0.1 ? prevZoomLevel - 0.1 : prevZoomLevel,
    );
  };

  const findTextContent = (fieldLength, fieldCountForFarm, fieldName) => {
    if (fieldLength === fieldCountForFarm) {
      return PAGE_CONTENT.allFields;
    } else if (fieldLength > 1) {
      return `${fieldLength} ${PAGE_CONTENT.fields}`;
    } else {
      return fieldName;
    }
  };
  return (
    <Dialog
      sx={ModalDialogStyle}
      open={isModalOpen.flag}
      onClose={modalCloseHandler}
      data-testid="image-modal">
      <DialogTitle
        sx={{
          DialogTitleStyle,
          ...flexSpaceBetween,
        }}>
        <TextWrapper fontSize="1rem" fontWeight={600} color={WHITE}>
          {src?.title[0]} {'-'}{' '}
          {findTextContent(
            src?.fieldName.length,
            src?.fieldCountForFarm,
            src?.fieldName[0],
          )}
        </TextWrapper>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        <img
          data-testid="image-url"
          src={src?.imageURL}
          style={{
            transform: `scaleX(${zoomLevel}) scaleY(${zoomLevel})`,
          }}
        />
      </DialogContent>
      <ButtonWrapper data-testid="zoom">
        <button onClick={handleZoomIn}>
          <AddIcon />
        </button>
        <button onClick={handleZoomOut}>
          <RemoveIcon />
        </button>
      </ButtonWrapper>
    </Dialog>
  );
};
ImageModalComp.propTypes = {
  isModalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  fileThumbnail: PropTypes.string,
};
export default ImageModalComp;

ImageModalComp.propTypes = {
  src: PropTypes.shape({
    title: PropTypes.array,
    fieldName: PropTypes.array,
    fieldCountForFarm: PropTypes.number,
    imageURL: PropTypes.string,
  }),
  isModalOpen: PropTypes.object,
  onClose: PropTypes.func,
};
