import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { NotificationLogProvider } from 'containers/NotificationLogContext.react';
import { useStyles } from 'theme/GlobalStyles';
import Header from './header/Header.react';
import Footer from './footer/Footer.react';
import { pathName } from 'Routes/routes.const';
import axios from 'axios';
import { PARTICIPANT_PROFILE } from 'urls';
import { useSearchParams } from 'react-router-dom';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ContentWithFooter,
  participantLayoutContentPadding,
} from './Layout.style';
import { baselineAndLandingPages } from './Layout.constants';
import {
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  ORIGINATION_MANAGER,
  ADMIN,
} from 'utils/config';

const DisplayContent = ({ strechPage = false, children }) => {
  if (strechPage) {
    return <ContentWithFooter>{children}</ContentWithFooter>;
  }
  return children;
};

const ParticipantLayout = () => {
  const { MainContainer, MainContent } = useStyles();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const [isParticipantEnrolled, setIsParticipantEnrolled] = useState(false);
  const { userRoles, isParticipantPortalAccessDenied, isAccessDenied } =
    useContext(userAuthRolesContext);

  const isParticipantContributor = userRoles?.includes(PARTICIPANT_CONTRIBUTOR);
  const isParticipantOwner = userRoles?.includes(PARTICIPANT);
  const isAdmin = userRoles?.includes(ADMIN);
  const isOrginationManager = userRoles?.includes(ORIGINATION_MANAGER);
  const isLandingPage =
    location.pathname === pathName.participant.landingPage ||
    location.pathname === pathName.participant.baselineLandingPage ||
    location.pathname === pathName.participant.activityLandingPage ||
    location.pathname === pathName.participant.contributorLandingPage ||
    location.pathname === pathName.logout ||
    ((isParticipantContributor ||
      isParticipantOwner ||
      isAdmin ||
      isOrginationManager) &&
      (location.pathname === pathName.participant.activityMenuPage ||
        location.pathname === pathName.participant.baselineMenuPage ||
        location.pathname === pathName.participant.menuPage));

  const [isBaseLineComplete, setIsBaseLineComplete] = useState(false);

  useEffect(() => {
    // Waiting for activity reporting completion flow (Commented for now).
    // setIsParticipantLanding(
    //   ternaryBooleanHandler(
    //     location.pathname === pathName.participant.landingPage,
    //   ),
    // );
    // setIsBaselineLanding(
    //   location.pathname === pathName.participant.baselineLandingPage,
    // );
    if (projectId !== null) {
      fetchParticipantEnrollmentStatus();
    }
  }, [location]);

  const fetchParticipantEnrollmentStatus = () => {
    axios
      .get(PARTICIPANT_PROFILE, {
        params: { enrollmentType: 'BASELINE', projectId, participantId },
      })
      .then((response) => {
        setIsParticipantEnrolled(
          response.data.participantProjectStatus === 'Enrolled',
        );
        setIsBaseLineComplete(
          response.data.baselineEnrollmentStatus === 'Enrolled',
        );
      });
  };

  const insideBaselineOrLandingPages = baselineAndLandingPages.includes(
    location?.pathname,
  );

  const excludedPaths = [
    pathName.participant.participantDataCollection,
    pathName.participant.participantFieldActivity,
    pathName.participant.participantFieldBaseline,
  ];

  const contentWithFooter = (
    <>
      <MainContainer
        style={{ flex: 1 }}
        disableGutters
        maxWidth="xl"
        isParticipantLanding={isLandingPage}
        isParticipantEnrolled={isParticipantEnrolled && isBaseLineComplete}
        isParticipantPortalAccessDenied={isParticipantPortalAccessDenied}
        isAccessDenied={isAccessDenied}
        isMainAccessDeniedPage={isAccessDenied}>
        <MainContent
          marginLeft="0rem"
          padding={participantLayoutContentPadding}
          fullWidth>
          <Outlet />
        </MainContent>
      </MainContainer>
      {(!excludedPaths.includes(location?.pathname) || isAccessDenied) && (
        <Footer
          isMainAccessDeniedPage={isAccessDenied}
          isParticipantLanding={isLandingPage}
          isParticipantMenu={
            location.pathname === pathName.participant.menuPage ||
            location.pathname === pathName.participant.baselineMenuPage ||
            location.pathname === pathName.participant.activityMenuPage
          }
          isInActivityPage={
            location.pathname ===
              pathName.participant.participantFieldActivity ||
            location.pathname ===
              pathName.participant.participantDataCollection ||
            location.pathname === pathName.participant.participantFieldBaseline
          }
        />
      )}
    </>
  );

  return (
    <>
      <NotificationLogProvider>
        <Header isParticipantLanding={isLandingPage} />
      </NotificationLogProvider>

      <DisplayContent strechPage={insideBaselineOrLandingPages}>
        {contentWithFooter}
      </DisplayContent>
    </>
  );
};

export default ParticipantLayout;
