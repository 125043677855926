import { USER_EVENTS } from 'utils/config';

export const useUserInactivity = (
  idleTimeout,
  warningTimeout,
  expireAction,
  updateIdleModal = () => {
    /* do nothing here */
  },
) => {
  let idleLogout = idleTimeout + warningTimeout; //Ideltime + warning time (2 Minutes)
  let idleEvent = null;
  let idleLogoutEvent = null;

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const eventTimeout = () => {
    if (idleEvent) clearTimeout(idleEvent);
    if (idleLogoutEvent) clearTimeout(idleLogoutEvent);
    idleEvent = setTimeout(() => updateIdleModal, idleTimeout); //show session warning modal.
    idleLogoutEvent = setTimeout(() => expireAction(), idleLogout); //Call logged out on session expire.
  };

  function removeAllEventListeners() {
    for (let e in USER_EVENTS) {
      window.removeEventListener(USER_EVENTS[e], eventTimeout);
      clearTimeout(idleEvent);
      clearTimeout(idleLogoutEvent);
    }
  }

  function addAllEventListener() {
    for (let e in USER_EVENTS) {
      window.addEventListener(USER_EVENTS[e], eventTimeout);
    }
  }

  return { removeAllEventListeners, addAllEventListener };
};
