import { MenuItem } from '@mui/material';
import { menuItemSx } from '../CustomOptionsButton.style';
import { useActivityContext } from 'containers/ActivityListContainer';
import { activityReview } from 'pages/Origination/Org_Project/Participants/ActivityReview';
import { getActionIdFromActionCode } from 'pages/Origination/Org_Project/Participants/Participants.content';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import { ORIGINATION_VIEWER } from 'utils/config';
import { checkTernaryCondition, hasAnyRequiredAccess } from 'utils/helper';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT } from 'urls';
import { ViewPdfButton } from '../ActivityMenuButton/EnrollmentButtons.react';

export const InitiateActivityReportingButton = ({
  label,
  disabled = false,
}) => {
  const {
    setInitiateActivityReportingModalData,
    participantName,
    projectId,
    participantId,
    projectCycleId,
    activity,
    closeMenu,
  } = useActivityContext();

  return (
    <MenuItem
      sx={menuItemSx}
      key="initiateBaseline"
      disabled={disabled}
      onClick={() => {
        setInitiateActivityReportingModalData((prev) => ({
          ...prev,
          id: Number(participantId),
          actionId: activity.actionId,
          participantName,
          flag: true,
          enrollmentInstanceId: null,
          projectId,
          participantId,
          projectCycleId: projectCycleId,
        }));
        closeMenu();
      }}>
      {label}
    </MenuItem>
  );
};

InitiateActivityReportingButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const ReviewActivityMenuItem = () => {
  const {
    setReviewEnrollmentProps,
    participantId,
    projectId,
    activity,
    statusMappingList,
    closeMenu,
    projectCycleId,
    updateParticipantBaselineStatus,
  } = useActivityContext();
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);
  const { userRoles } = useContext(userAuthRolesContext);
  const isViewer = hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]);

  const handleClick = () => {
    //call API to set the status of the activity to 'REVIEW'
    updateParticipantBaselineStatus({
      formId: activity.formId,
      actionId: getActionIdFromActionCode('ACTIVITY-REVIEW', statusMappingList),
      enrolmentType: 'Activity',
    }).then(() => {
      setReviewEnrollmentProps({
        ...activityReview(participantId, projectId),
        modalData: { flag: true },
        formId: activity.formId,
        baselineActionId: getActionIdFromActionCode(
          'ACTIVITY-REVIEW',
          statusMappingList,
        ),
        projectCycleId: projectCycleId,
      });
      setAreUiActionInProgress((prev) => prev + 1);
      closeMenu();
    });
  };

  return checkTernaryCondition(
    isViewer,
    <ViewPdfButton url={DOWNLOAD_ENROLLMENT_ACTIVITY_REPORT} />,
    <MenuItem
      sx={menuItemSx}
      key="review-submitted-activity"
      onClick={handleClick}>
      Review
    </MenuItem>,
  );
};
