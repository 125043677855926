import { useContext, useEffect } from 'react';
import DetailedOperationsDataComp from '../DetailedOperationsDataComp/DetailedOperationsDataComp.react';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import {
  MainWrapperDiv,
  NavigationBtnWrapper,
} from '../TabBodyComponent.style';
import UserNavigationButtonsV2 from 'pages/ParticipantDataCollection/components/UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import Loader from 'components/Loader/Loader.react';
import Proptypes from 'prop-types';
import ImplementedActivitiesV2 from 'pages/ParticipantDataCollection/ActivityReporting/components/ImplementedActivitiesV2/ImplementedActivitiesV2.react';
import { useLocation, useNavigate } from 'react-router';
import { GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE } from '../DetailedOperationsDataComp/DetailedOperationsDataComp.content';
import { isEmpty } from 'utils/helper';
import { pathName } from 'Routes/routes.const';

const TabBodyComponentV2 = ({
  currentYearData,
  currentFarmId,
  currentFieldId,
  allExpanded,
  setFieldDetails,
  setEnrollmentYears,
  disableAllFieldActions = false,
  navigateHandler = () => null,
  refreshStatusHandler = () => null,
  gotoNextInprogressPage = () => null,
  setUserActivityDetected = () => null,
  shouldNavigateToLandingPage,
  fetchBaselineYears = () => {
    /* do nothing here */
  },
  updateReportingStatus = () => {
    /* do nothing here */
  },
  isParticipantContributor = false,
  isEditable = true,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    apiLoading,
    saveDataHandler,
    setSubmitData,
    setGlobalAllExpanded,
    setIsFieldDisabled,
    isFieldDisabled,
    updateImplementedActivities,
    fetchBaseLineFieldData,
    refreshCurrentFieldData,
  } = useContext(implementedActivitiesWithOpsDataContext);
  useEffect(() => {
    const isExpanded =
      allExpanded?.[currentYearData?.yearValue]?.[currentFieldId] ?? true;

    setGlobalAllExpanded((currentState) => {
      const key = `${currentFieldId}-${currentYearData?.yearValue}`;
      if (isEmpty(currentState[key])) {
        currentState[key] = GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE;
      }
      return {
        ...currentState,
        [key]: currentState[key].map((item) => ({
          ...item,
          value: isExpanded,
        })),
      };
    });
  }, [allExpanded, currentFieldId, currentYearData?.yearValue]);
  useEffect(() => {
    setSubmitData((prev) => ({
      ...prev,
      sampleId: currentYearData?.sampleId,
      currentYearProjectCycleId: currentYearData?.projectCycleId,
      currentYear: currentYearData?.yearValue,
      phase: currentYearData?.phase,
    }));
  }, [currentYearData]);

  useEffect(() => {
    setIsFieldDisabled(
      currentYearData?.phase === 'baseline' &&
        pathname?.includes('participant-field-activity'),
    );
  }, [currentYearData?.phase, pathname]);

  const makeFormDirty = () => {
    setUserActivityDetected(true);
  };

  const handleSaveBtnClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    saveDataHandler()
      .then(() => {
        setUserActivityDetected(false);
        updateImplementedActivities();
        refreshStatusHandler();
      })
      .catch(() => {
        //do nothing here
      })
      .finally(() => {
        fetchBaseLineFieldData(currentYearData?.yearValue);
      });
  };

  const handleContinueClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    saveDataHandler()
      .then(() => {
        setUserActivityDetected(false);
        gotoNextInprogressPage();
      })
      .catch(() => {
        //do nothing here
      });
  };

  return (
    <MainWrapperDiv>
      <Loader loading={apiLoading} zIndex={999999} />
      <ImplementedActivitiesV2
        currentFieldId={currentFieldId}
        currentFarmId={currentFarmId}
        disableAllFieldActions={disableAllFieldActions}
        currentYearData={currentYearData}
        refreshStatusHandler={refreshStatusHandler}
        makeFormDirty={makeFormDirty}
        setUserActivityDetected={setUserActivityDetected}
        shouldNavigateToLandingPage={shouldNavigateToLandingPage}
        isEditable={isEditable}
      />
      <DetailedOperationsDataComp
        year={currentYearData?.yearValue}
        sampleId={currentYearData?.sampleId}
        setFieldDetails={setFieldDetails}
        setEnrollmentYears={setEnrollmentYears}
        disableAllFieldActions={disableAllFieldActions}
        currentFarmId={currentFarmId}
        currentFieldId={currentFieldId}
        makeFormDirty={makeFormDirty}
        isEditable={isEditable}
      />
      <NavigationBtnWrapper marginTop={'0.5rem'}>
        {!isFieldDisabled && !disableAllFieldActions && (
          <UserNavigationButtonsV2
            disabledCancel={!isEditable}
            disabledSave={!isEditable}
            disabledContinue={!isEditable}
            handleCancel={() => {
              navigateHandler();
              /* istanbul ignore else */
              if (isParticipantContributor) {
                fetchBaselineYears();
                refreshCurrentFieldData();
                updateReportingStatus('field', currentFieldId);
              }
            }}
            handleSave={handleSaveBtnClick}
            handleContinue={handleContinueClick}
          />
        )}
      </NavigationBtnWrapper>
    </MainWrapperDiv>
  );
};

TabBodyComponentV2.propTypes = {
  currentYearData: Proptypes.object,
  currentFarmId: Proptypes.number,
  currentFieldId: Proptypes.number,
  allExpanded: Proptypes.array,
  refreshStatusHandler: Proptypes.func,
  gotoNextInprogressPage: Proptypes.func,
  setUserActivityDetected: Proptypes.func,
  setFieldDetails: Proptypes.func,
  setEnrollmentYears: Proptypes.func,
  navigateHandler: Proptypes.func,
  disableAllFieldActions: Proptypes.bool,
  shouldNavigateToLandingPage: Proptypes.func,
  fetchBaselineYears: Proptypes.func,
  updateReportingStatus: Proptypes.func,
  isParticipantContributor: Proptypes.bool,
  isEditable: Proptypes.bool,
};

export default TabBodyComponentV2;
