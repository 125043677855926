import PropTypes from 'prop-types';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogStyle,
  DialogTitleStyle,
  FieldSectionWrapper,
  SubmitBtnStyle,
  ModalDialogStyle,
  closeIconSx,
} from '../Modals.style';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  addZeroBeforeDot,
  checkTernaryCondition,
  findObjectWithKey,
} from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  formFieldNames,
  fossilFuelTypeFormInitialData,
  MODAL_CONTENT,
  unitOfMeasurementTypes,
} from './FossilFuelTypeModal.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import { useForm } from 'hooks/useForm';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useEffect, useState, useContext } from 'react';
import {
  FocusedInputSx,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { FUEL_CONSUMPTION } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const FossilFuelTypeModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler = () => {},
  tabWiseSubmitHandler = () => {},
  existingFuelTypeIds = [],
}) => {
  const {
    unitOfMeasureList: { tractorVehicleFuel },
  } = useContext(uomContext);
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    fossilFuelTypeFormInitialData,
  );
  const { fossilFuelTypeDropdown } = useContext(baselineDropdownContext);
  const [isEdit, setIsEdit] = useState(false);
  const [newState, setNewState] = useState('');
  const [errorState, setErrorState] = useState({
    fossilFuelType: false,
    consumption: false,
    unitOfMeasure: false,
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [editModeFossilFuelTypes, setEditModeFossilFuelTypes] = useState([]);
  const [fuelUomList, setFuelUomList] = useState([]);
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(fossilFuelTypeFormInitialData);
    setIsEdit(false);
    setEditModeFossilFuelTypes([]);
    setErrorState({
      fossilFuelType: false,
      consumption: false,
      unitOfMeasure: false,
    });
  };
  const setModalErrorState = (value, fieldName) => {
    let newErrorState = { ...errorState };
    newErrorState[fieldName] = value === '';
    setErrorState(newErrorState);
  };

  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data?.formData.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      if (modalData) {
        setIsEdit(true);
      }
      const fuelTypes = modalOpen.data?.formData?.map(
        (fossilFuel) => fossilFuel?.fossilFuelType,
      );
      const dataUOM = unitOfMeasurementTypes(
        findObjectWithKey(tractorVehicleFuel, FUEL_CONSUMPTION)
          ?.FUEL_CONSUMPTION,
      ).find((item) => item.value === +modalData?.unitOfMeasure)?.itemLabel;

      setEditModeFossilFuelTypes(fuelTypes);
      setFormValue(
        checkTernaryCondition(
          modalData,
          modalData,
          fossilFuelTypeFormInitialData,
        ),
      );
      setNewState(dataUOM);
    }
  }, [modalOpen]);
  useEffect(() => {
    setFuelUomList(
      unitOfMeasurementTypes(
        findObjectWithKey(tractorVehicleFuel, FUEL_CONSUMPTION)
          ?.FUEL_CONSUMPTION,
      ),
    );
  }, [tractorVehicleFuel]);

  const fossilFuelTypeDropdownProps = {
    label: MODAL_CONTENT.fossil_fuel_type_label,
    value: checkTernaryCondition(
      formValue.fossilFuelType === '',
      MODAL_CONTENT.fossil_fuel_type_placeholder,
      formValue.fossilFuelType,
    ),
    isDisabled: false,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.fossilFuelType,
    ariaLabel: MODAL_CONTENT.fossil_fuel_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.fossil_fuel_type_placeholder,
    error: errorState.fossilFuelType,
    dropdownlist: fossilFuelTypeDropdown
      ?.filter(
        (fossilFuel) =>
          !existingFuelTypeIds.includes(fossilFuel.value) ||
          editModeFossilFuelTypes.includes(fossilFuel.value),
      )
      .sort((a, b) => a.label.localeCompare(b.label)),
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.fossilFuelType,
    setStateValue: () => {},
    showLabelAsValue: true,
    setModalErrorState: setModalErrorState,
    fieldName: 'fossilFuelType',
    hasNoBottomMargin: true,
    labelMarginTop: '0.5rem',
    labelMarginBottom: '0.25rem',
  };

  const submitBtnHandler = () => {
    const error = {
      fossilFuelType: formValue.fossilFuelType === '',
      consumption: formValue.consumption === '',
      unitOfMeasure: formValue.unitOfMeasure === '',
    };
    setErrorState(error);
    if (!Object.values(error).includes(true)) {
      submitHandler(
        modalOpen.id,
        {
          id: checkTernaryCondition(isEdit, formValue.id, uniqueId()),
          ...formValue,
        },
        isEdit,
        modalOpen.currentRowId,
      );
      tabWiseSubmitHandler(
        formValue,
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
      );
      modalCloseHandler();
    }
  };

  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);
  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={{ ...DialogStyle, ...ModalDialogStyle }}>
      <DialogTitle
        sx={{
          ...DialogTitleStyle,
          ...flexSpaceBetween,
        }}>
        <div>
          {modalOpen.data?.year} {MODAL_CONTENT.heading}
        </div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={{ ...DialogContentStyle, marginBottom: '0.5rem' }}>
        <FieldSectionWrapper marginBottom="0.5rem">
          <DropDown {...fossilFuelTypeDropdownProps} />
        </FieldSectionWrapper>
        <FieldSectionWrapper columnGap="4rem" marginBottom="0rem">
          <InputField
            width="8.75rem"
            required
            category="long-numbers-field"
            customInputSx={FocusedInputSx}
            label={MODAL_CONTENT.consumption_label}
            name={formFieldNames.consumption}
            value={formValue.consumption}
            customStyles={{
              marginBottom: '0rem',
              marginTop: '-1rem',
            }}
            rightSideLabelFormLabelMargin="0.5rem"
            labelMarginBottom="0.25rem"
            onUpdate={(e) => {
              const value = e.target.value;
              if (value.match(/^\d*(\.\d{0,2})?$/) || value === '') {
                customFieldChangeHandler(
                  formFieldNames.consumption,
                  addZeroBeforeDot(e.target.value),
                );
              }
              setErrorState({ ...errorState, consumption: value === '' });
            }}
            placeholder={MODAL_CONTENT.consumption_placeholder}
            primaryError={errorState.consumption}
            primaryErrorMessage={''}
            minWidth="8.75rem"
          />
          <RadioButtonsGroup
            list={fuelUomList}
            label={MODAL_CONTENT.unit_of_measure_label}
            direction={MODAL_CONTENT.row}
            selectedValue={newState}
            primaryError={errorState.unitOfMeasure}
            checkedIsDarkGreen
            primaryErrorMessage={''}
            handleChange={(e) => {
              const uomObj = fuelUomList.find(
                (item) => item.itemLabel === e.target.value,
              );
              customFieldChangeHandler(
                formFieldNames.unitOfMeasure,
                uomObj?.value,
              );
              setNewState(uomObj?.itemLabel);
              setErrorState({
                ...errorState,
                unitOfMeasure: e.target.value === '',
              });
            }}
            marginBottom="0rem"
            marginTop="0rem"
          />
        </FieldSectionWrapper>
      </DialogContent>
      <DialogActions style={DialogActionStyle}>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={submitBtnHandler}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FossilFuelTypeModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  tabWiseSubmitHandler: PropTypes.func,
  existingFuelTypeIds: PropTypes.array,
};
export default FossilFuelTypeModal;
