import styled from 'styled-components';

export const ErrorContainerWrapper = styled.div`
  margin: auto;
  margin-top: 30vh;
  width: 80vw;
  padding-bottom: 88px;
`;

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
};

const commonFontStyles = {
  fontFamily: 'Open Sans',
};

export const headerSx = {
  ...flexCenter,
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
  marginBottom: 4,
  color: 'rgba(167, 168, 170, 1)',
  fontSize: 34,
  fontWeight: 700,
  marginTop: '30px',
};

export const subHeadingSx = {
  ...flexCenter,
  ...commonFontStyles,
  fontSize: '20px',
  fontWeight: 700,
  color: 'rgba(51, 51, 51, 1)',
};

export const paragraphSx = {
  ...flexCenter,
  ...commonFontStyles,
  fontSize: '14px',
  fontWeight: 400,
};

export const buttonSx = {
  ...flexCenter,
  ...commonFontStyles,
  marginBottom: '88px',
  marginTop: '33px',
};
