import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import styled from 'styled-components';
import {
  GRAY,
  WHITE,
  COOL_GRAY,
  QUILL_GRAY,
  GREEN,
  DARK_GREEN,
  GLOBAL_BLUE,
  NEW_DARK_GREEN,
  NEW_HOVER_DARK_GREEN,
  TEAL_GREEN_FOCUS,
  BLUE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-row-gap: 0.5rem;
  height: 100%;
`;

export const ProjectNameWrapper = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #313233;
  display: flex;
  grid-column-gap: 0.5rem;
`;

export const MainSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;
export const columnFlexStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};
export const ParticipantInfoWrapper = styled.div`
  width: 100%;
  background-color: ${WHITE};
  display: flex;
  min-height: calc(100vh - 125px);
`;
export const leftSectionStyle = {
  minHeight: 'calc(100vh - 7.5rem)',
};

export const MenuWrapper = styled.div`
  width: 19.5rem;
  min-width: 19.5rem;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  padding: 0rem 2rem;
  gap: 6px;
`;

export const SectionWrapper = styled.div`
  height: 100%;
  border-left: 1px solid ${GRAY};
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 3px;
  }
`;

export const SectionFooter = styled.div`
  width: 92%;
  padding: 0.125rem 1rem 1.125rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CustomInfoWindow = styled.div`
  width: 10rem;
  position: relative;
  font-size: 0.875rem;
  padding: 0.1rem;
`;

export const InfoWindowContent = styled.div`
  margin-bottom: 0.5rem;
`;

export const AcceptanceBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AcceptBtn = styled.span`
  color: ${({ disabled }) =>
    checkTernaryCondition(disabled, COOL_GRAY, NEW_DARK_GREEN)};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  &:hover {
    color: ${NEW_HOVER_DARK_GREEN};
  }
  &:focus {
    color: ${TEAL_GREEN_FOCUS};
  }
`;

export const DeleteBtn = styled.span`
  color: ${(props) => props.color ?? COOL_GRAY};
  cursor: pointer;
  margin-left: auto;
`;

export const ProgressBar = styled(LinearProgress)(() => ({
  height: 5,
  backgroundColor: '#06D6A0',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: WHITE,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#06D6A0',
  },
}));

export const saveAndCloseButtonStyle = {
  textTransform: 'none',
  border: '1px solid #26890D',
  background: 'white',
  color: '#26890D',
  marginRight: '0.4rem',
};

export const cancelButtonStyle = {
  textTransform: 'none',
  backgroundColor: 'transparent',
  color: GREEN,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
};

export const buttonStyle = (disabled) => {
  return {
    textTransform: 'none',
    background: disabled ? QUILL_GRAY : DARK_GREEN,
    color: disabled ? COOL_GRAY : 'white',
    '&:hover': {
      background: disabled ? QUILL_GRAY : DARK_GREEN,
      color: disabled ? QUILL_GRAY : WHITE,
    },
  };
};

export const confirmationModalSx = {
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
};

export const BreadcrumbItem = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  color: ${({ lastItem }) =>
    checkTernaryCondition(lastItem, COOL_GRAY, GLOBAL_BLUE)};
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  width: fit-content;
  margin-left: ${({ margin }) => margin || ''};
`;

export const mapStyle = { height: '69vh', width: '100%' };
export const backdropSx = { color: '#fff', zIndex: 1001 };
export const dialogSx = { zIndex: 1000 };
export const NavigateIconStyle = { color: COOL_GRAY };
export const fieldMapViewStyle = { width: '100%', height: '76vh' };
export const backButtonStyle = {
  padding: 0,
  color: BLUE,
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 400,
  minWidth: 'unset',
};
