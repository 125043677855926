import { Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  buttonSx,
  ErrorContainerWrapper,
  headerSx,
  paragraphSx,
  subHeadingSx,
} from './errorPage.style';
import { ReactComponent as BackArrow } from 'assets/icons/Round.svg';
import {
  BackButtonLabel,
  BackButtonWrapper,
} from 'components/Backbutton/BackButton.style';
import { useNavigate, useParams } from 'react-router';
import {
  PAGE_NOT_FOUND,
  UNAUTHENTICATED_ACCESS,
  UNAUTHORIZED_ACCESS,
} from './errorPage.content';
import { ACCESS_DENIED_PAGE_CONTENT } from 'pages/AccessDeniedPage/AccessDeniedPage.content';

export const ErrorBanner = () => {
  const navigate = useNavigate();
  const { errorCode } = useParams();
  const [pageContents, setPageContents] = useState();
  useEffect(() => {
    switch (errorCode) {
      case '401':
        setPageContents(UNAUTHENTICATED_ACCESS);
        break;
      case '402':
        setPageContents(UNAUTHORIZED_ACCESS);
        break;
      default:
        setPageContents(PAGE_NOT_FOUND);
    }
  }, []);

  return (
    <ErrorContainerWrapper>
      <Card sx={{ margin: 'auto' }}>
        <CardContent>
          <Typography gutterBottom sx={headerSx}>
            {pageContents?.title}
          </Typography>
          <Typography gutterBottom sx={subHeadingSx}>
            {pageContents?.subHeading}
          </Typography>
          <Typography gutterBottom sx={paragraphSx}>
            {pageContents?.text}
          </Typography>
          <Typography gutterBottom sx={buttonSx}>
            <BackButtonWrapper
              data-testid="back-arrow"
              onClick={() => {
                navigate(-1);
              }}>
              <BackArrow />
              <BackButtonLabel>
                {ACCESS_DENIED_PAGE_CONTENT.back_button_label}
              </BackButtonLabel>
            </BackButtonWrapper>
          </Typography>
        </CardContent>
      </Card>
    </ErrorContainerWrapper>
  );
};
