import {
  checkTernaryCondition,
  descendingSortByKey,
  rotateData,
} from 'utils/helper';
import { TabsStyle } from './BaseLineReportingMainPage.style';
import { ReactComponent as YellowStatus } from '../../../assets/icons/yellowStatus.svg';
import { ReactComponent as GreenStatus } from '../../../assets/icons/greenStatus.svg';
import { ReactComponent as NotStartedStatus } from '../../../assets/icons/notStartedIcon.svg';
import { RED } from 'theme/GlobalColors';
import { NAVIGATION_PROPS } from '../ParticipantDataCollection.content';

export const PAGE_CONTENT = {
  cash_crop: 'Cash crop',
  completed: 'Completed',
  success: 'success',
  success_msg: 'Form details saved successfully.',
  error: 'error',
  error_msg:
    'Form details failed to save due to technical error. Please try again.',
  in_progress: 'In Progress',
  save_continue: 'Save and continue',
  save_close: 'Save and close',
  cancel: 'Cancel',
  baseline_field_heading:
    'Please provide details on crop production / management practices for each year of the operations data.',
  baseline_farm_heading:
    ' Please provide details on tractor / vehicle fuel consumption for each year of the operations data.',
  baseline_star_end_tooltip:
    'Baseline period determined based on planned project activity implementation dates and must be a minimum of 3 years.',
  delete_modal_first_message: 'Are you sure you want to delete',
  delete_modal_second_message: 'Any data entered will be lost.',
  EP: 'EP',
  EN: 'EN',
  edge_of_field: '+ Edge of field',
  edge_of_field_label: 'Edge of field',
  submit_complete_modal_title: 'All field baseline reporting is complete',
  submit_complete_modal_content:
    'You’re ready to review and submit or you may continue to edit fields that are complete.',
  continue_btn_label: 'Continue to edit farms / fields',
  review_btn_label: 'Review and submit',
  cancel_btn_label: 'Cancel',
  baseline_heading: '- Operations data',
  left_section_header: 'Farms',
  farm: 'Farm',
  crop: 'Crop',
  empty_farm_label: 'Tractors / vehicles fuel (0)',
  empty_crop_label: 'Crop (0)',
  copyButtonLabelTractors: 'Copy tractor or vehicle fuel',
  copy_button_label_crop: 'Copy saved field crop',
  complete: 'Complete',
  add_cash_crop_button: 'Crop',
  tractor_and_vehicle: 'Tractor / vehicle fuel',
  cash_crop_label: 'Crop',
  tractor_and_vehicle_add_btn: '+ Tractors / vehicles fuel',
  cash_crop_add_btn: '+ Optional practice',
  delete_heading_first_part: 'Delete',
  delete_content_firt_part: 'Are you sure you want to delete',
  delete_content_second_part: '? Any data entered will be lost.',
  acre: 'acre',
  bushels_acre: 'bushels',
  practices_count: 'practices',
  field: 'field',
  baseline_reporting: 'Baseline reporting',
  baseline_reporting_for_project: 'Baseline Reporting',
  home: 'Home',
  fieldCropCopyModalTitle: 'Copy field crop',
  tractorOrVehicleCopyModalTitle: 'Copy tractors / vehicles',
  copy: 'Copy',
  replaceModalContentPrefix: 'Are you sure you want to copy ',
  replaceModalContentSuffix: '? All data entered will be replaced.',
  farmCopyDisclaimer:
    'Please review the copied tractors / vehicles fuel usage data as the copied values may require updating due to farm area variances.',
  cashCropCopyDisclaimer:
    'Please review the copied field data as the copied values may require updating due to field area variances.',
  unsavedChangesPromptMsg:
    "You've made updates that haven't been saved yet. Would you like to save before closing?",
  addTractorVehicleBtn: 'Add tractor or vehicle fuel',
  baselineReportingType: 'Baseline',
  back: 'Back',
  PARTICIPANT_OWNER: 'PARTICIPANT_OWNER',
  PARTICIPANT_CONTRIBUTOR: 'PARTICIPANT_CONTRIBUTOR',
  expandAllButton: 'Expand all',
  PARTICIPANT: 'PARTICIPANT',
  collapseButton: 'Collapse all',
  FORM_LOCKED: 'Form locked',
  FORM_UNLOCKED: 'Form unlocked',
};
export const tabsTitle = (year, status) => {
  return (
    <TabsStyle>
      {year}
      {getTabYearStatus(status)}
    </TabsStyle>
  );
};

export const getTabYearStatus = (status) => {
  switch (status) {
    case 'In Progress':
      return <YellowStatus />;
    case 'Completed':
      return <GreenStatus />;
    default:
      return <NotStartedStatus />;
  }
};

const constructPages = (farmList) => {
  const pages = [];
  for (const farm of farmList) {
    const farmYears = descendingSortByKey(farm.farmYearStatus, 'dateId');

    for (let index = 0; index < farmYears.length; index++) {
      pages.push({
        type: NAVIGATION_PROPS.FARM_TYPE,
        id: farm.farmId,
        name: farm.farmName,
        yearIndex: index,
        status: checkTernaryCondition(
          farmYears[index].formStatus === PAGE_CONTENT.completed,
          NAVIGATION_PROPS.STATUS.COMPLETED,
          NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
        ),
      });
    }

    for (const field of farm.fieldDetails) {
      const fieldYears = descendingSortByKey(field.fieldYearStatus, 'dateId');

      for (let index = 0; index < fieldYears.length; index++) {
        pages.push({
          type: NAVIGATION_PROPS.FIELD_TYPE,
          id: field.fieldId,
          name: field.fieldName,
          farmId: farm.farmId,
          farmName: farm.farmName,
          yearIndex: index,
          status: checkTernaryCondition(
            fieldYears[index].formStatus === PAGE_CONTENT.completed,
            NAVIGATION_PROPS.STATUS.COMPLETED,
            NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
          ),
        });
      }
    }
  }

  return pages;
};

const getCurrentPageIndex = (
  pages,
  currentFarmData,
  currentFieldId,
  isFarmWebform,
  currentYearIndex,
) => {
  if (isFarmWebform) {
    return pages.findIndex(
      (page) =>
        page.type === NAVIGATION_PROPS.FARM_TYPE &&
        page.id === currentFarmData.farmId &&
        page.yearIndex === currentYearIndex,
    );
  }

  return pages.findIndex(
    (page) =>
      page.type === NAVIGATION_PROPS.FIELD_TYPE &&
      page.id === currentFieldId.id &&
      page.yearIndex === currentYearIndex,
  );
};

export const handleNextInProgrssSwitcher = ({
  updatedFarmInfo,
  currentFarmData,
  updatedFieldId,
  isFarmWebform,
  currentYearIndex,
}) => {
  const pages = constructPages(updatedFarmInfo);
  const currentPageIndex = getCurrentPageIndex(
    pages,
    currentFarmData,
    updatedFieldId,
    isFarmWebform,
    currentYearIndex,
  );

  const modifiedPages = rotateData(pages, currentPageIndex);
  const nextInProgressPage = modifiedPages.find((page, index) => {
    return index > 0 && page.status === NAVIGATION_PROPS.STATUS.NOT_COMPLETED;
  });

  return nextInProgressPage ?? null;
};

export const datesLabelList = [
  'Date harvested',
  'Date planted',
  'Dates applied',
  'Tillage date(s)',
  'Date terminated',
];

export const dateFieldNames = [
  {
    name: 'datesApplied',
    type: 'list',
  },
  {
    name: 'cropHarvestDate',
    type: 'string',
  },
  {
    name: 'cropPlantDate',
    type: 'string',
  },
  {
    name: 'tillageDates',
    type: 'list',
  },
  {
    name: 'cropTerminateDate',
    type: 'string',
  },
];
const setValForEmptyValues = (val) => {
  if (val === null || val === undefined || val === '') {
    return '--';
  } else {
    return val;
  }
};
export const checkEmptyDates = (
  label,
  value,
  currentLabel,
  labelList,
  fieldStyle,
  isRenderForValue,
) => {
  const isDateEmpty =
    value === '' &&
    labelList.includes(currentLabel) &&
    !label.includes('Fallow');

  if (isRenderForValue) {
    return checkTernaryCondition(
      isDateEmpty,
      `${currentLabel} required.`,
      setValForEmptyValues(value),
    );
  } else {
    return checkTernaryCondition(
      isDateEmpty,
      { ...fieldStyle, color: RED },
      fieldStyle,
    );
  }
};

export const baselineInstanceStatus = {
  approved: 'APPROVED',
  notApproved: 'NOT_APPROVED',
};
export const tractorVehicleFuel = [
  {
    FUEL_CONSUMPTION: [
      {
        dataElementId: 1,
        dataElementName: 'Fuel consumption',
        uomId: 1,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'L',
        uomNameFull: 'liter',
        uomNameDisplay: 'L',
        uomType: 'volume',
        uiModal: 'Tractors/vehicles fuel',
      },
      {
        dataElementId: 1,
        dataElementName: 'Fuel consumption',
        uomId: 3,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'gal',
        uomNameFull: 'gallon',
        uomNameDisplay: 'gal',
        uomType: 'volume',
        uiModal: 'Tractors/vehicles fuel',
      },
      {
        dataElementId: 1,
        dataElementName: 'Fuel consumption',
        uomId: 2,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ft^3',
        uomNameFull: 'cubic foot',
        uomNameDisplay: 'ft³',
        uomType: 'volume',
        uiModal: 'Tractors/vehicles fuel',
      },
    ],
  },
];
export const cropAndLandUse = [
  {
    ANNUAL_YIELD: [
      {
        dataElementId: 2,
        dataElementName: 'Annual yield',
        uomId: 18,
        numeratorUomId: 4,
        denominatorUomId: 16,
        uomName: 'bu/ac',
        uomNameFull: 'bushels per acre',
        uomNameDisplay: 'bu/ac',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 2,
        dataElementName: 'Annual yield',
        uomId: 19,
        numeratorUomId: 5,
        denominatorUomId: 16,
        uomName: 'lb/ac',
        uomNameFull: 'pounds per acre',
        uomNameDisplay: 'lb/ac',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 2,
        dataElementName: 'Annual yield',
        uomId: 20,
        numeratorUomId: 7,
        denominatorUomId: 16,
        uomName: 'ton/ac',
        uomNameFull: 'tons per acre',
        uomNameDisplay: 'ton/ac',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 2,
        dataElementName: 'Annual yield',
        uomId: 12,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: '% harvest efficiency',
        uomNameFull: '% harvest efficiency',
        uomNameDisplay: '% harvest efficiency',
        uomType: 'percent',
        uiModal: 'Crops & landuse',
      },
    ],
  },
  {
    SOIL_AMENDMENT_AMOUNT_APPLIED: [
      {
        dataElementId: 6,
        dataElementName: 'Soil amendment amount applied',
        uomId: 6,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'kg',
        uomNameFull: 'kilogram',
        uomNameDisplay: 'kg',
        uomType: 'mass',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 6,
        dataElementName: 'Soil amendment amount applied',
        uomId: 5,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'lb',
        uomNameFull: 'pound',
        uomNameDisplay: 'lb',
        uomType: 'mass',
        uiModal: 'Crops & landuse',
      },
    ],
  },
  {
    SEEDING_DEPTH: [
      {
        dataElementId: 4,
        dataElementName: 'Seeding depth',
        uomId: 8,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'in',
        uomNameFull: 'inch',
        uomNameDisplay: 'in',
        uomType: 'length',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 4,
        dataElementName: 'Seeding depth',
        uomId: 9,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'cm',
        uomNameFull: 'centimeter',
        uomNameDisplay: 'cm',
        uomType: 'length',
        uiModal: 'Crops & landuse',
      },
    ],
  },
  {
    PLANTING_DENSITY: [
      {
        dataElementId: 3,
        dataElementName: 'Planting density',
        uomId: 22,
        numeratorUomId: 13,
        denominatorUomId: 16,
        uomName: 'seeds/ac',
        uomNameFull: 'seeds per acre',
        uomNameDisplay: 'seeds/ac',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 3,
        dataElementName: 'Planting density',
        uomId: 19,
        numeratorUomId: 5,
        denominatorUomId: 16,
        uomName: 'lb/ac',
        uomNameFull: 'pounds per acre',
        uomNameDisplay: 'lb/ac',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 3,
        dataElementName: 'Planting density',
        uomId: 27,
        numeratorUomId: 6,
        denominatorUomId: 26,
        uomName: 'kg/ha',
        uomNameFull: 'kilograms per hectare',
        uomNameDisplay: 'kg/ha',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 3,
        dataElementName: 'Planting density',
        uomId: 28,
        numeratorUomId: 13,
        denominatorUomId: 15,
        uomName: 'seeds/m^2',
        uomNameFull: 'seeds per square meter',
        uomNameDisplay: 'seeds/m²',
        uomType: 'ratio',
        uiModal: 'Crops & landuse',
      },
    ],
  },
  {
    CROP_AREA: [
      {
        dataElementId: 22,
        dataElementName: 'Crop area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Crops & landuse',
      },
    ],
  },
  {
    IRRIGATION_VOLUME: [
      {
        dataElementId: 5,
        dataElementName: 'Irrigation volume',
        uomId: 1,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'L',
        uomNameFull: 'liter',
        uomNameDisplay: 'L',
        uomType: 'volume',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 5,
        dataElementName: 'Irrigation volume',
        uomId: 2,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ft^3',
        uomNameFull: 'cubic foot',
        uomNameDisplay: 'ft³',
        uomType: 'volume',
        uiModal: 'Crops & landuse',
      },
      {
        dataElementId: 5,
        dataElementName: 'Irrigation volume',
        uomId: 3,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'gal',
        uomNameFull: 'gallon',
        uomNameDisplay: 'gal',
        uomType: 'volume',
        uiModal: 'Crops & landuse',
      },
    ],
  },
];
export const fertilizer = [
  {
    FERTILIZER_APPLICATION_RATE: [
      {
        dataElementId: 8,
        dataElementName: 'Fertilizer application rate',
        uomId: 19,
        numeratorUomId: 5,
        denominatorUomId: 16,
        uomName: 'lb/ac',
        uomNameFull: 'pounds per acre',
        uomNameDisplay: 'lb/ac',
        uomType: 'ratio',
        uiModal: 'Fertilizer',
      },
      {
        dataElementId: 8,
        dataElementName: 'Fertilizer application rate',
        uomId: 21,
        numeratorUomId: 3,
        denominatorUomId: 16,
        uomName: 'gal/ac',
        uomNameFull: 'gallons per acre',
        uomNameDisplay: 'gal/ac',
        uomType: 'ratio',
        uiModal: 'Fertilizer',
      },
    ],
  },
];
export const tillage = [
  {
    DEPTH_OF_TILLAGE: [
      {
        dataElementId: 7,
        dataElementName: 'Depth of tillage',
        uomId: 8,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'in',
        uomNameFull: 'inch',
        uomNameDisplay: 'in',
        uomType: 'length',
        uiModal: 'Tillage',
      },
    ],
  },
];
export const implementedActivities = [
  {
    ACTUAL_TOTAL_ACTIVITY_AREA: [
      {
        dataElementId: 19,
        dataElementName: 'Actual total activity area',
        uomId: 16,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ac',
        uomNameFull: 'acre',
        uomNameDisplay: 'ac',
        uomType: 'area',
        uiModal: 'Implemented activity summary',
      },
    ],
  },
  {
    STRIP_WIDTH: [
      {
        dataElementId: 9,
        dataElementName: 'Strip width',
        uomId: 11,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ft',
        uomNameFull: 'foot',
        uomNameDisplay: 'ft',
        uomType: 'length',
        uiModal: 'Implemented activity summary - edge of field',
      },
    ],
  },
  {
    BARRIER_WIDTH: [
      {
        dataElementId: 11,
        dataElementName: 'Barrier width',
        uomId: 11,
        numeratorUomId: null,
        denominatorUomId: null,
        uomName: 'ft',
        uomNameFull: 'foot',
        uomNameDisplay: 'ft',
        uomType: 'length',
        uiModal: 'Implemented activity summary - edge of field',
      },
    ],
  },
  {
    SPECIES_DENSITY: [
      {
        dataElementId: 10,
        dataElementName: 'Species density',
        uomId: 24,
        numeratorUomId: 14,
        denominatorUomId: 16,
        uomName: 'trees planted/ac',
        uomNameFull: 'trees planted per acre',
        uomNameDisplay: 'trees planted / ac',
        uomType: 'ratio',
        uiModal: 'Implemented activity summary - edge of field',
      },
    ],
  },
];

export const FIELD_DATA_INIT = {
  id: 0,
  name: '',
};
