import { DataGrid } from '@mui/x-data-grid';
import { GridWrapper, datagridSx } from './ProjectCycleTable.style';
import PropTypes from 'prop-types';

const ProjectCycleTable = ({ rowData }) => {
  const columns = [
    {
      field: 'cycleYear',
      headerName: 'Cycle Year',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      disableColumnMenu: true,
    },
  ];
  return (
    <GridWrapper>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        sx={datagridSx}
        hideFooter={true}
        rows={rowData}
        columns={columns}
      />
    </GridWrapper>
  );
};

ProjectCycleTable.propTypes = {
  rowData: PropTypes.array,
};

export default ProjectCycleTable;
