import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { MODAL_CONTENT } from '../CropTypeModal/CropType.content';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogTitleStyle,
  SubmitBtnStyle,
  closeIconSx,
} from '../Modals.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { checkTernaryCondition } from 'utils/helper';
import { useForm } from 'hooks/useForm';
import {
  CropTypeModalDialogActionStyle,
  CropTypeModalDialogTitleStyle,
} from '../CropTypeModal/CropTypeModal.style';
import {
  ErrorWrapper,
  TextWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  FARM_FIELD_LABEL_COLOR,
  FORM_BG_BORDER,
} from 'theme/GlobalColors';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import { useContext, useEffect, useState } from 'react';
import {
  ADD_CROP_CONTENT,
  cropSeasons,
  initialCropTypeData,
  productionPurpose,
} from './AddCropModal.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import { SectionWrapper } from './AddCropModal.style';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';

const AddCropModal = ({
  modalOpen = { flag: false },
  setModalOpen,
  submitHandler,
  makeFormDirty = () => null,
}) => {
  const { cropKeys } = useContext(implementedActivitiesWithOpsDataContext);
  const [errorState, setErrorState] = useState({
    cropId: false,
    cropRotation: false,
    productionPurpose: false,
    cropSeason: false,
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [modalKeyError, setModalKeyError] = useState('');

  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(initialCropTypeData);
  const { cropTypeDropDown, fetchCropTypeDropdown } = useContext(
    baselineDropdownContext,
  );

  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(initialCropTypeData);
    setErrorStatesToFalse();
  };

  const setErrorStatesToFalse = () => {
    setErrorState({
      cropId: false,
      cropRotation: false,
      productionPurpose: false,
    });
  };

  const submitBtnHandler = () => {
    const hasError = {
      cropId: formValue.cropId === '' && !isFallowSelected(),
      productionPurpose: !formValue.productionPurpose,
      cropSeason: formValue.cropSeason === '',
    };
    setErrorState(hasError);
    if (!Object.values(hasError).includes(true)) {
      submitHandler(
        {
          id: uniqueId(),
          ...formValue,
        },
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        'cropList',
      );
      modalCloseHandler();
      setErrorStatesToFalse();
      makeFormDirty();
    }
  };
  useEffect(() => {
    setIsSubmitDisabled(Object.values(errorState).includes(true));
  }, [errorState]);
  const isFallowSelected = () => {
    return (
      formValue.productionPurpose.toLowerCase() ===
      MODAL_CONTENT.fallow_crop_value.toLowerCase()
    );
  };
  useEffect(() => {
    fetchCropTypeDropdown(formValue.productionPurpose, true);
    if (
      formValue.productionPurpose.toLowerCase() ===
      MODAL_CONTENT.fallow_crop_value.toLowerCase()
    ) {
      setFormValue({
        ...formValue,
        cropId:
          cropTypeDropDown.filter(
            (item) => item.label.toLowerCase() === 'fallow',
          )[0]?.value || 6,
      });
    } else {
      setFormValue({
        ...formValue,
        cropId: '',
      });
    }
  }, [formValue.productionPurpose]);

  useEffect(() => {
    customFieldChangeHandler(
      'cropTypeDropDownBasedOnProductionPurpose',
      cropTypeDropDown.filter((item) => item.value === formValue.cropId),
    );
  }, [formValue.cropId]);
  useEffect(() => {
    const filteredCropKey = cropKeys.map((item) =>
      item.toLowerCase().includes('fallow')
        ? item.slice(item.indexOf('-') + 1)
        : item,
    );
    const modalKey =
      formValue.productionPurpose.toLowerCase() ===
      MODAL_CONTENT.fallow_crop_value.toLowerCase()
        ? `${formValue.cropSeason}-${formValue.productionPurpose}`
        : `${formValue.cropId}-${formValue.cropSeason}-${formValue.productionPurpose}`;
    setModalKeyError(filteredCropKey.includes(modalKey));
  }, [formValue.cropId, formValue.productionPurpose, formValue.cropSeason]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={() => {
        modalCloseHandler();
      }}
      sx={{
        '.MuiDialog-paper': {
          padding: '1.5rem',
          borderRadius: '2px',
          height: '240px',
          width: '600px',
        },
      }}>
      <DialogTitle
        sx={{
          ...DialogTitleStyle,
          ...CropTypeModalDialogTitleStyle,
          ...flexSpaceBetween,
        }}>
        <TextWrapper fontSize="1rem" fontWeight={600} color={DARK_CHARCOAL}>
          {ADD_CROP_CONTENT.add_crop}
        </TextWrapper>
        <CloseIcon
          onClick={() => modalCloseHandler()}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          gap: '.9rem',
          flexDirection: 'column',
          '&.MuiDialogContent-root': {
            padding: modalKeyError ? '0rem' : '1rem 0rem',
          },
        }}>
        {modalKeyError && (
          <ErrorWrapper letterSpacing="0rem">
            {ADD_CROP_CONTENT.duplicate_crop_error}
          </ErrorWrapper>
        )}
        <div>
          <RadioButtonsGroup
            headerColor={FARM_FIELD_LABEL_COLOR}
            marginRight={'2rem'}
            marginTop={'0.1rem'}
            marginBottom={'0rem'}
            list={productionPurpose}
            label={MODAL_CONTENT.production_purpose}
            direction="row"
            selectedValue={formValue.productionPurpose}
            checkedIsDarkGreen
            primaryError={errorState.productionPurpose || modalKeyError}
            handleChange={(e) => {
              customFieldChangeHandler('productionPurpose', e.target.value);
              setErrorState({ ...errorState, productionPurpose: false });
            }}
          />
        </div>
        <SectionWrapper>
          <DropDown
            onUpdate={() => {
              setErrorState({ ...errorState, cropId: false });
            }}
            headerColor={FARM_FIELD_LABEL_COLOR}
            minWidth="13rem"
            defaultDropdownColor={FORM_BG_BORDER}
            labelMarginTop={checkTernaryCondition(
              errorState.productionPurpose,
              '0.5rem',
              '0rem',
            )}
            customPlaceholderColor={COOL_GRAY}
            hasNoBottomMargin={true}
            label={MODAL_CONTENT.crop_type}
            value={checkTernaryCondition(
              formValue.cropId === '',
              MODAL_CONTENT.select_crop_type,
              formValue.cropId,
            )}
            stateValue={formValue.cropId}
            setFormFieldValue={customFieldChangeHandler}
            setStateValue={() => {}}
            field_key="cropId"
            name={MODAL_CONTENT.crop_type}
            dropdownlist={cropTypeDropDown}
            dropDownPlaceholder={MODAL_CONTENT.select_crop_type}
            width={MODAL_CONTENT.thirteenPointSevenFiveRem}
            height={MODAL_CONTENT.dropdownHeight}
            error={(errorState.cropId || modalKeyError) && !isFallowSelected()}
            setModalErrorState={() => {}}
            fieldName="cropId"
            showLabelAsValue={true}
            isDisabled={isFallowSelected()}
          />
          <RadioButtonsGroup
            headerColor={FARM_FIELD_LABEL_COLOR}
            marginRight={'2rem'}
            marginTop={'0.1rem'}
            marginBottom={'0rem'}
            list={cropSeasons}
            label={ADD_CROP_CONTENT.crop_season}
            direction="row"
            selectedValue={formValue.cropSeason}
            checkedIsDarkGreen
            primaryError={errorState.cropSeason || modalKeyError}
            handleChange={(e) => {
              customFieldChangeHandler('cropSeason', e.target.value);
              setErrorState({ ...errorState, cropSeason: false });
            }}
          />
        </SectionWrapper>
      </DialogContent>
      <DialogActions
        style={{ ...DialogActionStyle, ...CropTypeModalDialogActionStyle }}>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={() => {
            modalCloseHandler();
          }}>
          {MODAL_CONTENT.cancel}
        </Button>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled || modalKeyError}
          onClick={() => {
            submitBtnHandler();
          }}>
          {MODAL_CONTENT.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddCropModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  yearWiseCashCropList: PropTypes.array,
  makeFormDirty: PropTypes.func,
};
export default AddCropModal;
