import { MenuItem } from '@mui/material';
import { menuItemSx } from '../CustomOptionsButton.style';
import { useActivityContext } from 'containers/ActivityListContainer';
import { pathName } from 'Routes/routes.const';
import { baselineReview } from 'pages/Origination/Org_Project/Participants/BaselineReview';
import { getActionIdFromActionCode } from 'pages/Origination/Org_Project/Participants/Participants.content';
import { useContext } from 'react';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import PropTypes from 'prop-types';
import { checkTernaryCondition, hasAnyRequiredAccess } from 'utils/helper';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { ORIGINATION_VIEWER } from 'utils/config';
import { DOWNLOAD_ENROLLMENT_BASELINE_REPORT } from 'urls';
import { ViewPdfButton } from './EnrollmentButtons.react';

export const InitiateBaselineReportingButton = ({
  label,
  disabled = false,
}) => {
  const {
    setBaselineModalProps,
    activity,
    participantName,
    projectId,
    participantId,
    projectName,
    statusMappingList,
    closeMenu,
    projectCycleId,
  } = useActivityContext();

  return (
    <MenuItem
      sx={menuItemSx}
      key="initiateBaseline"
      disabled={disabled}
      onClick={() => {
        setBaselineModalProps({
          open: true,
          actionId: activity.actionId,
          formId: activity.formId,
          participantName,
          navigateLink: `${pathName.participant.participantFieldBaseline}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`,
          navigateState: {
            projectId,
            projectName,
            participantId,
            participantFirstName: participantName,
            participantLastName: '',
            participantEmail: '',
          },
          actionCode: statusMappingList.find((data) => data.actionId === 1)
            ?.actionCode,
        });
        closeMenu();
      }}>
      {label}
    </MenuItem>
  );
};

InitiateBaselineReportingButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export const ReviewMenuItem = () => {
  const {
    setReviewEnrollmentProps,
    participantId,
    projectId,
    activity,
    statusMappingList,
    closeMenu,
    projectCycleId,
  } = useActivityContext();
  const { updateParticipantBaselineStatus } = useContext(LifeCycleContext);
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);
  const { userRoles } = useContext(userAuthRolesContext);
  const isViewer = hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]);

  const handleClick = () => {
    Promise.resolve(
      setAreUiActionInProgress((prev) => prev + 1),
      setReviewEnrollmentProps({
        ...baselineReview(participantId, projectId),
        modalData: { flag: true },
        formId: activity.formId,
        baselineActionId: getActionIdFromActionCode(
          'BASELINE-REVIEW',
          statusMappingList,
        ),
        projectCycleId: projectCycleId,
      }),
    ).then(() => {
      if (
        getActionIdFromActionCode('BASELINE-SUBMIT', statusMappingList) ===
        activity.actionId
      ) {
        updateParticipantBaselineStatus({
          formId: activity.formId,
          actionId: getActionIdFromActionCode(
            'BASELINE-REVIEW',
            statusMappingList,
          ),
          projectCycleId,
        });
      }
    });
    closeMenu();
  };

  return checkTernaryCondition(
    isViewer,
    <ViewPdfButton url={DOWNLOAD_ENROLLMENT_BASELINE_REPORT} />,
    <MenuItem sx={menuItemSx} key="reviewSubmitted" onClick={handleClick}>
      Review
    </MenuItem>,
  );
};
