import {
  checkTernaryCondition,
  isEmpty,
  isInValidDate,
  returnTrueWhenValueIsNullOrEmpty,
  setIntegerValueWhenEmpty,
  sortFieldsByName,
} from 'utils/helper';
import { BASELINE_MODAL_CONTENT } from './ReportingComponents/TabBodyComponent.content';
import moment from 'moment';
import axios from 'axios';
import {
  FETCH_ALL_USER_ACTIONS,
  FETCH_USER_LOCK_DETAILS,
  GET_LATEST_ACTIVITY_STATUS,
} from 'urls';
import { COMPLETED_STATUS_LIST } from 'utils/config';

export const getStatus = (farmList) => {
  const allFieldDetails = farmList?.flatMap((farm) => farm?.fieldDetails) || [];
  const status = checkTernaryCondition(
    allFieldDetails
      .filter((fields) => fields.fieldId)
      .every((field) => field.fieldStatus === 'Completed'),
    'complete',
    'in progress',
  );

  return status;
};

const getProductionPurposeValue = (productionPurpose) => {
  if (
    productionPurpose === BASELINE_MODAL_CONTENT.cash_crop_value ||
    productionPurpose === BASELINE_MODAL_CONTENT.cover_crop_value ||
    productionPurpose === BASELINE_MODAL_CONTENT.fallow_crop_value
  ) {
    return productionPurpose;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.cash_crop.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.cash_crop_value;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.cover_crop.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.cover_crop_value;
  }
  if (
    productionPurpose?.toLowerCase() ===
    BASELINE_MODAL_CONTENT.fallow.toLowerCase()
  ) {
    return BASELINE_MODAL_CONTENT.fallow_crop_value;
  }
};

export const getFormattedCashcropList = (
  parentState,
  editMode,
  deletePracticeModalsData,
  submitData,
) => {
  return parentState?.map((item) => {
    const valueToUse = checkTernaryCondition(item.data, item.data, item);
    const cropPlantDate = checkTernaryCondition(
      valueToUse.cropPlantDate?.toString().length === 0,
      '',
      moment(valueToUse.cropPlantDate).format('YYYY-MM-DD'),
    );
    const cropHarvestDate = checkTernaryCondition(
      valueToUse.cropTerminationDate?.toString().length === 0,
      '',
      moment(valueToUse.cropTerminationDate).format('YYYY-MM-DD'),
    );
    const editArrayIs = checkTernaryCondition(
      editMode,
      {
        cropSampleId: checkTernaryCondition(
          valueToUse.cropSampleId &&
            !valueToUse.cropSampleId.toString().includes('-'),
          valueToUse.cropSampleId,
          0,
        ),
        deletedIrrigationList: Array.from(
          new Set(
            deletePracticeModalsData?.deletedIrrigationList
              .filter((item) => item.parentId === valueToUse.id)
              .map((item) => item.subId),
          ),
        ),
        deletedSoilAmendmentList:
          deletePracticeModalsData?.deletedSoilAmendmentList
            .filter((item) => item.parentId === valueToUse.id)
            .map((item) => item.subId),
      },
      {},
    );
    return {
      ...editArrayIs,
      cropSeason: valueToUse.cropSeason,
      yieldUnitOfMeasure: checkTernaryCondition(
        valueToUse.annualYield,
        valueToUse.annualYieldUom,
        null,
      ),
      cropId: valueToUse.cropId,
      cropArea: setIntegerValueWhenEmpty(valueToUse.cropArea),
      cropAreaUom: +valueToUse.cropAreaUom,
      annualYield: setIntegerValueWhenEmpty(valueToUse.annualYield),
      plantingDensity: checkTernaryCondition(
        +valueToUse.plantingDensity > 0,
        +valueToUse.plantingDensity,
        valueToUse.plantingDensity,
      ),
      cropValue: setIntegerValueWhenEmpty(valueToUse.cropValue),
      plantingDensityUom: checkTernaryCondition(
        isEmpty(valueToUse.plantingDensityUom),
        null,
        valueToUse.plantingDensityUom,
      ),
      seedingDepth: checkTernaryCondition(
        +valueToUse.cropDepth > 0,
        +valueToUse.cropDepth,
        valueToUse.cropDepth,
      ),
      seedingDepthUom: checkTernaryCondition(
        isEmpty(valueToUse.cropDepthUom),
        null,
        valueToUse.cropDepthUom,
      ),
      cropPlantDate: checkTernaryCondition(
        isInValidDate(cropPlantDate),
        '',
        cropPlantDate,
      ),
      cropTerminationDate: checkTernaryCondition(
        isInValidDate(cropHarvestDate),
        '',
        cropHarvestDate,
      ),
      addedFromImplementedActivities: Boolean(
        valueToUse.addedFromImplementedActivities,
      ),
      productionPurpose: getProductionPurposeValue(
        valueToUse.productionPurpose,
      ),
      cropPlannedManagementTypeId: valueToUse.cropPlannedManagementTypeId,
      projectActivities: valueToUse.activityList || [],
      projectActivityId: 0,
      cropRotation: false,
      isCopied: valueToUse.isCopied,
      tillageSystemOther: valueToUse.tillageSystemOther,
      tillageSystemDisturbanceId: valueToUse.tillageSystemDisturbanceId,
      fossilFuelList: [],
      irrigationTypeList: valueToUse?.irrigationList
        ?.filter((item) => item.irrigationType !== '')
        .map((obj) => {
          return {
            irrigationMethodId: Number(obj?.irrigationType),
            irrigationVolume: setIntegerValueWhenEmpty(obj?.irrigationRate),
            irrigationVolumeUom: obj?.uom,
            irrigationSampleId:
              checkTernaryCondition(
                obj.id?.toString().includes('-'),
                0,
                obj.id,
              ) || 0,
          };
        }),
      soilAmendmentList: valueToUse?.soilAmendmentList
        ?.filter((item) => item.soilAmendmentsType !== '')
        ?.map((obj) => {
          return {
            projectActivityId: 0,
            amountApplied: setIntegerValueWhenEmpty(obj?.amountApplied),
            soilAmendmentTypeId: Number(obj?.soilAmendmentsType),
            unitOfMeasure: obj?.unitOfMeasure,
            addedFromImplementedActivities: Boolean(
              obj?.addedFromImplementedActivities,
            ),
            soilAmendSampleId: checkTernaryCondition(
              obj.id?.toString().includes('-'),
              0,
              obj.id,
            ),
          };
        }),
      residueManagementTypeList: checkTernaryCondition(
        returnTrueWhenValueIsNullOrEmpty(
          valueToUse?.agricultureResiduesManaged,
        ) ||
          valueToUse?.agricultureResiduesManaged === undefined ||
          valueToUse?.cropResidueRemoved === undefined,
        [],
        [
          {
            agresidueSampleId: 0,
            cropResidueManagementRemovalMethodId:
              valueToUse?.agricultureResiduesManaged,
            cropResidueRemoved: setIntegerValueWhenEmpty(
              valueToUse?.cropResidueRemoved,
            ),
          },
        ],
      ),
    };
  });
};

export const formatNitrogenList = (nitrogenList) => {
  return nitrogenList.map((obj) => {
    return {
      applicationDate: obj?.data?.datesApplied
        ?.filter((date) => date?.toString().trim()?.length > 0)
        .map((date) => {
          return moment(date).format('YYYY-MM-DD');
        }),
      applicationMethodId: Number(obj?.data?.applicationMethod),
      containsInhibitor: obj?.data?.hasInhibitor === 'Yes',
      fertilizerApplicationRate: Number(obj?.data?.applicationRate),
      fertilizerApplicationUom: checkTernaryCondition(
        isEmpty(obj?.data?.unitOfMeasure),
        null,
        obj?.data?.unitOfMeasure,
      ),
      fertilizerId: obj?.data?.fertilizerType,
      fertilizerSampleId: checkTernaryCondition(
        obj.id?.toString().includes('-'),
        0,
        obj.id,
      ),
      kpercentage: checkTernaryCondition(
        obj?.data?.kPercentage === '',
        null,
        Number(obj?.data?.kPercentage),
      ),
      manureTypeId: checkTernaryCondition(
        obj?.data?.manureType === '',
        0,
        obj?.data?.manureType,
      ),
      npercentage: checkTernaryCondition(
        obj?.data?.nPercentage === '',
        null,
        Number(obj?.data?.nPercentage),
      ),
      ppercentage: checkTernaryCondition(
        obj?.data?.pPercentage === '',
        null,
        Number(obj?.data?.pPercentage),
      ),
    };
  });
};
export const formatTillageList = (tillageTypeDtoList) => {
  return tillageTypeDtoList.map((obj) => {
    return {
      addedFromImplementedActivities: obj?.addedFromImplementedActivities,
      tillagePracticeId: Number(obj?.data?.tillagePractice),
      projectActivityId: 0,
      performedOn: obj?.data?.tillageDates
        ?.filter((date) => date?.toString().trim()?.length > 0)
        .map((date) => {
          return moment(date).format('YYYY-MM-DD');
        }),
      tillageDepth: obj?.data?.depthOfTillage ?? '',
      surfaceDisturbance: obj?.data?.surfaceDisturbance ?? '',
      specificTillageType: '',
      tillageSampleId: checkTernaryCondition(
        obj.id?.toString().includes('-'),
        0,
        obj.id,
      ),
    };
  });
};
export const getcashCropSubmitBody = ({
  fieldId,
  projectId,
  participantId,
  sampleId,
  cashCropList,
  nitrogenList,
  tillageTypeDtoList,
  farmId,
  yearStatus,
  reportingStatus,
  operationsDataTabStatus,
  projectCycleId,
  editMode,
  deletePracticeModalsData,
}) => {
  return {
    addBaselineDto: {
      activityReportingStatus: checkTernaryCondition(
        reportingStatus,
        'Enrollment In Progress',
        'Interested',
      ),
      fieldId: fieldId,
      projectId: +projectId,
      participantId: +participantId,
      participantBaselineStatus: 'Enrollment In Progress',
      farmId: farmId,
      sampleId: sampleId,
      projectCycleId: projectCycleId,
      addedFromImplementedActivities: false,
    },
    baselineFieldDto: {
      fieldStatus: checkTernaryCondition(
        cashCropList?.length > 0,
        'Completed',
        'In Progress',
      ),
      fieldOperationsDataStatus: operationsDataTabStatus || 'Completed',
      cropList: getFormattedCashcropList(
        cashCropList,
        editMode,
        deletePracticeModalsData,
      ),
      nitrogenList: formatNitrogenList(nitrogenList),
      tillageTypeDtoList: formatTillageList(tillageTypeDtoList),
      yearStatus: yearStatus,
    },
  };
};

/**
 * Formats the reporting years data according to the state management requirements.
 *
 * @param {api data: Array} data
 * @param {"farm" or "field"} yearType
 * @returns {Array} The formatted years data.
 */
export const formatReportingYearsData = (data, yearType, type) => {
  const dataToReturn = data
    .map((year) => ({
      yearValue: year.dateId.toString(),
      sampleId: year.sampleId,
      type: yearType,
      status: year.formStatus,
      previousStatus: year.formStatus,
      phase: year.phase,
      projectCycleId: year.projectCycleId,
    }))
    .sort((year1, year2) => year2.yearValue - year1.yearValue);
  if (type === 'activity') {
    const filteredObject = [...dataToReturn].find(
      (year) => year.phase.toLowerCase() === 'project',
    );
    const newEnrolmentYearObjectOperations = {
      ...filteredObject,
      displayTitle: 'Operations data',
    };
    return [newEnrolmentYearObjectOperations];
  } else {
    return dataToReturn;
  }
};

export const getFormattedFarmData = (fossilFuels = []) => {
  const fossiFuelFormatted = fossilFuels?.map((data) => ({
    ffSampleId: checkTernaryCondition(typeof data.id === 'string', 0, data.id),
    ffId: data.fossilFuelType,
    consumption: parseFloat(data.consumption),
    unitOfMeasure: data.unitOfMeasure,
    isCopied: data.isCopied,
  }));
  const yearStatus = checkTernaryCondition(
    fossilFuels.length === 0,
    'Not Started',
    'Completed',
  );

  return { yearStatus, fossiFuelFormatted };
};
const transformNitrogenList = (nitrogenList) => {
  let result = [];

  nitrogenList.forEach((item) => {
    if (item.fertilizerSampleId === 0 || item.applicationDate.length === 0) {
      result.push(item);
    } else {
      const applicationDates = item.applicationDate;
      const fertilizerSampleIds = item.fertilizerSampleId.toString().split('_');

      applicationDates.forEach((date, index) => {
        let newItem = { ...item };
        newItem.applicationDate = [date];
        newItem.fertilizerSampleId = parseInt(fertilizerSampleIds[index], 10);
        result.push(newItem);
      });
    }
  });

  return result;
};

const transformTillageList = (tillageTypeDtoList) => {
  let result = [];
  tillageTypeDtoList.forEach((item) => {
    if (item.tillageSampleId === 0 || item.performedOn.length === 0) {
      result.push(item);
    } else {
      const datesPerformed = item.performedOn;
      const tillageSampleIds = item.tillageSampleId.toString().split('_');
      datesPerformed.forEach((date, index) => {
        let newItem = { ...item };
        newItem.performedOn = [date];
        newItem.tillageSampleId = parseInt(tillageSampleIds[index], 10);
        result.push(newItem);
      });
    }
  });

  return result;
};

const transformDeletedFertilizerList = (deletedFertilizerList) => {
  let result = [];

  deletedFertilizerList.forEach((item) => {
    const id = item.id?.toString();

    if (id.includes('-')) {
      return;
    } else if (id.includes('_')) {
      const splitIds = id.split('_');
      result.push(...splitIds.map(Number));
    } else {
      result.push(Number(id));
    }
  });

  return result;
};
const transformDeletedTillageList = (deletedTillageList) => {
  let result = [];

  deletedTillageList.forEach((item) => {
    const id = item.id?.toString();

    if (id.includes('-')) {
      return;
    } else if (id.includes('_')) {
      const splitIds = id.split('_');
      result.push(...splitIds.map(Number));
    } else {
      result.push(Number(id));
    }
  });
  return result;
};
export const getFieldEditApiData = (
  formData,
  submitData,
  fieldId,
  deletePracticeModalsData,
  operationsDataTabStatus = 'Not Started',
) => {
  const fieldStatus = checkTernaryCondition(
    formData.baselineFieldDto.fieldStatus === 'Completed',
    'CP',
    'IP',
  );

  const apiParams = {
    fieldId: fieldId,
    fieldStatus: fieldStatus,
    sampleId: submitData?.sampleId,
    fieldOperationsDataStatus: operationsDataTabStatus,
    projectCycleId: formData.addBaselineDto.projectCycleId,
  };

  const reqBody = {
    fieldSampleCrops: formData.baselineFieldDto.cropList,
    yearStatus: formData.baselineFieldDto.yearStatus,
    nitrogenList: transformNitrogenList(formData.baselineFieldDto.nitrogenList),
    deletedNitrogenList: transformDeletedFertilizerList(
      deletePracticeModalsData?.deletedFertilizerList,
    ),
    tillageTypeDtoList: transformTillageList(
      formData.baselineFieldDto.tillageTypeDtoList,
    ),
    deletedTillageList: transformDeletedTillageList(
      deletePracticeModalsData?.deletedTillageList,
    ),
  };
  return { apiParams, reqBody };
};
/**
 * Updates the expanded state of all accordions for a given year tab.
 * This function is used in BaselineReporting and ActivityReporting pages
 * for handling the state of the accordions.
 *
 * @param {number} year - The year for which the expanded state is being updated.
 * @param {Function} setAllExpanded - The state setter function to update the expanded state.
 * @param {Object} currentFieldId - The ID of the current field whose state is being updated.
 * @param {boolean} expandedState - The state to set for the accordions (expanded or collapsed).
 */
export const updateAllExpandedState = (
  year,
  setAllExpanded,
  currentFieldId,
  expandedState,
) => {
  setAllExpanded((prev) => {
    const updatedYear = {
      ...prev[year],
      [currentFieldId.id]: expandedState,
    };
    return {
      ...prev,
      [year]: checkTernaryCondition(prev[year], updatedYear, {
        [currentFieldId.id]: expandedState,
      }),
    };
  });
};

export const getActivityLatestStatus = async ({
  participantId,
  projectId,
  activityType,
}) => {
  return axios
    .get(
      `${GET_LATEST_ACTIVITY_STATUS}?participantId=${+participantId}&internalProjectId=${+projectId}&phase=${activityType}`,
    )
    .then((res) => {
      return res.data;
    });
};

export const fetchAllUserActions = async () => {
  return axios.get(FETCH_ALL_USER_ACTIONS).then((response) => {
    return response.data;
  });
};

/**
 * Checks if an activity has been submitted based on its latest status.
 *
 * @param {Object} params - The parameters for the function.
 * @param {number} params.participantId - The ID of the participant.
 * @param {number} params.projectId - The ID of the project.
 * @param {string} params.activityType - The type of the activity.
 * @param {Array} params.statusMappingList - The list of status mappings.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the activity is submitted.
 */
export const isActivitySubmitted = async ({
  participantId,
  projectId,
  activityType,
  statusMappingList,
}) => {
  return getActivityLatestStatus({
    participantId: participantId,
    projectId: projectId,
    activityType: activityType,
  }).then((data) => {
    return COMPLETED_STATUS_LIST.includes(
      statusMappingList.find((status) => status.actionId === data).actionCode,
    );
  });
};

export function getFarmsWithSortedFields(farmList) {
  if (!farmList) return [];

  return farmList.map((farm) => {
    const sortedFields = sortFieldsByName(farm.fieldDetails, 'fieldName');
    return {
      ...farm,
      fieldDetails: sortedFields,
    };
  });
}

export const fetchUserLockDetails = async (
  farmId,
  internalProjectId,
  participantOwnerId,
  enrollmentType,
) => {
  return axios
    .get(`${FETCH_USER_LOCK_DETAILS}/${farmId}`, {
      params: {
        internalProjectId,
        participantOwnerId,
        enrollmentType,
      },
    })
    .then((response) => {
      return response.data;
    });
};
