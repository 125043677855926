import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  outlinedDropdownInputSx,
  InputSx,
  FieldSectionWrapper,
} from '../Modals.style';
import {
  MODAL_CONTENT,
  errorSetter,
  unitOfMeasureDropdownList,
} from './SoilAmendmentsModal.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { checkTernaryCondition, findObjectWithKey } from 'utils/helper';
import InputField from 'components/FormComponents/InputField/InputField.react';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import { handleTextFieldValueChange } from '../Modals.content';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { FormWrapper } from 'pages/ParticipantDataCollection/ReportingComponents/Modals.style';
import { FORM_BG_BORDER } from 'theme/GlobalColors';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { deleteIconSx } from 'components/FormComponents/FormStyles';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { SOIL_AMENDMENT_AMOUNT_APPLIED } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const SoilAmendmentsModal = ({
  modalOpen = { flag: false },
  submitHandler,
  parentId,
  existingSoilAmendmentIds,
  setShouldSubmitWork,
  makeFormDirty = () => null,
  isEditable = true,
}) => {
  const formValue = modalOpen.data;
  const [errorState, setErrorState] = useState(errorSetter(modalOpen.data));
  const {
    unitOfMeasureList: { cropAndLandUse },
  } = useContext(uomContext);

  const customFieldChangeHandler = (key, value) => {
    submitHandler(
      {
        ...formValue,
        [key]: value,
      },
      modalOpen.id,
      'soilAmendmentList',
    );
  };
  const { soilAmendmentsDropdown } = useContext(baselineDropdownContext);
  const { submitData, handlePracticeTypeDelete, isFieldDisabled } = useContext(
    implementedActivitiesWithOpsDataContext,
  );

  const submitBtnHandler = () => {
    setErrorState(errorSetter(formValue));
  };

  const setModalErrorState = (value, fieldName) => {
    switch (fieldName) {
      case 'soilAmendmentsType':
        setErrorState({ ...errorState, soilAmendmentsTypeError: value === '' });
        break;
      case 'amountApplied':
        setErrorState({ ...errorState, amountAppliedError: value === '' });
        break;
      case 'unitOfMeasure':
        setErrorState({ ...errorState, uomError: value === '' });
        break;
    }
  };

  useEffect(() => {
    if (submitData?.editMode === true && formValue?.isFetchedFromAPI) {
      submitBtnHandler();
    }
  }, [submitData]);

  return (
    <FormWrapper alignItem="center">
      <FormWrapper>
        <DropDown
          defaultDropdownColor={FORM_BG_BORDER}
          hasNoBottomMargin={true}
          value={checkTernaryCondition(
            formValue?.soilAmendmentsType === '',
            MODAL_CONTENT.select_type,
            formValue?.soilAmendmentsType,
          )}
          isDisabled={
            modalOpen.data?.addedFromImplementedActivities ||
            isFieldDisabled ||
            !isEditable
          }
          label={MODAL_CONTENT.soil_amendments_type}
          error={
            errorState?.soilAmendmentsTypeError && formValue.isFetchedFromAPI
          }
          setFormFieldValue={customFieldChangeHandler}
          stateValue={formValue?.soilAmendmentsType}
          setStateValue={() => {}}
          name={MODAL_CONTENT.soil_amendments_type}
          field_key="soilAmendmentsType"
          dropDownPlaceholder={MODAL_CONTENT.select_type}
          dropdownlist={soilAmendmentsDropdown?.filter(
            (type) =>
              !existingSoilAmendmentIds
                .filter((item) => item !== formValue.soilAmendmentsType)
                .includes(type.value),
          )}
          fontSize={MODAL_CONTENT.dropdownFontSize}
          minWidth="14rem"
          width="14rem"
          height={MODAL_CONTENT.dropdownHeight}
          setModalErrorState={setModalErrorState}
          fieldName={'soilAmendmentsType'}
          showLabelAsValue={true}
          labelMarginTop={MODAL_CONTENT.onePointFiveRem}
          labelMarginBottom={MODAL_CONTENT.zeroPointTwoFiveRem}
          customSx={outlinedDropdownInputSx}
          onUpdate={() => {
            setShouldSubmitWork(true);
            makeFormDirty();
          }}
        />
        <FieldSectionWrapper
          columnGap="1.5rem"
          marginBottom={MODAL_CONTENT.zeroRem}>
          <InputField
            isDisabled={
              isFieldDisabled ||
              formValue?.soilAmendmentsType === '' ||
              !isEditable
            }
            label={MODAL_CONTENT.amount_applied}
            placeholder={MODAL_CONTENT.type_amount}
            value={formValue?.amountApplied}
            minWidth="8rem"
            width="8rem"
            name="amountApplied"
            onUpdate={(event) => {
              handleTextFieldValueChange(
                event,
                'amountApplied',
                true,
                customFieldChangeHandler,
              );
              setModalErrorState(event.target.value, 'amountApplied');
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
            category="long-numbers-field"
            primaryError={
              errorState?.amountAppliedError && formValue.isFetchedFromAPI
            }
            labelMarginTop={MODAL_CONTENT.onePointFiveRem}
            labelMarginBottom={MODAL_CONTENT.zeroPointTwoFiveRem}
            rightSideLabelFormLabelMargin={MODAL_CONTENT.zeroRem}
            customInputSx={InputSx}
          />
          <RadioButtonsGroup
            isDisabled={
              isFieldDisabled ||
              formValue?.soilAmendmentsType === '' ||
              !isEditable
            }
            customSx={{
              maxWidth: '8rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '13px',
            }}
            selectedValue={
              unitOfMeasureDropdownList(
                findObjectWithKey(cropAndLandUse, SOIL_AMENDMENT_AMOUNT_APPLIED)
                  .SOIL_AMENDMENT_AMOUNT_APPLIED,
              )?.find((item) => item.value === formValue?.unitOfMeasure)
                ?.itemLabel
            }
            list={unitOfMeasureDropdownList(
              findObjectWithKey(cropAndLandUse, SOIL_AMENDMENT_AMOUNT_APPLIED)
                .SOIL_AMENDMENT_AMOUNT_APPLIED,
            )}
            label={MODAL_CONTENT.unit_of_measure}
            primaryError={errorState?.uomError && formValue.isFetchedFromAPI}
            direction="row"
            handleChange={(event) => {
              const list = unitOfMeasureDropdownList(
                findObjectWithKey(cropAndLandUse, SOIL_AMENDMENT_AMOUNT_APPLIED)
                  .SOIL_AMENDMENT_AMOUNT_APPLIED,
              );
              customFieldChangeHandler(
                MODAL_CONTENT.UOM_NAME,
                list.find((item) => item.itemLabel === event.target.value)
                  .value,
              );
              setModalErrorState(event.target.value, MODAL_CONTENT.UOM_NAME);
              setShouldSubmitWork(true);
              makeFormDirty();
            }}
            checkedIsDarkGreen
            marginTop={MODAL_CONTENT.onePointFiveRem}
            marginBottom="0rem"
            radioBtnPadding={'0rem'}
            radioBtnGap={'1.3rem'}
            radioBtnOptionGap={'0.5rem'}
            marginLeft={'0rem'}
          />
        </FieldSectionWrapper>
      </FormWrapper>
      {!isFieldDisabled && (
        <DeleteIconWithHoverEffect
          data-testid={`delete-icon-soil-amendments`}
          sx={{
            ...deleteIconSx,
            marginTop: '3.3rem',
            opacity: isEditable ? 1 : 0.5,
            pointerEvents: isEditable ? 'all' : 'none',
          }}
          onClick={() => {
            handlePracticeTypeDelete(
              formValue.isFetchedFromAPI,
              parentId,
              formValue?.id,
              'soilAmendmentList',
              'deletedSoilAmendmentList',
            );
            makeFormDirty();
          }}
        />
      )}
    </FormWrapper>
  );
};

export default SoilAmendmentsModal;

SoilAmendmentsModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
    data: PropTypes.object,
    id: PropTypes.number,
  }),
  submitHandler: PropTypes.func,
  parentId: PropTypes.number,
  existingSoilAmendmentIds: PropTypes.arrayOf(PropTypes.number),
  setShouldSubmitWork: PropTypes.func,
  makeFormDirty: PropTypes.func,
};
