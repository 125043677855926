import { useMemo, useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  FETCH_COVER_CROP_TYPE_DROPDOWN,
  FETCH_CROP_TYPE_DROPDOWN,
  FETCH_FERTILIZER_APPLICATION_METHOD,
  FETCH_FERTILIZER_TYPE_DROPDOWN,
  FETCH_FOSSIL_FUEL_TYPE_DROPDOWN,
  FETCH_GRAZING_TYPE_DROPDOWN,
  FETCH_IRRIGATION_METHOD_DROPDOWN,
  FETCH_LIVESTOCK_TYPE_DROPDOWN,
  FETCH_MANURE_MANAGEMENT_DROPDOWN,
  FETCH_MANURE_TYPE_DROPDOWN,
  FETCH_SOIL_AMENDMENTS_DROPDOWN,
  FETCH_TILLAGE_TYPE_DROPDOWN,
  FETCH_EDGE_OF_FIELD_PRACTICE_TYPE,
  FETCH_EDGE_OF_FIELD_SPICES_TYPE,
  FETCH_RESIDUE_MANAGEMENT_REMOVAL_METHOD,
  FETCH_ALL_CROP_TYPE_DROPDOWN,
  FETCH_MANAGEMENT_TYPE_DROPDOWN,
} from 'urls';
import { checkTernaryCondition, getUniqueObjectsByKey } from 'utils/helper';
import { useSearchParams } from 'react-router-dom';
import { getProductionPurposeValue } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';

export const baselineDropdownContext = createContext({});
export const BaselineDropdownProvider = ({ children }) => {
  const [fossilFuelTypeDropdown, setFossilFuelTypeDropdown] = useState([]);
  const [residueTypeDropdown, setResidueTypeDropdown] = useState([]);
  const [livestockTypeDropdown, setLivestockTypeDropdown] = useState([]);
  const [grazingTypeDropDown, setGrazingTypeDropDown] = useState([]);
  const [fertilizerTypeDropDown, setFertilizerTypeDropDown] = useState([]);
  const [fertilizerNoneId, setFertilizerNoneId] = useState(null);
  const [statusList, setStatusList] = useState('');
  const [activityStatusList, setActivityStatusList] = useState('');
  const [irrigationMethodDropDown, setIrrigationMethodDropDown] = useState([]);
  const [manureManagementSystemDropdown, setManureManagementSystemDropdown] =
    useState([]);
  const [soilAmendmentsDropdown, setSoilAmendmentsDropdown] = useState([]);
  const [tillageTypeDropdown, setTillageTypeDropdown] = useState([]);
  const [selectedTillagePractice, setSelectedTillagePractice] = useState('');
  const [coverCropTypeDropdown, setCoverCropTypeDropdown] = useState([]);
  const [allCropTypeDropDown, setAllCropTypeDropDown] = useState([]);
  const [animalTypeDropdown, setAnimalTypeDropdown] = useState([]);
  const [manureTypeDropdown, setManureTypeDropdown] = useState([]);
  const [plannedUsage] = useState([]);
  const [tillIds, setTillIds] = useState([]);
  const [cropTypeDropDown, setCropTypeDropDown] = useState([]);
  const [fallowDropDown, setFallowDropdown] = useState([]);
  const [cashCropDropDown, setCashCropDropDown] = useState([]);
  const [cropCoverDropDown, setCropCoverDropDown] = useState([]);
  const [fertilizerApplicationMethod, setFertilizerApplicationMethod] =
    useState([]);
  const [terminationType] = useState([]);
  const [loaderCounter, setLoaderCounter] = useState(0);
  const [practiceDropdown, setPracticeDropdown] = useState([]);
  const [speciesCategory, setSpeciesCategory] = useState([]);
  const [managementTypeDropDown, setManagementTypeDropDown] = useState([]);
  const [irrigationNoneId, setIrrigationNoneId] = useState(null);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');

  const fetchManagementTypeDropdown = () => {
    axios.get(FETCH_MANAGEMENT_TYPE_DROPDOWN).then((response) => {
      setManagementTypeDropDown(
        response.data.map((data) => ({
          label: data.cropPlannedManagementType,
          value: data.cropPlannedManagementTypeId,
          flag: data.cropTerminationFlag,
        })),
      );
    });
  };

  const fetchCropTypeDropdown = (productionPurpose, dontShowLoader) => {
    if (!dontShowLoader) {
      setLoaderCounter((prev) => prev + 1);
    }
    const url = checkTernaryCondition(
      productionPurpose === 'FALLOW',
      FETCH_CROP_TYPE_DROPDOWN,
      `${FETCH_ALL_CROP_TYPE_DROPDOWN}=${getProductionPurposeValue(
        productionPurpose,
      )}`,
    );
    axios
      .get(url)
      .then((response) => {
        setCropTypeDropDown(
          response.data.map((data) => ({
            label: data.cropName,
            value: data.cropId,
          })),
        );
      })
      .finally(() => {
        if (!dontShowLoader) {
          setLoaderCounter((prev) => prev - 1);
        }
      });
  };

  const fetchFallowCropTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(`${FETCH_ALL_CROP_TYPE_DROPDOWN}=Fallow`)
      .then((response) => {
        setFallowDropdown(
          response.data.map((data) => ({
            label: data.cropName,
            value: data.cropId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchCashCropTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(`${FETCH_ALL_CROP_TYPE_DROPDOWN}=Cash crop`)
      .then((response) => {
        setCashCropDropDown(
          response.data.map((data) => ({
            label: data.cropName,
            value: data.cropId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchCropCoverTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(`${FETCH_ALL_CROP_TYPE_DROPDOWN}=Cover crop`)
      .then((response) => {
        setCropCoverDropDown(
          response.data.map((data) => ({
            label: data.cropName,
            value: data.cropId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchAllCropTypeDropdown = () => {
    axios.get(FETCH_CROP_TYPE_DROPDOWN).then((response) => {
      setAllCropTypeDropDown(
        response.data.map((data) => ({
          label: data.cropName,
          value: data.cropId,
        })),
      );
    });
  };
  const fetchFossilFuelTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_FOSSIL_FUEL_TYPE_DROPDOWN)
      .then((response) => {
        setFossilFuelTypeDropdown(
          response.data.map((data) => ({
            label: data.ffName,
            value: data.ffId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchResidueTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_RESIDUE_MANAGEMENT_REMOVAL_METHOD)
      .then((response) => {
        setResidueTypeDropdown(
          response.data.map((data) => ({
            label: data.value,
            value: data.id,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchGrazingTypeDropDown = () => {
    setLoaderCounter((prev) => prev + 1);
    axios
      .get(FETCH_GRAZING_TYPE_DROPDOWN)
      .then((response) => {
        setGrazingTypeDropDown(
          response.data.map((data) => ({
            label: data.prescribedGrazingSystem,
            value: data.prescribedGrazingSystemId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchManureManagementSystemDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_MANURE_MANAGEMENT_DROPDOWN)
      .then((response) => {
        setManureManagementSystemDropdown(
          response.data.map((data) => ({
            label: data.value,
            value: data.id,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchLivestockTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_LIVESTOCK_TYPE_DROPDOWN)
      .then((response) => {
        setLivestockTypeDropdown(
          response.data.map((data) => ({
            label: data.livestockType,
            value: data.livestockId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchFertilizerTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FERTILIZER_TYPE_DROPDOWN)
      .then((response) => {
        const dataList = response.data.map((data) => ({
          label: data.fertilizerName.replace(/^./, (x) => x.toUpperCase()),
          value: data.fertilizerId,
          form: data.fertilizerForm,
          category: data.fertilizerCategory,
          fertilizerSampleId: data.fertilizerId,
          nPercentage: data.npct,
          pPercentage: data.ppct,
          kPercentage: data.kpct,
        }));
        setFertilizerTypeDropDown(getUniqueObjectsByKey(dataList, 'label'));
        setFertilizerNoneId(
          response.data.find((item) => item.fertilizerName === 'None')
            ?.fertilizerId,
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchAnimalTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FERTILIZER_TYPE_DROPDOWN)
      .then((response) => {
        setAnimalTypeDropdown(
          response.data
            .filter(
              (item) =>
                item.fertilizerName.replace(/^./, (x) => x.toUpperCase()) ===
                'Manure',
            )
            .map((data) => ({
              label: data.manureLivestockType.replace(/^./, (x) =>
                x.toUpperCase(),
              ),
              value: data.fertilizerId,
              category: data.fertilizerCategory,
              fertilizerSampleId: data.fertilizerId,
            })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchManureTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_MANURE_TYPE_DROPDOWN)
      .then((response) => {
        setManureTypeDropdown(
          response.data.map((data) => ({
            label: data.manureType.replace(/^./, (x) => x.toUpperCase()),
            value: data.manureTypeId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchFertilizerApplicationMethod = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_FERTILIZER_APPLICATION_METHOD)
      .then((response) => {
        setFertilizerApplicationMethod(
          response.data.map((data) => ({
            label: data.fertilizerApplicationMethod,
            value: data.fertilizerApplicationMethodId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchIrrigationMethodDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_IRRIGATION_METHOD_DROPDOWN)
      .then((response) => {
        setIrrigationMethodDropDown(
          response.data.map((data) => ({
            label: data.irrigationMethod,
            value: data.irrigationMethodId,
          })),
        );
        setIrrigationNoneId(
          response.data.find((item) => item.irrigationMethod === 'None')
            ?.irrigationMethodId,
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchSoilAmendmentsDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_SOIL_AMENDMENTS_DROPDOWN, { params: { projectId: projectId } })
      .then((response) => {
        setSoilAmendmentsDropdown(
          response.data.map((data) => ({
            label: data.value,
            value: data.id,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchTillageTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    let idList = [];
    return axios
      .get(FETCH_TILLAGE_TYPE_DROPDOWN)
      .then((response) => {
        setTillageTypeDropdown(
          response.data.map((data) => ({
            label: data.tillagePracticeType,
            value: data.tillagePracticeId,
          })),
        );
        response?.data?.map((item) => {
          if (item.tillagePracticeType.includes('Reduced till')) {
            idList.push(item.tillagePracticeId);
          }
          setTillIds(idList);
        });
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };
  const fetchCoverCropTypeDropdown = () => {
    setLoaderCounter((prev) => prev + 1);
    return axios
      .get(FETCH_COVER_CROP_TYPE_DROPDOWN)
      .then((response) => {
        setCoverCropTypeDropdown(
          response.data.map((data) => ({
            label: data.coverCropType,
            value: data.coverCropTypeId,
            coverCropSampleId: data.coverCropSampleId,
          })),
        );
      })
      .finally(() => setLoaderCounter((prev) => prev - 1));
  };

  const fetchEdgeOfFiledPracticeTypeDropDown = () => {
    axios
      .get(FETCH_EDGE_OF_FIELD_PRACTICE_TYPE, {
        params: { projectId: projectId },
      })
      .then((response) => {
        setPracticeDropdown(
          response.data.map((item) => ({
            label: item.edgeOfFieldPractice,
            value: item.edgeOfFieldPracticeId,
          })),
        );
      });
  };
  const fetchEdgeOfFiledSpeciesTypeDropDown = () => {
    axios.get(FETCH_EDGE_OF_FIELD_SPICES_TYPE).then((response) => {
      setSpeciesCategory(
        response.data.map((item) => ({
          label: item.speciesCategoryName,
          value: item.speciesCategoryId,
        })),
      );
    });
  };

  useEffect(() => {
    fetchSoilAmendmentsDropdown();
    fetchTillageTypeDropdown();
    fetchFossilFuelTypeDropdown();
    fetchResidueTypeDropdown();
    fetchLivestockTypeDropdown();
    fetchGrazingTypeDropDown();
    fetchManureManagementSystemDropdown();
    fetchFertilizerTypeDropdown();
    fetchIrrigationMethodDropdown();
    fetchCoverCropTypeDropdown();
    fetchAnimalTypeDropdown();
    fetchManureTypeDropdown();
    fetchTillageTypeDropdown();
    fetchFertilizerApplicationMethod();
    fetchCropTypeDropdown();
    fetchEdgeOfFiledPracticeTypeDropDown();
    fetchEdgeOfFiledSpeciesTypeDropDown();
    fetchManagementTypeDropdown();
    fetchAllCropTypeDropdown();
    fetchFallowCropTypeDropdown();
    fetchCashCropTypeDropdown();
    fetchCropCoverTypeDropdown();
  }, []);

  const value = useMemo(
    () => ({
      fossilFuelTypeDropdown,
      livestockTypeDropdown,
      residueTypeDropdown,
      grazingTypeDropDown,
      manureManagementSystemDropdown,
      fertilizerTypeDropDown,
      irrigationMethodDropDown,
      soilAmendmentsDropdown,
      tillageTypeDropdown,
      coverCropTypeDropdown,
      animalTypeDropdown,
      manureTypeDropdown,
      plannedUsage,
      terminationType,
      fertilizerApplicationMethod,
      tillIds,
      cropTypeDropDown,
      loaderCounter,
      practiceDropdown,
      speciesCategory,
      selectedTillagePractice,
      setSelectedTillagePractice,
      statusList,
      setStatusList,
      activityStatusList,
      setActivityStatusList,
      fetchCropTypeDropdown,
      managementTypeDropDown,
      irrigationNoneId,
      setIrrigationNoneId,
      allCropTypeDropDown,
      setAllCropTypeDropDown,
      fertilizerNoneId,
      setFertilizerNoneId,
      fallowDropDown,
      setFallowDropdown,
      cashCropDropDown,
      setCashCropDropDown,
      cropCoverDropDown,
      setCropCoverDropDown,
    }),
    [
      fossilFuelTypeDropdown,
      residueTypeDropdown,
      livestockTypeDropdown,
      grazingTypeDropDown,
      manureManagementSystemDropdown,
      fertilizerTypeDropDown,
      irrigationMethodDropDown,
      soilAmendmentsDropdown,
      tillageTypeDropdown,
      coverCropTypeDropdown,
      animalTypeDropdown,
      manureTypeDropdown,
      plannedUsage,
      terminationType,
      fertilizerApplicationMethod,
      tillIds,
      cropTypeDropDown,
      loaderCounter,
      practiceDropdown,
      speciesCategory,
      selectedTillagePractice,
      setSelectedTillagePractice,
      statusList,
      setStatusList,
      activityStatusList,
      setActivityStatusList,
      managementTypeDropDown,
      irrigationNoneId,
      fertilizerNoneId,
      allCropTypeDropDown,
      fallowDropDown,
      cashCropDropDown,
      cropCoverDropDown,
    ],
  );

  return (
    <baselineDropdownContext.Provider value={value}>
      {children}
    </baselineDropdownContext.Provider>
  );
};

BaselineDropdownProvider.propTypes = {
  children: PropTypes.node,
};
