import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  DividerStyles,
  LeftSectionWrapper,
  MainContainer,
  MainWrapper,
  ProjectName,
  ProjectNameWrapper,
  TabberBoxCustomStyle,
  ParentContainer,
  TabStylesSx,
  projectNameStyle,
} from './ActivityReporting.style';
import {
  TabComponentWrapper,
  ParticipantFlowFooterWrapper,
  footerStyle,
  CollapseExpandButtonSx,
  HeaderButtonContainer,
  HeaderContainer,
} from '../BaseLineReporting/BaseLineReportingMainPage.style';
import {
  Backdrop,
  CircularProgress,
  Divider,
  Box,
  Tabs,
  Button,
  Typography,
  Switch,
} from '@mui/material';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import {
  PAGE_CONTENT,
  autoSaveTargetKeys,
  tabsTitle,
  activityInstanceStatus,
  FIELD_DATA_INIT,
} from './ActivityReporting.content';
import { TextWrapper } from 'theme/GlobalStyledComps';
import LeftMenuSubsection from '../components/LeftMenuSubsection/LeftMenuSubsection.react';
import { BLACK, DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';
import {
  checkTernaryCondition,
  getFarmLockedTooltip,
  isEmpty,
  rotateData,
  sortWithAKeyInSpecificOrder,
} from 'utils/helper';
import { useStyles, flexColumn, yAxisAutoScroll } from 'theme/GlobalStyles';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useReportingApis } from 'hooks/useReportingApis';
import {
  FETCH_PARTICIPANT_PROFILE_ACTIVITY,
  FETCH_PROJECT_LIFECYCLES,
  PARTICIPANT_PROFILE,
  UPDATE_FARM_STATUS,
} from 'urls';
import { pathName } from 'Routes/routes.const';
import TabBodyComponent from '../ReportingComponents/TabBodyComponent.react';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import {
  fetchAllUserActions,
  fetchUserLockDetails,
  getFarmsWithSortedFields,
  getFormattedFarmData,
  getStatus,
  isActivitySubmitted,
  updateAllExpandedState,
} from '../ReportingUtilFunctions';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import ProofOfImplementationMainComp from './components/ProofOfImplementationMainComp/ProofOfImplementationMainComp.react';
import {
  ACTIVITY_TYPES,
  ADMIN,
  ORIGINATION_MANAGER,
  PARTICIPANT_CONTRIBUTOR,
  REPORTING_STATUS,
} from 'utils/config';
import {
  getFarmById,
  getOperationsDataBody,
  submitPoiTabData,
} from './ActivityReporting.functions';
import axios from 'axios';
import EnrollmentCompleteModal from '../components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import moment from 'moment';
import UnsavedChangesModal from '../components/UnsavedChangesModal/UnsavedChangesModal.react';
import Footer from 'layout/footer/Footer.react';
import { columnFlexStyle } from '../ParticipantDataCollection.style';
import { ImplementedActivitiesWithOpsDataProvider } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import TabBodyComponentV2 from '../ReportingComponents/TabBodyComponentV2/TabBodyComponentV2.react';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import {
  FIELD_SUBMIT_TRIGGER_INIT,
  NAVIGATION_PROPS,
  UNSAVED_CHANGES_MODAL_PROPS_INIT,
} from '../ParticipantDataCollection.content';
import PropTypes from 'prop-types';
import { SwitchContainer } from '../ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import LightTooltip from 'components/LightTooltip';
import SwitchComponent from 'components/FormComponents/SwitchComponent/SwitchComponent.react';
import { useUserInactivity } from 'hooks/useUserInactivity';
import { useInterval } from 'hooks/useInterval';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { switchLabelStyle, swithStyle } from '../pages/ParticipantPages.style';

const ActivityReporting = ({ isInTesting = false }) => {
  const { setActivityStatusList, loaderCounter } = useContext(
    baselineDropdownContext,
  );
  const {
    userRoles,
    userDetails: { userId },
  } = useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles.includes(PARTICIPANT_CONTRIBUTOR);
  const [searchParams] = useSearchParams();
  const [farmDetails, setFarmDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [projectCycleData, setProjectCycleData] = useState({
    cycleStartDate: '',
    cycleEndDate: '',
  });
  const [currentField, setCurrentField] = useState(FIELD_DATA_INIT);
  const [type, setType] = useState(PAGE_CONTENT.farm);
  const [fieldDetails, setFieldDetails] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [activityTabData, setActivityTabData] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const [totalFieldArea, setTotalFieldArea] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchLastSectionData, setFetchLastSectionData] = useState(false);
  const [userActivityDetected, setUserActivityDetected] = useState(false);
  const [poiStatusList, setPoiStatusList] = useState([]);
  const [
    activityReportingMappingModalErrorMessage,
    setActivityReportingMappingModalErrorMessage,
  ] = useState('');
  const [submitData, setSubmitData] = useState({
    sampleId: null,
    parentState: null,
    editMode: false,
    practiceModalsData: null,
    deletePracticeModalsData: null,
    practicesData: null,
  });
  const [poiCurrentTab, setPoiCurrentTab] = useState(0);
  const [poiActivities, setPoiActivities] = useState([]);
  const [poiData, setPoiData] = useState([]);
  const [poiCurrentTabStatus, setPoiCurrentTabStatus] = useState('Not Started');
  const [activityFieldTabs, setActivityFieldTabs] = useState([]);
  const [reportingCompleteModal, setReportingCompleteModal] = useState({
    flag: false,
  });
  const [unsavedChangesModalProps, setUnsavedChangesModalProps] = useState(
    UNSAVED_CHANGES_MODAL_PROPS_INIT,
  );
  const [fieldSubmitTriggerProps, setFieldSubmitTriggerProps] = useState(
    FIELD_SUBMIT_TRIGGER_INIT,
  );
  const [triggerCurrentFieldDataFetch, setTriggerCurrentFieldDataFetch] =
    useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [activeUser, setActiveUser] = useState({
    activeUserId: null,
    name: null,
  });
  const idleEventTimeout = 1000 * 60 * 15; // 15 mins
  const { removeAllEventListeners, addAllEventListener } = useUserInactivity(
    idleEventTimeout,
    0,
    () => {
      handleSwitchChange({ target: { checked: false } });
    },
  );
  /**
   * State to manage expansion status of accordions in the current tab year.
   * This is a nested object where the key is the current tab year, and the value is another object where
   * the key is the fieldId and the value is a boolean indicating whether the
   * field is expanded (defaulted to true).
   */
  const [allExpanded, setAllExpanded] = useState({});
  const navigate = useNavigate();

  const { TabberBox } = useStyles();
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );
  const [activityApprovedFarmIds, setActivityApprovedFarmIds] = useState([]);
  const [activityApprovedFieldIds, setActivityApprovedFieldIds] = useState([]);

  const [annualImplementationCost, setAnnualImplementationCost] = useState('');
  const [statusMappingList, setStatusMappingList] = useState([]);
  const {
    loading: apiLoading,
    fetchBaselineFieldDetails,
    fetchBaselineFarmYears,
    fetchBaselineFieldYears,
    fetchProjectData,
    fetchFieldArea,
    submitBaselineFarm,
  } = useReportingApis();

  const closeUnsavedChangesModal = () =>
    setUnsavedChangesModalProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);

  // Gives the current farm details based on the currentFarmId
  const currentFarmData = useMemo(() => {
    return fieldDetails?.farmDetails?.find(
      (farm) => farm.farmId === currentFarmId,
    );
  }, [fieldDetails, currentFarmId]);

  const switcher = ({
    switchTo,
    farmId,
    farmName,
    fieldId,
    fieldName,
    tabIndex,
    targetType,
  }) => {
    switch (switchTo) {
      case 'Tab':
        setCurrentTab(tabIndex);
        if (targetType === PAGE_CONTENT.farm) {
          fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmId);
        } else {
          fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, fieldId);
        }
        break;

      case 'Farm':
        setCurrentTab(0);
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
        fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmId);

        // reset field data
        setCurrentField(FIELD_DATA_INIT);
        break;
      case 'Field':
        setCurrentTab(0);
        setCurrentField({ id: fieldId, name: fieldName });
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, fieldId);
        break;
    }
  };

  const handleLeaveWithoutSave = () => {
    switch (unsavedChangesModalProps.navigateNextTo) {
      case 'Tab':
        switcher({
          switchTo: 'Tab',
          farmId: unsavedChangesModalProps.targetFarmId,
          farmName: unsavedChangesModalProps.targetFarmName,
          fieldId: unsavedChangesModalProps.targetFieldId,
          fieldName: unsavedChangesModalProps.targetFieldName,
          tabIndex: unsavedChangesModalProps.nextTab,
          targetType: type, // Same as current type for tab change
        });
        break;
      case 'Farm':
        switcher({
          switchTo: 'Farm',
          farmId: unsavedChangesModalProps.targetFarmId,
          farmName: unsavedChangesModalProps.targetFarmName,
          fieldId: unsavedChangesModalProps.targetFieldId,
          fieldName: unsavedChangesModalProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Farm',
        });
        break;
      case 'Field':
        switcher({
          switchTo: 'Field',
          farmId: unsavedChangesModalProps.targetFarmId,
          farmName: unsavedChangesModalProps.targetFarmName,
          fieldId: unsavedChangesModalProps.targetFieldId,
          fieldName: unsavedChangesModalProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Field',
        });
        break;
    }
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
  };

  const handleSaveAndLeave = () => {
    if (type === PAGE_CONTENT.farm) {
      submitCurrentPage().then(() => {
        closeUnsavedChangesModal();
        setUserActivityDetected(false);
        handleLeaveWithoutSave();
        fetchFieldDetails(false).then((fieldDetailsData) => {
          fetchBaselineYears(
            fieldDetailsData,
            PAGE_CONTENT.farm,
            currentFarmId,
          );
        });
      });
    } else if (type === PAGE_CONTENT.crop) {
      closeUnsavedChangesModal();
      setUserActivityDetected(false);

      setFieldSubmitTriggerProps({
        trigger: true,
        navigateTo: unsavedChangesModalProps.navigateNextTo,
        targetFarmId: unsavedChangesModalProps.targetFarmId,
        targetFarmName: unsavedChangesModalProps.targetFarmName,
        targetFieldId: unsavedChangesModalProps.targetFieldId,
        targetFieldName: unsavedChangesModalProps.targetFieldName,
        targetTab: unsavedChangesModalProps.nextTab,
      });
    }
  };

  /*================> Start of in progress handler logic <==================*/

  // Note: This function checks if the user is currently inside POI tab of the given farm, currentTab 0 is ops data and 1 is POI
  const userInsidePOI = (farm) =>
    type === PAGE_CONTENT.farm &&
    farm.farmId === currentFarmId &&
    currentTab === 1;

  /**
   * @descriptionThis function creates an array of pages based on the farm and field data, this array is used to navigate to the next in progress page,
   * @param fieldDetailsData -> response from getParticipantProfile api -> has all farm/field data
   * @returns pages list based on the fieldDetailsData, structure of pages array is a follows:
   * [farm1OpsData, field1, field2, farm1POI, farm2OpsData, field1, field2, farm3OpsData, field1, field2...]
   *
   * Note: POI is added at the end of each farm data if all fields are completed, if all fields are not completed, POI is just ignored and not added to the list. In the example shown above farm2POI is not added assuming all fields under farm2 are not completed.
   * Also POI will be added right after the opsData if the user is actually inside the POI tab of the farm.
   *
   */
  const constructPageList = (fieldDetailsData) => {
    const farmList = fieldDetailsData.farmDetails,
      pageList = [];

    for (const farm of farmList) {
      pageList.push({
        type: PAGE_CONTENT.farm,
        page: autoSaveTargetKeys.farmOperationsData,
        id: farm.farmId,
        name: farm.farmName,
        status: checkTernaryCondition(
          farm.farmOperationsDataStatus === PAGE_CONTENT.completed,
          NAVIGATION_PROPS.STATUS.COMPLETED,
          NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
        ),
        farmId: farm.farmId,
      });

      // Check if the user is inside the POI tab of this farm, if yes, add POI tab right after the opsData
      const isUserInsidePOI = userInsidePOI(farm);
      if (isUserInsidePOI) {
        pageList.push({
          type: PAGE_CONTENT.farm,
          page: autoSaveTargetKeys.proofOfImplementation,
          id: farm.farmId,
          name: farm.farmName,
          // Note: ignorePOI check is added to skip POI when all fields are not completed, if status of POI is set to completed, it will be ignored.
          status: checkTernaryCondition(
            farm.farmProofOfImplementationStatus === PAGE_CONTENT.completed,
            NAVIGATION_PROPS.STATUS.COMPLETED,
            NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
          ),
          farmId: farm.farmId,
        });
      }

      let allFieldsCompleted = true;
      for (const field of farm.fieldDetails) {
        let fieldYears = field?.fieldYearStatus?.sort(
          (a, b) => b.dateId - a.dateId,
        );
        const fieldName = field?.fieldName,
          fieldStatus = checkTernaryCondition(
            fieldYears[0]?.formStatus === PAGE_CONTENT.completed, // Consider only the 1st year since others are baseline years
            NAVIGATION_PROPS.STATUS.COMPLETED,
            NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
          );

        pageList.push({
          type: PAGE_CONTENT.crop,
          page: autoSaveTargetKeys.fieldYear,
          id: field.fieldId,
          name: fieldName,
          status: fieldStatus,
          farmId: farm.farmId,
        });

        if (fieldStatus !== NAVIGATION_PROPS.STATUS.COMPLETED) {
          allFieldsCompleted = false;
        }
      }

      // Add POI tab if all fields are completed and user is not inside POI tab(if inside POI tab, it will be added after the opsData already)
      if (allFieldsCompleted && !isUserInsidePOI) {
        pageList.push({
          type: PAGE_CONTENT.farm,
          page: autoSaveTargetKeys.proofOfImplementation,
          id: farm.farmId,
          name: farm.farmName,
          status: checkTernaryCondition(
            farm.farmProofOfImplementationStatus === PAGE_CONTENT.completed,
            NAVIGATION_PROPS.STATUS.COMPLETED,
            NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
          ),
          farmId: farm.farmId,
        });
      }
    }

    return pageList;
  };

  const getCurrentScreenIndex = (pageList) => {
    if (type === PAGE_CONTENT.farm) {
      const currentPage = checkTernaryCondition(
        currentTab == 0,
        autoSaveTargetKeys.farmOperationsData,
        autoSaveTargetKeys.proofOfImplementation,
      );

      return pageList.findIndex(
        (item) =>
          item.type === PAGE_CONTENT.farm &&
          item.page === currentPage &&
          item.id == currentFarmId,
      );
    } else if (type === PAGE_CONTENT.crop) {
      const currentPage = autoSaveTargetKeys.fieldYear;
      const fieldId = currentField.id;

      return pageList.findIndex(
        (item) =>
          item.type === PAGE_CONTENT.crop &&
          item.page === currentPage &&
          item.farmId == currentFarmId &&
          item.id == fieldId,
      );
    }
  };

  const switchScreen = (pageData) => {
    if (pageData === null) return;

    if (pageData.type === PAGE_CONTENT.farm) {
      setCurrentFarmId(pageData.id);
      setExpandedFarmId(pageData.id);
      setFarmDetails({
        id: pageData.id,
        name: pageData.name,
      });

      const newTab = checkTernaryCondition(
        pageData.page === autoSaveTargetKeys.farmOperationsData,
        0,
        1,
      );
      setCurrentTab(newTab);
      updateReportingStatus(PAGE_CONTENT.farm, pageData.id);
    } else if (pageData.type === PAGE_CONTENT.crop) {
      setCurrentFarmId(pageData.farmId);
      setExpandedFarmId(pageData.farmId);
      setCurrentField({
        id: pageData.id,
        name: pageData.name,
      });
      setCurrentTab(0);
    }
  };

  /**
   *
   * @param fieldData is the response from getParticipantPropfile api -> has all farm/field data
   */
  const gotoNextInprogressPage = (fieldData = fieldDetails) => {
    // Note: If all pages are completed, then open review modal
    if (getActivityReportingStatus(fieldData) === REPORTING_STATUS.COMPLETED) {
      setReportingCompleteModal({ flag: true });
      setTriggerCurrentFieldDataFetch(true);
      fetchBaselineYears(type, currentFarmId);
      return;
    }

    const pageList = constructPageList(fieldData);
    const currentScreenIndex = getCurrentScreenIndex(pageList);
    // Rotate the data to start from the current screen index
    const updatedPageList = rotateData(pageList, currentScreenIndex);

    const nextInProgressPage =
      updatedPageList.find(
        (page, index) =>
          index > 0 && page.status === NAVIGATION_PROPS.STATUS.NOT_COMPLETED,
      ) ?? updatedPageList[1];

    switchScreen(nextInProgressPage);
  };

  /*=============> End of in progress handler logic <==================*/

  //Separate Approved Farm and fields and add into an array
  function filterApprovedActivityData(farmsList) {
    const activityApprovedFarms = [],
      activityApprovedFields = [];
    for (const farm of farmsList) {
      /* istanbul ignore else */
      if (
        farm.activityReportingStatus === activityInstanceStatus.approved &&
        farm.fieldDetails.every(
          (field) =>
            field.activityReportingStatus === activityInstanceStatus.approved,
        )
      ) {
        activityApprovedFarms.push(farm.farmId);
      }
      const fieldsList = farm.fieldDetails;
      for (const field of fieldsList) {
        /* istanbul ignore else */
        if (field.activityReportingStatus === activityInstanceStatus.approved) {
          activityApprovedFields.push(field.fieldId);
        }
      }
    }
    setActivityApprovedFarmIds(activityApprovedFarms);
    setActivityApprovedFieldIds(activityApprovedFields);
  }

  // Logic to handle disabling of farm and fields on creation of Additional instance
  const disableAllFarmActions =
    activityApprovedFarmIds?.includes(currentFarmId);
  const disableAllFieldActions = activityApprovedFieldIds?.includes(
    currentField.id,
  );

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `activity-page-tab-${index}`,
    };
  };
  const fetchTotalFieldArea = () => {
    const apiParams = { fieldId: currentField.id };
    fetchFieldArea(apiParams).then((area) => setTotalFieldArea(area));
  };

  const shouldNavigateToLandingPage = async () => {
    if (!isParticipantContributor) return Promise.resolve(false);
    return isActivitySubmitted({
      participantId,
      projectId,
      activityType: ACTIVITY_TYPES.activity,
      statusMappingList,
    });
  };

  const getOperationsDataTab = (tabData) => {
    const title = checkTernaryCondition(
      type === PAGE_CONTENT.farm,
      tabData?.displayTitle,
      tabData?.yearValue,
    );
    return {
      id: 1,
      title: tabsTitle(title, tabData.status, tabData.phase),
      component: (
        <TabBodyComponent
          isInActivity
          year={tabData?.yearValue}
          type={checkTernaryCondition(
            type === PAGE_CONTENT.farm,
            PAGE_CONTENT.empty_farm_label,
            PAGE_CONTENT.empty_crop_label,
          )}
          fieldType={type}
          sampleId={tabData?.sampleId}
          phase={tabData?.phase}
          setLoading={setLoading}
          fieldId={currentField.id}
          farmId={currentFarmId}
          totalFieldArea={totalFieldArea}
          enrollmentYearData={tabData}
          setFieldDetails={setFieldDetails}
          fetchFieldDetails={fetchFieldDetails}
          setSubmitData={setSubmitData}
          saveDataHandler={saveDataHandler}
          setIsInitialLoad={setIsInitialLoad}
          fetchLastSectionData={fetchLastSectionData}
          setFetchLastSectionData={setFetchLastSectionData}
          setUserActivityDetected={setUserActivityDetected}
          typeOfReporting={'Activity'}
          setActivityReportingMappingModalErrorMessage={
            setActivityReportingMappingModalErrorMessage
          }
          activityReportingMappingModalErrorMessage={
            activityReportingMappingModalErrorMessage
          }
          handleCancel={navigateHandler}
          approvedFarmIds={activityApprovedFarmIds}
          shouldNavigateToLandingPage={shouldNavigateToLandingPage}
          updateReportingStatus={updateReportingStatus}
          isParticipantContributor={isParticipantContributor}
          isEditable={isEditable}
        />
      ),
    };
  };

  const fetchBaselineYears = async (fieldDetailsData, fieldType, id) => {
    if (fieldType === PAGE_CONTENT.farm) {
      const farm = getFarmById(fieldDetailsData?.farmDetails, id);
      const apiParams = { farmId: id, projectCycleId: Number(projectCycleId) };

      fetchBaselineFarmYears(apiParams, 'activity').then((data) => {
        setActivityTabData({
          ...data[0],
          farmOperationsDataStatus: farm?.farmOperationsDataStatus,
          farmProofOfImplementationStatus:
            farm?.farmProofOfImplementationStatus,
        });
        setPoiStatusList(farm.poiTabStatus);
      });
    } else {
      const farm = getFarmById(fieldDetailsData?.farmDetails, currentFarmId);
      if (id === 0) return;
      const apiParams = {
        fieldId: id,
        projectId: projectId,
        projectCycleId: Number(projectCycleId),
      };
      const currentField = farm?.fieldActivityReportingStatusDto.find(
        (field) => field.fieldId === id,
      );

      currentField &&
        (await fetchBaselineFieldYears(apiParams, 'baseline').then((data) =>
          setActivityFieldTabs(data),
        ));
    }
  };

  const fetchFieldDetails = async (isInitial) => {
    const URL = `${FETCH_PARTICIPANT_PROFILE_ACTIVITY}&projectId=${projectId}&participantId=${participantId}`;
    return fetchBaselineFieldDetails(URL).then((data) => {
      const updatedData = {
        ...data,
        farmDetails: getFarmsWithSortedFields(data.farmDetails),
      };

      const farmId = updatedData?.farmDetails[0]?.farmId;
      const farmName = updatedData?.farmDetails[0]?.farmName;
      const farmList = updatedData?.farmDetails;
      filterApprovedActivityData(farmList);
      setFieldDetails(updatedData);
      if (isInitial) {
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
      }

      return updatedData;
    });
  };

  const updateReportingStatus = (targetType, targetId) => {
    fetchFieldDetails(false).then((fieldDetailsValue) => {
      fetchBaselineYears(fieldDetailsValue, targetType, targetId);
    });
  };

  function fetchLifeCycleDates() {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectCycleId,
      );
      if (cycleObject?.length > 0) {
        setProjectCycleData({
          cycleStartDate: moment(cycleObject[0]?.cycleStartDate).format(
            'MM/DD/YYYY',
          ),
          cycleEndDate: moment(cycleObject[0]?.cycleEndDate).format(
            'MM/DD/YYYY',
          ),
        });
      }
    });
  }

  const fetchLockDetailsForSwitch = () => {
    currentFarmId &&
      fetchUserLockDetails(
        currentFarmId,
        projectId,
        participantId,
        ACTIVITY_TYPES.activity.toUpperCase(),
      ).then((data) => {
        const activeUserId = data?.activeUserId;
        setActiveUser(data);
        if (activeUserId === null) {
          setIsSwitchDisabled(false);
        } else {
          setIsSwitchDisabled(activeUserId !== userId);
        }
      });
  };

  useEffect(() => {
    fetchLifeCycleDates();
    addAllEventListener();
    fetchLockDetailsForSwitch();
    statusMappingList.length === 0 &&
      fetchAllUserActions().then((data) => setStatusMappingList(data));
    return () => {
      removeAllEventListeners();
    };
  }, []);

  useInterval(async () => {
    if (!isEditable) {
      fetchLockDetailsForSwitch();
    }
  }, 10000);

  const handleFarmSubmit = ({
    isTabSwitched,
    clickdata,
    parentState,
    sampleId,
    gotoNextInprogressScreen,
  }) => {
    const { yearStatus, fossiFuelFormatted } =
      getFormattedFarmData(parentState);
    const submitFarmData = {
      activityReportingStatus: 'Enrollment In Progress',
      enrolmentType: 'Activity',
      farmSampleId: sampleId,
      farmId: currentFarmId,
      farmOperationsDataStatus: yearStatus,
      projectId: projectId,
      participantId: participantId,
      participantBaselineStatus: 'Enrollment In Progress',
      yearStatus: yearStatus,
      fossilFuels: fossiFuelFormatted,
      projectCycleId: Number(projectCycleId),
    };
    submitBaselineFarm(submitFarmData, type)
      .then(() => {
        setUserActivityDetected(false);
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_msg);
        fetchFieldDetails(false).then((fieldData) => {
          const shouldFetchBaselineYears =
            isTabSwitched ||
            (clickdata.type === 'farm' &&
              clickdata.id === currentFarmId &&
              type === PAGE_CONTENT.farm);
          if (shouldFetchBaselineYears) {
            fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, currentFarmId);
          } else {
            setActivityTabData((prev) => ({
              ...prev,
              type: 'farm',
              farmOperationsDataStatus: checkTernaryCondition(
                fossiFuelFormatted.length > 0,
                'Completed',
                'Not Started',
              ),
            }));
          }

          if (gotoNextInprogressScreen) {
            gotoNextInprogressPage(fieldData);
          }
        });
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
      });
  };

  const saveDataHandler = (
    isTabSwitched = false,
    clickdata = { type: 'none', id: null },
    gotoNextInprogressScreen = false,
  ) => {
    const { sampleId, parentState, editMode } = submitData;
    if (sampleId === null) return;
    if (type === PAGE_CONTENT.farm) {
      handleFarmSubmit({
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
        gotoNextInprogressScreen,
      });
    }
  };
  const navigateHandler = () => {
    const dataCollectionCompleted =
      getActivityReportingStatus(fieldDetails) === REPORTING_STATUS.COMPLETED;
    handleSwitchChange({ target: { checked: false } });
    /* istanbul ignore else */
    if (dataCollectionCompleted || !isParticipantContributor) {
      navigate(
        {
          pathname: pathName.participant.activityMenuPage,
          search: createSearchParams({
            projectid: projectId,
            participantid: participantId,
            projectcycleid: projectCycleId,
          }).toString(),
        },
        { state: { fromNotLanding: true } },
      );
    }
  };

  const updateStatus = (
    currentFieldIdValue,
    newFieldStatus,
    fieldDetailsData,
  ) => {
    const updatedFieldDetails = { ...fieldDetailsData };
    let areAllFarmsCompleted = true;
    for (const farm of updatedFieldDetails.farmDetails) {
      for (const field of farm.fieldDetails) {
        if (field.fieldId === currentFieldIdValue) {
          field.fieldStatus = newFieldStatus;
        }
        if (field.fieldStatus !== 'Completed') {
          areAllFarmsCompleted = false;
        }
      }
      farm.farmStatus = areAllFarmsCompleted ? 'complete' : 'in progress';
      areAllFarmsCompleted = true;
    }
    setFieldDetails(updatedFieldDetails);
  };

  const updateFieldLevelStatus = () => {
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              farmActivityReportingStatus: REPORTING_STATUS.IN_PROGRESS,
              fieldActivityReportingStatusDto:
                farm.fieldActivityReportingStatusDto.map((field) => {
                  return checkTernaryCondition(
                    field.fieldId === currentField.id,
                    {
                      ...field,
                      fieldActivityReportingStatus:
                        REPORTING_STATUS.IN_PROGRESS,
                    },
                    field,
                  );
                }),
            },
            farm,
          );
        }),
      };
    });
  };

  const updateFarmLevelStatus = () => {
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              farmActivityReportingStatus: REPORTING_STATUS.IN_PROGRESS,
            },
            farm,
          );
        }),
      };
    });
  };

  function handleNextInProgressFromField() {
    const URL = `${PARTICIPANT_PROFILE}?enrollmentType=ACTIVITY&projectId=${projectId}&participantId=${participantId}`;
    fetchBaselineFieldDetails(URL).then((data) => {
      const updatedData = {
        ...data,
        farmDetails: getFarmsWithSortedFields(data.farmDetails),
      };
      gotoNextInprogressPage(updatedData);
      const farmList = updatedData?.farmDetails;
      const baselineStatus = getStatus(farmList);
      updateStatus(currentField.id, baselineStatus, updatedData);
    });
  }

  const proofOfImplementationTabs = [
    {
      id: 2,
      title: tabsTitle(
        PAGE_CONTENT.proof_of_implementation,
        activityTabData?.farmProofOfImplementationStatus,
        PAGE_CONTENT.project,
      ),
      isDisabled: false,
      component: (
        <ProofOfImplementationMainComp
          farmId={currentFarmId}
          setActivityTabData={setActivityTabData}
          fetchFieldDetails={fetchFieldDetails}
          poiStatusList={poiStatusList}
          updateFarmLevelStatus={updateFarmLevelStatus}
          currentTab={poiCurrentTab}
          setCurrentTab={setPoiCurrentTab}
          projectActivities={poiActivities}
          setProjectActivities={setPoiActivities}
          currentTabData={poiData}
          setCurrentTabData={setPoiData}
          currentTabStatus={poiCurrentTabStatus}
          setCurrentTabStatus={setPoiCurrentTabStatus}
          updateReportingStatus={updateReportingStatus}
          gotoNextInprogressPage={gotoNextInprogressPage}
          farmProofOfImplementationStatus={
            activityTabData?.farmProofOfImplementationStatus
          }
          setUserActivityDetected={setUserActivityDetected}
          userActivityDetected={userActivityDetected}
          setAnnualImplementationCost={setAnnualImplementationCost}
          annualImplementationCost={annualImplementationCost}
          setReportingCompleteModal={setReportingCompleteModal}
          disableAllFarmActions={disableAllFarmActions}
          activityApprovedFields={activityApprovedFieldIds}
          shouldNavigateToLandingPage={shouldNavigateToLandingPage}
          navigateHandler={navigateHandler}
          isParticipantContributor={isParticipantContributor}
          isEditable={isEditable}
        />
      ),
    },
  ];

  const farmActivityTabs = [
    getOperationsDataTab({
      ...activityTabData,
      status: activityTabData?.farmOperationsDataStatus,
    }),
    ...proofOfImplementationTabs,
  ];
  const fieldActivityTabs = useMemo(() => {
    return activityFieldTabs.map((tab, index) => ({
      title: tabsTitle(tab?.yearValue, tab?.status, tab?.phase),
      component: (
        <TabBodyComponentV2
          navigateHandler={navigateHandler}
          allExpanded={allExpanded}
          currentYearData={tab}
          currentFarmId={currentFarmId}
          currentFieldId={currentField.id}
          setEnrollmentYears={setActivityFieldTabs}
          setFieldDetails={setFieldDetails}
          refreshStatusHandler={() =>
            updateReportingStatus('field', currentField.id)
          }
          gotoNextInprogressPage={handleNextInProgressFromField}
          setUserActivityDetected={setUserActivityDetected}
          disableAllFieldActions={disableAllFieldActions || index > 0}
          shouldNavigateToLandingPage={shouldNavigateToLandingPage}
          fetchBaselineYears={fetchBaselineYears}
          updateReportingStatus={updateReportingStatus}
          isParticipantContributor={isParticipantContributor}
          isEditable={isEditable}
        />
      ),
    }));
  }, [activityFieldTabs, currentField, currentFarmId, allExpanded, isEditable]);

  const getActivityTabs = () => {
    if (type === PAGE_CONTENT.farm) {
      return farmActivityTabs;
    }
    return fieldActivityTabs;
  };

  const fetchProjectDetails = () => {
    const apiPath = `/${projectId}`;
    const errorHandler = () => setProjectDetails({ projectName: '' });
    fetchProjectData(apiPath, errorHandler).then((projectData) =>
      setProjectDetails(projectData),
    );
  };

  const updateCurrentTab = (newTab) => setCurrentTab(newTab);

  useEffect(() => {
    fetchFieldDetails(true);
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    if (currentField?.id > 0) {
      setType(PAGE_CONTENT.crop);
      fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, currentField.id);
      fetchTotalFieldArea();
    }
  }, [currentField]);
  useEffect(() => {
    if (farmDetails) {
      setType(PAGE_CONTENT.farm);
      fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmDetails.id);
    }
  }, [farmDetails]);

  useEffect(() => {
    if (activityTabData && !isInitialLoad) {
      let updatedStatusData = {};
      if (type === PAGE_CONTENT.farm) {
        updatedStatusData = {
          farmOperationsDataStatus: checkTernaryCondition(
            submitData.parentState.length > 0,
            'In Progress',
            'Not Started',
          ),
        };
      } else {
        updatedStatusData = {
          fieldOperationsDataStatus: checkTernaryCondition(
            submitData.parentState.length > 0 ||
              submitData.practiceModalsData.edgeOfFieldList.length > 0,
            'In Progress',
            'Not Started',
          ),
        };
      }
      setActivityTabData((prev) => ({
        ...prev,
        ...updatedStatusData,
      }));
    }
    if (type === PAGE_CONTENT.farm && !isInitialLoad) {
      updateFarmLevelStatus();
    }
    if (type === PAGE_CONTENT.crop && !isInitialLoad) {
      updateFieldLevelStatus();
    }
  }, [submitData]);

  useEffect(() => {
    let finalData = [];
    fieldDetails?.farmDetails?.map((farm) =>
      finalData.push(farm?.farmActivityReportingStatus),
    );
    if (finalData.every((element) => element === PAGE_CONTENT.completed)) {
      setActivityStatusList(PAGE_CONTENT.completed);
    } else {
      setActivityStatusList(PAGE_CONTENT.in_progress);
    }
  }, [fieldDetails]);

  /**============> Start of autosave logic <============== **/

  /**
   * This function is used to identify the current page in which the user is in, using this data w.r.t current page is saved
   * @returns {string} representing the name of the current page based on the type prop and current tab value(page names are stored in autoSaveTargetKeys constant)
   */
  const getCurrentPage = () => {
    if (type === PAGE_CONTENT.farm && currentTab === 0)
      return autoSaveTargetKeys.farmOperationsData;
    if (type === PAGE_CONTENT.farm && currentTab === 1)
      return autoSaveTargetKeys.proofOfImplementation;
    if (type !== PAGE_CONTENT.farm && currentTab === 0)
      return autoSaveTargetKeys.fieldImplementedActivities;
    if (type !== PAGE_CONTENT.farm && currentTab === 1)
      return autoSaveTargetKeys.fieldOperationsData;
  };

  /**
   *
   * This function submits the current page and is used for the purpose of autosave feature, whenever user clicks on any other field/farm/tab, current page data is saved using this function, post which navigation to the target place is carried out.
   * @returns {Promise} -> this async promise is used by autosave function, on resolve of this Promise navigation to target place happens.
   */
  const submitCurrentPage = () => {
    const currentPage = getCurrentPage();

    switch (currentPage) {
      case autoSaveTargetKeys.farmOperationsData: {
        const apiBody = getOperationsDataBody(
          submitData.parentState,
          activityTabData?.sampleId,
          currentFarmId,
          projectId,
          participantId,
          projectCycleId,
        );

        return submitBaselineFarm(apiBody);
      }

      case autoSaveTargetKeys.proofOfImplementation: {
        return submitPoiTabData({
          projectActivities: poiActivities,
          currentTab: poiCurrentTab,
          currentTabData: poiData,
          projectId: projectId,
          farmId: currentFarmId,
          currentTabStatus: poiCurrentTabStatus,
          participantId: participantId,
          annualImplementationCost: annualImplementationCost,
        });
      }

      case autoSaveTargetKeys.fieldImplementedActivities: {
        return Promise.resolve();
      }

      case autoSaveTargetKeys.fieldOperationsData: {
        return Promise.resolve();
      }

      default:
        break;
    }
  };

  /**
   *
   * @param {string} targetPlace - This is the destination place to navigate after saving current page data.
   * @param {number} targetId - This is the id of the field/farm to navigate to.
   * @param {string} targetName - This is the name of the field/farm to navigate to.
   */

  // Note: This is called when user switches tab inside farm/field
  const handleTabChange = (_, selectedTab) => {
    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        isOpen: true,
        nextTab: selectedTab,
        targetFarmId: currentFarmId,
        targetFieldId: currentField.id,
        navigateNextTo: 'Tab',
      });
    } else {
      const currentId =
        type === PAGE_CONTENT.farm ? currentFarmId : currentField.id;
      fetchBaselineYears(type, currentId);
      updateCurrentTab(selectedTab);
    }
  };

  // Note: This is called on clicking field name from left menu
  const handleAccordianItemClick = ({
    targetId: fieldId,
    targetName: fieldName,
    targetFarmId: farmId,
    targetFarmName: farmName,
  }) => {
    if (currentField.id === fieldId && type === PAGE_CONTENT.crop) return;
    if (currentFarmId !== farmId) {
      handleSwitchChange({ target: { checked: false } });
    }
    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        isOpen: true,
        nextTab: 0,
        targetFarmId: farmId,
        targetFarmName: farmName,
        targetFieldId: fieldId,
        targetFieldName: fieldName,
        navigateNextTo: 'Field',
      });
    } else {
      setCurrentTab(0);
      setCurrentField({ id: fieldId, name: fieldName });
      setCurrentFarmId(farmId);
      setExpandedFarmId(farmId);
    }
  };

  // Note: This is called on clicking farm name from left menu
  const handleAccordianSummaryClick = ({
    targetId: farmId,
    targetName: farmName,
  }) => {
    if (currentFarmId === farmId && type === PAGE_CONTENT.farm) return;
    if (currentFarmId !== farmId) {
      handleSwitchChange({ target: { checked: false } });
    }
    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        isOpen: true,
        nextTab: 0,
        targetFarmId: farmId,
        targetFarmName: farmName,
        targetFieldId: null,
        targetFieldName: null,
        navigateNextTo: 'Farm',
      });
    } else {
      setFarmDetails({
        id: farmId,
        name: farmName,
      });
      setCurrentFarmId(farmId);
      setExpandedFarmId(farmId);
      setCurrentTab(0);
      // reset field data
      setCurrentField(FIELD_DATA_INIT);
    }
  };

  /**===============> End of autosave logic <=============== **/

  // Note: This function will be called once field data is save from context and we navigate to the target place from here based on the fieldSubmitTriggerProps.
  const handleSubmitCallback = () => {
    // Navigate to appropriate place based on trigger submit props
    switch (fieldSubmitTriggerProps.navigateTo) {
      case 'Tab':
        setCurrentTab(fieldSubmitTriggerProps.targetTab);
        fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, currentField.id);
        break;
      case 'Field':
        switcher({
          switchTo: 'Field',
          farmId: fieldSubmitTriggerProps.targetFarmId,
          farmName: fieldSubmitTriggerProps.targetFarmName,
          fieldId: fieldSubmitTriggerProps.targetFieldId,
          fieldName: fieldSubmitTriggerProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Field',
        });
        break;
      case 'Farm':
        switcher({
          switchTo: 'Farm',
          farmId: fieldSubmitTriggerProps.targetFarmId,
          farmName: fieldSubmitTriggerProps.targetFarmName,
          fieldId: fieldSubmitTriggerProps.targetFieldId,
          fieldName: fieldSubmitTriggerProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Farm',
        });
        break;
    }
    setUnsavedChangesModalProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);
    setFieldSubmitTriggerProps(FIELD_SUBMIT_TRIGGER_INIT);
    fetchFieldDetails(false);
  };

  const getActivityReportingStatus = (fieldData) => {
    const farmList = fieldData?.farmDetails;
    if (!farmList) return 'Not Started';

    for (let farm of farmList) {
      if (farm.farmActivityReportingStatus !== 'Completed') {
        return 'In Progress';
      }
    }

    return 'Completed';
  };

  const getFieldsList = () => {
    return fieldDetails?.farmDetails?.map((data) => {
      let fieldList = [];
      if (data.fieldActivityReportingStatusDto) {
        fieldList = sortWithAKeyInSpecificOrder(
          data.fieldActivityReportingStatusDto,
          'fieldId',
          data.fieldDetails.map((field) => field.fieldId),
        ).map((item) => ({
          value: data.fieldDetails.find(
            (field) => field.fieldId === item.fieldId,
          )?.fieldName,
          status: item.fieldActivityReportingStatus,
          id: item.fieldId,
        }));
      }

      return {
        id: data.farmId,
        status: data.farmActivityReportingStatus,
        value: data.farmName,
        fields: fieldList,
      };
    });
  };

  const year = activityFieldTabs?.[currentTab]?.yearValue;

  /**
   * @description function to update the form edit switch state
   * @param {Event} event
   */
  const handleSwitchChange = (event) => {
    const switchSelected = event.target.checked;
    setIsEditable(switchSelected);
    setUserActivityDetected(false);
    setLoading(true);
    if (currentFarmId > 0) {
      axios
        .put(UPDATE_FARM_STATUS, {
          farmId: currentFarmId,
          projectId: projectId,
          participantId: participantId,
          lockStatus: switchSelected,
          enrolmentType: ACTIVITY_TYPES.activity,
        })
        .then(() => {
          setIsEditable(switchSelected);
        })
        .catch((err) => {
          const customErrorMsg = err?.response?.data?.errorCodeMsg;
          setIsEditable(false);

          if (!isEmpty(customErrorMsg)) {
            toast(
              <CustomSnackbar
                type="error"
                message={err?.response?.data?.errorCodeMsg}
              />,
              toastStyle,
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <MainWrapper>
      <ProjectNameWrapper style={projectNameStyle}>
        <ProjectName>{projectDetails?.projectName}</ProjectName>
        <ProjectName>{` - ${projectCycleData?.cycleEndDate.slice(
          6,
        )}`}</ProjectName>
        <ProjectName>{` (${projectCycleData?.cycleStartDate}`}</ProjectName>
        <ProjectName>{` - ${projectCycleData?.cycleEndDate})`}</ProjectName>
      </ProjectNameWrapper>
      <Divider style={DividerStyles} />
      <ParentContainer>
        {!isInTesting && (
          <Backdrop
            sx={{
              backgroundColor: WHITE,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={
              loading ||
              activityTabData === null ||
              apiLoading ||
              (!isInTesting && loaderCounter > 0)
            }>
            <CircularProgress color="success" />
          </Backdrop>
        )}
        <LeftSectionWrapper>
          <LeftMenuSubsection
            heading={PAGE_CONTENT.leftSectionHeading}
            fieldData={getFieldsList()}
            showFields={false}
            setCurrentFieldId={setCurrentField}
            isBaselineFlow={true}
            currentFarmId={currentFarmId}
            expandedFarmId={expandedFarmId}
            setExpandedFarmId={setExpandedFarmId}
            selectedFieldId={currentField}
            isInBaselinePage
            isInsideActivityReporting
            isFarmSelected={type === PAGE_CONTENT.farm}
            handleAccordianItemClick={handleAccordianItemClick}
            handleAccordianSummaryClick={handleAccordianSummaryClick}
          />
        </LeftSectionWrapper>
        <div style={columnFlexStyle}>
          <MainContainer>
            <HeaderContainer style={{ padding: '1.5rem 0 1rem 1.5rem' }}>
              <TextWrapper
                fontSize="1rem"
                fontWeight="600"
                color={DARK_CHARCOAL}>
                {checkTernaryCondition(
                  type === PAGE_CONTENT.farm,
                  <>{farmDetails?.name}</>,
                  <>{`${currentFarmData?.farmName} - ${currentField?.name}`}</>,
                )}
                <SwitchContainer>
                  <Typography sx={switchLabelStyle}>
                    {checkTernaryCondition(
                      isEditable,
                      PAGE_CONTENT.FORM_UNLOCKED,
                      PAGE_CONTENT.FORM_LOCKED,
                    )}
                  </Typography>
                  <LightTooltip
                    title={getFarmLockedTooltip(
                      isSwitchDisabled,
                      userRoles?.includes(ADMIN) ||
                        userRoles?.includes(ORIGINATION_MANAGER),
                      activeUser.name,
                    )}
                    arrow>
                    <div>
                      <SwitchComponent
                        handleChange={handleSwitchChange}
                        name="farm-lock"
                        isChecked={isEditable}
                        stylesSx={swithStyle}
                        disabled={isSwitchDisabled}
                      />
                    </div>
                  </LightTooltip>
                </SwitchContainer>
              </TextWrapper>
              {type !== PAGE_CONTENT.farm && (
                <HeaderButtonContainer>
                  <Button
                    onClick={() => {
                      updateAllExpandedState(
                        year,
                        setAllExpanded,
                        currentField,
                        false,
                      );
                    }}
                    variant="outlined"
                    size="medium"
                    sx={{
                      ...CollapseExpandButtonSx,
                    }}>
                    {PAGE_CONTENT.collapseButton}
                  </Button>
                  <Button
                    onClick={() => {
                      updateAllExpandedState(
                        year,
                        setAllExpanded,
                        currentField,
                        true,
                      );
                    }}
                    variant="outlined"
                    sx={{
                      ...CollapseExpandButtonSx,
                    }}>
                    {PAGE_CONTENT.expandAllButton}
                  </Button>
                </HeaderButtonContainer>
              )}
            </HeaderContainer>

            <Box
              sx={{
                ...flexColumn,
                ...yAxisAutoScroll,
              }}>
              <TabberBox
                style={{
                  ...TabberBoxCustomStyle,
                  padding: '0rem 0 0rem 1.5rem',
                }}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="page-tabs">
                  {getActivityTabs()?.map((tab, index) => {
                    const key = `Tab title ${index}`;
                    return (
                      <Tab
                        disabled={tab?.isDisabled}
                        sx={TabStylesSx}
                        className={classNames({
                          'active-tab': currentTab === index,
                        })}
                        key={key}
                        label={tab?.title}
                        {...tabProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </TabberBox>
              <ImplementedActivitiesWithOpsDataProvider
                currentFieldId={currentField.id}
                currentFarmId={currentFarmId}
                triggerFieldDataSubmit={fieldSubmitTriggerProps?.trigger}
                dataSubmitCallback={handleSubmitCallback}
                triggerCurrentFieldDataFetch={triggerCurrentFieldDataFetch}
                setTriggerCurrentFieldDataFetch={
                  setTriggerCurrentFieldDataFetch
                }>
                {getActivityTabs()?.map((tab, index) => {
                  const key = `Tab body ${index}`;
                  return (
                    <TabPanel
                      key={key}
                      value={currentTab}
                      index={index}
                      style={{ maxHeight: '40rem', overflow: 'auto' }}
                      extraClassName="styled-scrollbar participant-flow">
                      <TabComponentWrapper>{tab.component}</TabComponentWrapper>
                      <ParticipantFlowFooterWrapper
                        style={{
                          ...footerStyle,
                        }}>
                        <Footer
                          isBaselineFlow={true}
                          isParticipantMenu={
                            location.pathname === pathName.participant.menuPage
                          }
                          isInActivityPage={
                            location.pathname ===
                              pathName.participant.participantFieldActivity ||
                            location.pathname ===
                              pathName.participant.participantDataCollection ||
                            location.pathname ===
                              pathName.participant.participantFieldBaseline
                          }
                        />
                      </ParticipantFlowFooterWrapper>
                    </TabPanel>
                  );
                })}
              </ImplementedActivitiesWithOpsDataProvider>
            </Box>
          </MainContainer>
        </div>
      </ParentContainer>
      <EnrollmentCompleteModal
        modalData={reportingCompleteModal}
        modalCloseHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
        title={PAGE_CONTENT.submit_complete_modal_title}
        contentText={PAGE_CONTENT.submit_complete_modal_content}
        firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
        secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
        cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
        firstSubmitBtnHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
        secondSubmitBtnHandler={navigateHandler}
        cancelHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
      />

      <UnsavedChangesModal
        open={unsavedChangesModalProps?.isOpen}
        handleClose={closeUnsavedChangesModal}
        handleSaveAndLeave={async () => {
          const shouldNavigate = await shouldNavigateToLandingPage();
          const { targetFarmId } = unsavedChangesModalProps;
          if (shouldNavigate) {
            navigate(pathName.participant.landingPage);
            return;
          }
          handleSaveAndLeave();
          if (targetFarmId !== currentFarmId) {
            handleSwitchChange({ target: { checked: false } });
          }
        }}
        handleLeaveWithoutSave={async () => {
          const { targetFarmId } = unsavedChangesModalProps;
          const shouldNavigate = await shouldNavigateToLandingPage();
          if (shouldNavigate) {
            navigate(pathName.participant.landingPage);
            return;
          }
          handleLeaveWithoutSave();
          if (targetFarmId !== currentFarmId) {
            handleSwitchChange({ target: { checked: false } });
          }
        }}
      />
    </MainWrapper>
  );
};

ActivityReporting.propTypes = {
  isInTesting: PropTypes.bool,
};

export default ActivityReporting;
