import { Box, Paper, Stack, Typography } from '@mui/material';
import { TitleContainer } from '../CustomBarChart/CustomBarChart.style';
import {
  BottomLegendsContainer,
  CurrentActualWrapper,
  LegendBox,
  LegendBoxWrapper,
  LegendText,
  LegendWrapper,
  noDataLabelSx,
  TextWrapper,
  TitleCurrentValueWrapper,
} from '../CustomLineChart/CustomLineChart.style';
import {
  ChartsXAxis,
  ChartsYAxis,
  ChartsTooltip,
  ChartsGrid,
  BarChart,
  BarPlot,
  LinePlot,
  lineElementClasses,
  ResponsiveChartContainer,
  axisClasses,
} from '@mui/x-charts';
import {
  checkTernaryCondition,
  generateTickIntervals,
  isEmpty,
} from 'utils/helper';
import PropTypes from 'prop-types';
import {
  BLACK,
  COOL_GRAY,
  DARK_BLACK_60_PERCENT,
  DARK_CHARCOAL,
  PROJECTED_DASHED_GRAY,
  QUILL_GRAY,
  WHITE,
  X_AXIS_LINE_GRAY,
} from 'theme/GlobalColors';
import { NO_DATA_VALUE } from '../CustomLineChart/CustomLineChart.content';
import { MODAL_CONTENT } from './MuiBarChart.content';

const STEP_SIZE_COUNT = 4;

export const renderTooltipContent = ({ series, dataIndex }) => {
  const seriesData = series
    ?.filter(({ type }) => type === 'bar')
    ?.map(({ label, data }) => {
      return (
        <Box
          key={label}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          py={1}
          px={2}
          gap={2}>
          <Typography>{label}</Typography>
          <Typography>{data[dataIndex] || 0}</Typography>
        </Box>
      );
    });
  return <Paper sx={{ backgroundColor: WHITE }}>{seriesData}</Paper>;
};

const MuiBarChart = ({
  options,
  isDataSetEmpty = false,
  currentValue = {},
  data = {},
  title = '',
  hideLegends = true,
  style = {},
  layout = 'vertical',
  showLeftAxis = true,
  isComparisonChart = false,
  showTooltip = true,
}) => {
  const maxDataValue = Math.max(
    ...data.datasets.flatMap((dataset) => dataset.data),
  );
  const stepSize = Math.ceil(maxDataValue / STEP_SIZE_COUNT);

  const barDatasetsWithColor = data.datasets?.map((dataset) => ({
    ...dataset,
    color: dataset?.backgroundColor,
    type: MODAL_CONTENT.BAR,
  }));

  const projectedLineData = data.datasets?.find(
    (dataset) => dataset?.label === MODAL_CONTENT.PROJECTED,
  );

  const lineDatasetsWithColor = checkTernaryCondition(
    !isEmpty(projectedLineData),
    {
      ...projectedLineData,
      color: projectedLineData?.backgroundColor,
      type: MODAL_CONTENT.LINE,
    },
    null,
  );

  const datasets = checkTernaryCondition(
    lineDatasetsWithColor,
    [...barDatasetsWithColor, lineDatasetsWithColor],
    barDatasetsWithColor,
  );

  const barBackgroundColors = data.datasets?.map((dataset) =>
    checkTernaryCondition(
      Array.isArray(dataset?.backgroundColor),
      dataset?.backgroundColor,
      [dataset.backgroundColor],
    ),
  );

  const xAxisConfig = {
    data: data.labels,
    scaleType: MODAL_CONTENT.BAND,
    id: 'x-axis-id',
    ...checkTernaryCondition(
      isComparisonChart,
      {},
      {
        colorMap: {
          type: MODAL_CONTENT.ORDINAL,
          values: data.labels,
          colors: barBackgroundColors[0] || BLACK,
        },
      },
    ),
    tickSize: 0,
  };
  const yAxisConfig = {
    label: options?.scales.y?.title?.text,
    scaleType: MODAL_CONTENT.LINEAR,
    id: 'y-axis-id',
  };

  return (
    <Stack direction="column" height="100%" width="100%" position="relative">
      <TitleCurrentValueWrapper>
        {!isEmpty(title) && <TitleContainer>{title}</TitleContainer>}
        <CurrentActualWrapper color={currentValue.color}>
          <TextWrapper>{currentValue.value}</TextWrapper>
          <TextWrapper
            fontSize="0.688rem"
            fontWeight={400}
            lineHeight="0.936rem">
            {currentValue.label}
          </TextWrapper>
        </CurrentActualWrapper>
      </TitleCurrentValueWrapper>
      {isDataSetEmpty && isComparisonChart && (
        <Typography sx={noDataLabelSx}>{NO_DATA_VALUE}</Typography>
      )}
      {!isComparisonChart ? (
        <BarChart
          margin={{
            left: 0,
            right: 0,
            ...checkTernaryCondition(isDataSetEmpty, {}, { top: 0 }),
            bottom: style?.bottom,
          }}
          slotProps={{
            legend: {
              hidden: hideLegends,
              labelStyle: { fontSize: 12 },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 15,
            },
            popper: {
              sx: {
                ['& .MuiChartsTooltip-mark']: { display: 'none' },
              },
            },
            bar: {
              rx: 2,
              ry: 2,
            },
          }}
          sx={{
            ...style,
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-10px, 0)',
            },
            '& .MuiChartsAxis-tickLabel>tspan': {
              fill: DARK_CHARCOAL,
              color: DARK_CHARCOAL,
            },
            '& .MuiBarLabel-root': {
              fill: WHITE,
            },
            '&& .MuiChartsAxis-line': {
              stroke: QUILL_GRAY,
            },
          }}
          layout={layout}
          series={data.datasets}
          axisHighlight={{ x: 'none' }}
          leftAxis={checkTernaryCondition(showLeftAxis, undefined, null)}
          tooltip={{
            trigger: checkTernaryCondition(showTooltip, 'item', 'none'),
          }}
          barLabel={({ value }) => {
            return checkTernaryCondition(+value === 0.0001, 0, value);
          }}
          xAxis={[
            checkTernaryCondition(
              layout === MODAL_CONTENT.VERTICAL,
              xAxisConfig,
              yAxisConfig,
            ),
          ]}
          yAxis={[
            checkTernaryCondition(
              layout === MODAL_CONTENT.VERTICAL,
              yAxisConfig,
              xAxisConfig,
            ),
          ]}
        />
      ) : (
        <ResponsiveChartContainer
          sx={{
            ...style,
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-13px, 0)',
              fill: COOL_GRAY,
            },
            [`& .${lineElementClasses.root}`]: {
              stroke: PROJECTED_DASHED_GRAY,
              strokeWidth: 3,
              strokeDasharray: projectedLineData?.borderDash,
            },
            '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
              stroke: X_AXIS_LINE_GRAY,
              strokeWidth: 2,
            },
          }}
          layout={layout}
          series={datasets}
          margin={{ bottom: style?.bottom, top: 5 }}
          xAxis={[
            checkTernaryCondition(
              layout === MODAL_CONTENT.VERTICAL,
              xAxisConfig,
              yAxisConfig,
            ),
          ]}
          yAxis={[
            checkTernaryCondition(
              layout === MODAL_CONTENT.VERTICAL,
              {
                ...yAxisConfig,
                max: maxDataValue,
                tickInterval: generateTickIntervals(stepSize),
              },
              xAxisConfig,
            ),
          ]}>
          <ChartsGrid horizontal />
          <LinePlot />
          <BarPlot />
          {!isDataSetEmpty && (
            <ChartsTooltip
              slots={{ axisContent: renderTooltipContent }}
              trigger={checkTernaryCondition(showTooltip, 'axis', 'none')}
            />
          )}
          <ChartsXAxis
            label=""
            position="bottom"
            axisId="x-axis-id"
            disableTicks
            tickLabelStyle={{
              fill: DARK_BLACK_60_PERCENT,
            }}
          />
          <ChartsYAxis
            label={options.scales.y?.title?.text}
            position="left"
            axisId="y-axis-id"
            disableLine
            disableTicks
            tickLabelStyle={{
              fill: DARK_BLACK_60_PERCENT,
            }}
          />
        </ResponsiveChartContainer>
      )}
      {!hideLegends && (
        <BottomLegendsContainer>
          {data?.datasets?.map((dataset) => (
            <LegendWrapper key={dataset?.label}>
              <LegendBoxWrapper>
                <LegendBox backgroundColor={dataset?.borderColor} />
              </LegendBoxWrapper>
              <LegendText>{dataset?.label}</LegendText>
            </LegendWrapper>
          ))}
        </BottomLegendsContainer>
      )}
    </Stack>
  );
};
MuiBarChart.propTypes = {
  options: PropTypes.object,
  data: PropTypes.object,
  currentValue: PropTypes.object,
  title: PropTypes.string,
  hideLegends: PropTypes.bool,
  style: PropTypes.object,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  showLeftAxis: PropTypes.bool,
  isComparisonChart: PropTypes.bool,
  isDataSetEmpty: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

export default MuiBarChart;
