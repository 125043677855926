import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ParticipantActivityContext } from 'contextAPI/participantActivityContext';
import axios from 'axios';

import {
  GENERATE_BASELINE_INSTANCE_ID,
  SEND_ACTIVITY_EMAIL,
  SEND_BASELINE_REQUEST_EMAIL,
  UPDATE_BASELINE_ACTVITY_STATUS,
} from 'urls';
import {
  INITIATE_ACTIVITY_REPORTING_INITIALSTATE,
  LABELS,
  baselineModalPropsInit,
  displayToast,
  getActionCodeFromActionId,
  getActionIdFromActionCode,
  initialReviewEnrollmentProp,
} from 'pages/Origination/Org_Project/Participants/Participants.content';
import { ContractActivityAction } from 'components/SimpleOptionsButton/ActivityOptions/ContractActivity.react';
import { EnrollmentActivityAction } from 'components/SimpleOptionsButton/ActivityOptions/EnrollmentActivity.react';
import { BaselineActivityAction } from 'components/SimpleOptionsButton/ActivityOptions/BaselineActivity.react';
import { ActivityOptionsButton } from 'components/SimpleOptionsButton/ActivityOptions/ActivityOptionsButton.react';
import { ActivityReportingAction } from 'components/SimpleOptionsButton/ActivityOptions/ActivityReporting.react';
import {
  ACTIVITY_SUBTYPE,
  ACTIVITY_TYPE,
} from 'components/SimpleOptionsButton/ActivityOptions/ActivityOptions.constants';
import { REPORTING_STATUS } from 'components/SimpleOptionsButton/ActivityMenuItem/ActivityMenuItem.constant';
import { PipelineAcerage } from 'components/SimpleOptionsButton/ActivityOptions/AddPipelineAcerage.react';
import { EditPipelineAcerage } from 'components/SimpleOptionsButton/ActivityOptions/EditPipelineAcerage.react';
import { INACTIVE_EMAIL_TEMLATE_TEXT } from 'utils/config';

// Create a context
export const ActivityContext = createContext();

export const useActivityContext = () => useContext(ActivityContext);

export const ActivityListContainer = React.memo(function ActivityContainer({
  setShouldNotRefresh,
  activity,
  status,
  activityType,
  activityDataList,
  statusMappingList,
  activitySubType,
  projectCycleId,
  area,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    participantId,
    projectId,
    participantName,
    projectName,
    activityData,
  } = useContext(ParticipantActivityContext);
  const [selectedBaselineOption, setSelectedBaselineOption] = useState(null);
  const [selectedActivityOption, setSelectedActivityOption] = useState(null);

  const [reviewEnrollmentProps, setReviewEnrollmentProps] = useState({
    ...initialReviewEnrollmentProp,
    projectId,
    participantId,
    projectCycleId: projectCycleId,
  });

  const [baselineModalProps, setBaselineModalProps] = useState(
    baselineModalPropsInit,
  );
  const [reopenProps, setReopenProps] = useState({
    ...initialReviewEnrollmentProp,
    projectCycleId: projectCycleId,
  });

  const [loading, setLoading] = useState(false);

  const [
    initiateActivityReportingModalData,
    setInitiateActivityReportingModalData,
  ] = useState(INITIATE_ACTIVITY_REPORTING_INITIALSTATE);

  const updateParticipantBaselineStatus = ({
    formId,
    actionId,
    enrolmentType,
    projectCycleId,
  }) => {
    return axios.post(UPDATE_BASELINE_ACTVITY_STATUS, {
      formId: +formId,
      actionId: +actionId,
      enrolmentType,
      projectCycleId,
    });
  };

  const generateBaselineId = (enrolmentType, projectCycleId) => {
    return axios.post(GENERATE_BASELINE_INSTANCE_ID, {
      internalProjectId: +projectId,
      participantId: +participantId,
      enrolmentType,
      projectCycleId,
    });
  };

  const [enrollModalData, setEnrollModalData] = useState({
    actionCode: 'ENROLL-DEFAULT',
    flag: false,
    id: 0,
    projectData: [],
    projectId,
    participantName,
    instanceId: -1,
    shouldGenerateNewInstance: false,
    projectCycleId: projectCycleId,
  });

  const [addContractOpen, setAddContractOpen] = useState({
    isOpen: false,
    participantId,
    projectId,
    editMode: false,
  });

  const [editPipelineAcresOpen, setEditPipelineAcresOpen] = useState({
    isOpen: false,
    participantId,
    projectId,
    editMode: false,
  });

  const [editContractOpen, setEditContractOpen] = useState({
    isOpen: false,
    participantId,
    projectId,
  });

  const [addPipelineAcresOpen, setAddPipelineAcresOpen] = useState({
    isOpen: false,
    participantId,
    projectId,
  });

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleBaselineSendRequest = (projectId, participantId) => {
    const body = {
      internalProjectId: projectId,
      participantId: participantId,
      projectCycleId: projectCycleId,
    };

    const updateStatusAndReset = (formId, actionCode) => {
      updateParticipantBaselineStatus({
        formId,
        actionId: getActionIdFromActionCode(actionCode, statusMappingList),
        enrolmentType: 'Baseline',
        projectCycleId,
      }).finally(() => {
        setLoading(false);
        setBaselineModalProps(baselineModalPropsInit);
      });
    };

    const handleFormId = (
      formId,
      previousBaselineActionCode = 'BASELINE_DEFAULT',
    ) => {
      switch (selectedBaselineOption) {
        case 'Report on behalf':
          updateStatusAndReset(formId, previousBaselineActionCode);
          break;
        case 'Send request':
          setLoading(true);
          axios
            .post(SEND_BASELINE_REQUEST_EMAIL, body)
            .then((response) => {
              response?.data?.message !== INACTIVE_EMAIL_TEMLATE_TEXT &&
                displayToast('success', LABELS.requestEmailSuccessMsg);
              activity.actionId ===
                getActionIdFromActionCode(
                  'BASELINE_DEFAULT',
                  statusMappingList,
                ) && updateStatusAndReset(formId, 'BASELINE_SEND_REQ');
            })
            .catch(() => displayToast('error', LABELS.requestEmailErrorMsg))
            .finally(() => setLoading(false));
          break;
      }
    };

    if (!baselineModalProps.formId) {
      generateBaselineId('Baseline', projectCycleId).then((response) =>
        handleFormId(response.data),
      );
    } else {
      handleFormId(
        baselineModalProps.formId,
        getActionCodeFromActionId(
          baselineModalProps?.actionId,
          statusMappingList,
        ),
      );
    }
  };

  const handleActivityReportingSendRequest = () => {
    const body = {
      approvalDescription: '',
      emailFrom: '',
      enrollmentInstanceId: null,
      internalProjectId: projectId,
      participantId,
      projectCycleId,
    };

    const updateStatusAndReset = (formId, actionCode) => {
      updateParticipantBaselineStatus({
        formId,
        actionId: getActionIdFromActionCode(actionCode, statusMappingList),
        enrolmentType: 'Activity',
        projectCycleId,
      }).finally(() => {
        setLoading(false);
        setInitiateActivityReportingModalData(
          INITIATE_ACTIVITY_REPORTING_INITIALSTATE,
        );
      });
    };

    const handleFormId = (
      formId,
      previousBaselineActionCode = 'ACTIVITY_DEFAULT',
    ) => {
      switch (selectedActivityOption) {
        case 'Report on behalf':
          updateStatusAndReset(formId, previousBaselineActionCode);
          break;
        case 'Send request':
          setLoading(true);
          axios
            .post(SEND_ACTIVITY_EMAIL, body)
            .then((response) => {
              response?.data?.message !== INACTIVE_EMAIL_TEMLATE_TEXT &&
                displayToast('success', LABELS.requestEmailSuccessMsg);
              activity.actionId ===
                getActionIdFromActionCode(
                  'ACTIVITY_DEFAULT',
                  statusMappingList,
                ) && updateStatusAndReset(formId, 'ACTIVITY_SEND_REQ');
            })
            .catch(() => displayToast('error', LABELS.requestEmailErrorMsg))
            .finally(() => setLoading(false));
          break;
      }
    };
    if (!activity.formId) {
      generateBaselineId('Activity', projectCycleId).then((response) =>
        handleFormId(response.data),
      );
    } else {
      handleFormId(
        activity.formId,
        getActionCodeFromActionId(
          initiateActivityReportingModalData?.actionId,
          statusMappingList,
        ),
      );
    }
  };

  const shouldMenuBeShown = () => {
    /* istanbul ignore else */
    if (activityType === ACTIVITY_TYPE.BASELINE_REPORTING) {
      const enrollmentActivity = activityData[0]?.activityList.find(
        ({ activityType: enrolledActivity }) =>
          enrolledActivity === ACTIVITY_TYPE.ENROLLMENT,
      );
      return (
        enrollmentActivity &&
        enrollmentActivity.status === REPORTING_STATUS.approved
      );
    } else if (activityType === ACTIVITY_TYPE.ACTIVITY_REPORTING) {
      const baselineActivity = activityData[0]?.activityList.find(
        ({ activityType: baselinedActivity }) =>
          baselinedActivity === ACTIVITY_TYPE.BASELINE_REPORTING,
      );
      return (
        baselineActivity &&
        baselineActivity.status === REPORTING_STATUS.approved
      );
    } else if (
      (activityType === ACTIVITY_TYPE.OUTREACH &&
        activitySubType === ACTIVITY_SUBTYPE.not_started &&
        activityData[0].activityList.find(
          (activity) =>
            activity.activitySubType === ACTIVITY_SUBTYPE.interested,
        )) ||
      (activityData[1] &&
        activityType === ACTIVITY_TYPE.CONTRACTING &&
        activitySubType === ACTIVITY_SUBTYPE.not_started &&
        activityData[1].activityList.find(
          (activity) =>
            activity.activitySubType === ACTIVITY_SUBTYPE.contract_added,
        ))
    ) {
      return false;
    }
    return true;
  };

  const contextValue = useMemo(
    () => ({
      addContractOpen,
      setAddContractOpen,
      editContractOpen,
      setEditContractOpen,
      addPipelineAcresOpen,
      setAddPipelineAcresOpen,
      editPipelineAcresOpen,
      setEditPipelineAcresOpen,
      status,
      activityType,
      participantId,
      projectId,
      enrollModalData,
      setEnrollModalData,
      activity,
      reviewEnrollmentProps,
      setReviewEnrollmentProps,
      reopenProps,
      setReopenProps,
      participantName,
      baselineModalProps,
      setBaselineModalProps,
      handleBaselineSendRequest,
      loading,
      setLoading,
      projectName,
      updateParticipantBaselineStatus,
      selectedBaselineOption,
      setSelectedBaselineOption,
      statusMappingList,
      generateBaselineId,
      initiateActivityReportingModalData,
      setInitiateActivityReportingModalData,
      handleActivityReportingSendRequest,
      anchorEl,
      setAnchorEl,
      closeMenu,
      activityDataList,
      activitySubType,
      projectCycleId,
      area,
      setSelectedActivityOption,
    }),
    [
      enrollModalData,
      addContractOpen,
      editContractOpen,
      addPipelineAcresOpen,
      editPipelineAcresOpen,
      reviewEnrollmentProps,
      reopenProps,
      baselineModalProps,
      loading,
      selectedBaselineOption,
      selectedActivityOption,
      initiateActivityReportingModalData,
      anchorEl,
      projectCycleId,
    ],
  );

  useEffect(() => {
    setShouldNotRefresh(addContractOpen.isOpen);
  }, [addContractOpen]);

  /* istanbul ignore else */
  if (!shouldMenuBeShown()) {
    return null;
  }
  return (
    <ActivityContext.Provider value={contextValue}>
      <ActivityOptionsButton horizIcon={true} />
      <ContractActivityAction />
      <PipelineAcerage />
      <EditPipelineAcerage />
      <EnrollmentActivityAction />
      <BaselineActivityAction />
      <ActivityReportingAction />
    </ActivityContext.Provider>
  );
});

ActivityListContainer.propTypes = {
  activity: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  activityDataList: PropTypes.array.isRequired,
  statusMappingList: PropTypes.array.isRequired,
  activitySubType: PropTypes.string.isRequired,
  projectCycleId: PropTypes.number.isRequired,
  area: PropTypes.string,
  setShouldNotRefresh: PropTypes.func.isRequired,
};
