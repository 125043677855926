// EnrollmentMenuItems.js
import { useContext } from 'react';
import { useActivityContext } from 'containers/ActivityListContainer';
import {
  InitiateEnrollmentButton,
  ReviewEnrollmentButton,
  ViewPdfButton,
  RequestAdditionalEnrollment,
} from '../ActivityMenuButton/EnrollmentButtons.react';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import { DOWNLOAD_ENROLLMENT_REPORT } from 'urls';
import { ORIGINATION_VIEWER } from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { hasAnyRequiredAccess } from 'utils/helper';
import { CONTINUE } from './ActivityMenuItem.content';

const EnrollmentMenuItems = () => {
  const { status } = useActivityContext();
  const { userRoles } = useContext(userAuthRolesContext);
  const isViewer = hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]);

  return (
    <>
      {status === REPORTING_STATUS.not_started && (
        <InitiateEnrollmentButton
          label={'Initiate enrollment'}
          disabled={isViewer}
        />
      )}
      {(status === REPORTING_STATUS.in_progress ||
        status === REPORTING_STATUS.requested) && (
        <>
          <InitiateEnrollmentButton
            label={CONTINUE}
            key={'enrollment-continue'}
            disabled={isViewer}
          />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_REPORT}
            key={'view-enrollment'}
          />
        </>
      )}
      {(status === REPORTING_STATUS.submitted ||
        status === REPORTING_STATUS.in_review) && (
        <ReviewEnrollmentButton key={'review-enrollment'} />
      )}
      {status === REPORTING_STATUS.approved && (
        <>
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_REPORT}
            key={'view-enrollment'}
          />
          <RequestAdditionalEnrollment disabled={isViewer} />
        </>
      )}
    </>
  );
};

export default EnrollmentMenuItems;
