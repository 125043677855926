export const PAGE_CONTENT = {
  back: 'Back',
  APPROVE: 'Approve',
  reopen: 'Reopen',
  CANCEL: 'Cancel',
  downloadPdf: 'Download PDF',
  exception_report: 'Download exception report',
  enrollment: 'Enrollment',
  baselineReporting: 'Baseline reporting',
  activity_reporting: 'Activity reporting',
};
