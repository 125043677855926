// BaselineReportingMenuItems.js
import { useContext } from 'react';
import {
  InitiateBaselineReportingButton,
  ReviewMenuItem,
} from '../ActivityMenuButton/BaselineButtons.react';
import { useActivityContext } from 'containers/ActivityListContainer';
import { REPORTING_STATUS } from './ActivityMenuItem.constant';
import { ViewPdfButton } from '../ActivityMenuButton/EnrollmentButtons.react';
import { DOWNLOAD_ENROLLMENT_BASELINE_REPORT } from 'urls';
import { ORIGINATION_VIEWER } from 'utils/config';
import { hasAnyRequiredAccess } from 'utils/helper';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { CONTINUE } from './ActivityMenuItem.content';

const BaselineReportingMenuItems = () => {
  const { status } = useActivityContext();
  const { userRoles } = useContext(userAuthRolesContext);
  const isViewer = hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]);

  return (
    <>
      {status === REPORTING_STATUS.not_started && (
        <InitiateBaselineReportingButton
          label={'Initiate baseline reporting'}
          disabled={isViewer}
        />
      )}
      {(status === REPORTING_STATUS.in_progress ||
        status === REPORTING_STATUS.requested) && (
        <>
          <InitiateBaselineReportingButton
            label={CONTINUE}
            disabled={isViewer}
          />
          <ViewPdfButton
            url={DOWNLOAD_ENROLLMENT_BASELINE_REPORT}
            key={'view-baseline'}
          />
        </>
      )}
      {(status === REPORTING_STATUS.submitted ||
        status === REPORTING_STATUS.in_review) && <ReviewMenuItem />}
      {status === REPORTING_STATUS.approved && (
        <ViewPdfButton
          url={DOWNLOAD_ENROLLMENT_BASELINE_REPORT}
          key={'view-approved'}
        />
      )}
    </>
  );
};

export default BaselineReportingMenuItems;
