import { NotificationLogProvider } from 'containers/NotificationLogContext.react';
import Header from 'layout/header/Header.react';
import React from 'react';
import { ErrorBanner } from './errorBanner.react';

const ErrorPage = () => {
  return (
    <>
      <NotificationLogProvider>
        <Header />
      </NotificationLogProvider>
      <ErrorBanner />
    </>
  );
};

export default ErrorPage;
