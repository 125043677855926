import { useContext, useEffect, useState } from 'react';
import {
  checkTernaryCondition,
  findObjectWithKey,
  ifAnyIsTrue,
  isEmpty,
} from 'utils/helper';
import NitrogentFertilizerModal from '../NitrogenFertilizerModal.react';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import {
  MODAL_CONTENT,
  ModalInitialErrorState,
  NitrogenFertilizerFormInitialData,
  fertilizerTypeOptions,
  formFieldNames,
  isValueNullorUndefined,
  findUnitOfMeasurementOptions,
} from '../NitrogenFertilizerModal.content';
import {
  COOL_GRAY,
  DARK_GREEN,
  FARM_FIELD_LABEL_COLOR,
} from 'theme/GlobalColors';
import { useForm } from 'hooks/useForm';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/config';
import { ReactComponent as Calendericon } from '../../../../../../../assets/icons/calenderIcon.svg';
import NitrogenFertilizerContent from '../NitrogenFertilizerContent/NitrogenFertilizerContent.react';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { FertilizerTypeTooltipSx } from '../NitrogentFertilizerModal.style';
import { outlinedDropdownInputSx } from '../../Modals.style';
import PropTypes from 'prop-types';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import { FERTILIZER_APPLICATION_RATE } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

const NitrogenFertilizerWrapper = ({
  actsAsModal,
  practiceTypeData,
  hasTestDefaultDate,
  year,
  id,
  modalOpen,
  setModalOpen,
  submitHandler,
  isInTesting,
  formDataValue,
  setIsAddBtnDisabled = () => {
    /* do nothing here */
  },
  makeFormDirty = () => {
    /* do nothing here */
  },
  disableAllFieldActions = false,
  isEditable = true,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datesAppliedArray, setDateAppliedArray] = useState(
    checkTernaryCondition(
      !actsAsModal && formDataValue !== undefined,
      formDataValue?.datesApplied,
      [null],
    ),
  );
  const [selectedUnitOfMeasure, setSelectedUnitOfMeasure] = useState('');
  const [dateError, setDateError] = useState({ flag: false, indexArray: [] });
  const [dateRequiedList, setDateRequiedList] = useState([]);
  const [errorState, setErrorState] = useState(ModalInitialErrorState);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [noDataOptionSelected, setNoDataOptionSelected] = useState(false);
  const [onHoverDateApplied, setOnHoverDateApplied] = useState(false);
  const [isnpkDisabled, setIsnpkDisabled] = useState({
    nPercentage: false,
    pPercentage: false,
    kPercentage: false,
  });
  const [previousFertilizerCategory, setPreviousFertilizerCategory] =
    useState('');
  const [previousNPKValues, setPreviousNPKValues] = useState({
    nPercentage: '',
    pPercentage: '',
    kPercentage: '',
  });
  const [duplicatedDate, setDuplicateDate] = useState({
    flag: false,
    dupIndexArray: [],
  });
  const { deleteHandler, isFieldDisabled } = useContext(
    implementedActivitiesWithOpsDataContext,
  );
  const inputsDisabled = !actsAsModal;
  const [isDateInvalid, setIsDateInvalid] = useState(false);
  const [isCombinationUnique, setIsCombinationUnique] = useState(true);

  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    checkTernaryCondition(
      !actsAsModal && formDataValue !== undefined,
      formDataValue,
      NitrogenFertilizerFormInitialData,
    ),
  );
  const {
    fertilizerTypeDropDown,
    animalTypeDropdown,
    manureTypeDropdown,
    fertilizerApplicationMethod,
  } = useContext(baselineDropdownContext);
  const {
    unitOfMeasureList: { fertilizer },
  } = useContext(uomContext);
  const [fertilizerTypeDropdownList, setFertilizerTypeDropdownList] = useState(
    fertilizerTypeDropDown,
  );
  const existingFertilizerTypeIds =
    practiceTypeData?.map((item) => item.data.fertilizerType) || [];
  const unitOfMeasurementOptions = findUnitOfMeasurementOptions(
    findObjectWithKey(fertilizer, FERTILIZER_APPLICATION_RATE)
      ?.FERTILIZER_APPLICATION_RATE,
  );

  const selectUom = () => {
    if (formValue.fertilizerType === 21) {
      if (formValue.manureType === 1 || formValue.manureType === 2) {
        setSelectedUnitOfMeasure(
          unitOfMeasurementOptions.find(
            (x) => x.label === MODAL_CONTENT.gals_acre,
          )?.value,
        );
        setErrorState({
          ...errorState,
          unitOfMeasure: !unitOfMeasurementOptions.some(
            (x) => x.label === MODAL_CONTENT.gals_acre,
          ),
        });
      } else if (formValue.manureType === 3) {
        setSelectedUnitOfMeasure(
          unitOfMeasurementOptions.find(
            (x) => x.label === MODAL_CONTENT.lbs_acre,
          )?.value,
        );
        setErrorState({
          ...errorState,
          unitOfMeasure: !unitOfMeasurementOptions.some(
            (x) => x.label === MODAL_CONTENT.lbs_acre,
          ),
        });
      }
    } else if (formValue.fertilizerType === '') {
      setSelectedUnitOfMeasure('');
    } else if (
      fertilizerTypeDropDown
        .find((data) => data.value === formValue.fertilizerType)
        ?.form?.includes(MODAL_CONTENT.liquid_label)
    ) {
      setSelectedUnitOfMeasure(
        unitOfMeasurementOptions.find(
          (x) => x.label === MODAL_CONTENT.gals_acre,
        )?.value,
      );
      setErrorState({
        ...errorState,
        unitOfMeasure: !unitOfMeasurementOptions.some(
          (x) => x.label === MODAL_CONTENT.gals_acre,
        ),
      });
    } else if (
      fertilizerTypeDropDown
        .find((data) => data.value === formValue.fertilizerType)
        ?.form?.includes(MODAL_CONTENT.solid_label)
    ) {
      setSelectedUnitOfMeasure(
        unitOfMeasurementOptions.find((x) => x.label === MODAL_CONTENT.lbs_acre)
          ?.value,
      );
      setErrorState({
        ...errorState,
        unitOfMeasure: !unitOfMeasurementOptions.some(
          (x) => x.label === MODAL_CONTENT.lbs_acre,
        ),
      });
    }
  };

  const mineralPercentageArray = [
    {
      label: MODAL_CONTENT.n_percentage,
      name: formFieldNames.nPercentage,
      value: formValue.nPercentage,
      placeholder: MODAL_CONTENT.percentage_place_holder,
      error: errorState.nPercentage,
      error_message: '',
      disabled: isnpkDisabled.nPercentage,
    },
    {
      label: MODAL_CONTENT.p_percentage,
      name: formFieldNames.pPercentage,
      value: formValue.pPercentage,
      placeholder: MODAL_CONTENT.percentage_place_holder,
      error: errorState.pPercentage,
      error_message: '',
      disabled: isnpkDisabled.pPercentage,
    },
    {
      label: MODAL_CONTENT.k_percentage,
      name: formFieldNames.kPercentage,
      value: formValue.kPercentage,
      placeholder: MODAL_CONTENT.percentage_place_holder,
      error:
        formValue.fertilizerCategory === fertilizerTypeOptions[1].label &&
        errorState.kPercentage,
      error_message: '',
      disabled: isnpkDisabled.kPercentage,
    },
  ];

  const fertilizertypeDropdownProps = {
    label: MODAL_CONTENT.fertilizer_type_label,
    headerColor: FARM_FIELD_LABEL_COLOR,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.fertilizerType === '',
      MODAL_CONTENT.fertilizer_type_placeholder,
      formValue.fertilizerType,
    ),
    isDisabled: inputsDisabled,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.fertilizerType,
    ariaLabel: MODAL_CONTENT.fertilizer_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.fertilizer_type_placeholder,
    error: errorState.fertilizerType,
    errorMessage: '',
    setModalErrorState: setErrorState,
    dropdownlist: fertilizerTypeDropdownList,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.fertilizerType,
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    minWidth: 'fit-content',
    fieldBottomMargin: '0rem',
    labelMarginTop: checkTernaryCondition(actsAsModal, '1.5rem', '0rem'),
    labelMarginBottom: '0.25rem',
    moduleError: !isCombinationUnique,
    tooltipLabel:
      !actsAsModal &&
      !isFieldDisabled &&
      !disableAllFieldActions &&
      MODAL_CONTENT.fertilizer_type_deletion_tooltip,
    tooltipSx: FertilizerTypeTooltipSx,
    onUpdate: (value) => {
      setErrorState({
        ...errorState,
        fertilizerType: isEmpty(value),
      });
    },
  };
  const animalTypeDropdownProps = {
    label: MODAL_CONTENT.animal_type_label,
    headerColor: FARM_FIELD_LABEL_COLOR,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.animalType === '',
      MODAL_CONTENT.animal_type_placeholder,
      formValue.animalType,
    ),
    isDisabled: inputsDisabled,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.animalType,
    ariaLabel: MODAL_CONTENT.animal_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.animal_type_placeholder,
    error: errorState.animalType,
    errorMessage: '',
    setModalErrorState: setErrorState,
    dropdownlist: animalTypeDropdown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.animalType,
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    minWidth: 'fit-content',
    fieldBottomMargin: '0rem',
    labelMarginTop: '1.5rem',
    labelMarginBottom: '0.25rem',
    moduleError: !isCombinationUnique,
    onUpdate: (value) => {
      setErrorState({
        ...errorState,
        animalType: isEmpty(value),
      });
    },
  };
  const manureTypeDropdownProps = {
    label: MODAL_CONTENT.manure_type_label,
    headerColor: FARM_FIELD_LABEL_COLOR,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.manureType === '',
      MODAL_CONTENT.manure_type_placeholder,
      formValue.manureType,
    ),
    isDisabled: inputsDisabled,
    width: '13.75rem',
    height: '2rem',
    name: formFieldNames.manureType,
    ariaLabel: MODAL_CONTENT.manure_type_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.manure_type_placeholder,
    error: errorState.manureType,
    errorMessage: '',
    setModalErrorState: setErrorState,
    dropdownlist: manureTypeDropdown,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.manureType,
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    minWidth: 'fit-content',
    fieldBottomMargin: '0rem',
    labelMarginTop: '1.5rem',
    labelMarginBottom: '0.25rem',
    moduleError: !isCombinationUnique,
    onUpdate: (value) => {
      setErrorState({
        ...errorState,
        manureType: isEmpty(value),
      });
    },
  };

  const unitOfMeasurementDropdownProps = {
    label: MODAL_CONTENT.unit_of_measure_label,
    headerColor: FARM_FIELD_LABEL_COLOR,
    value: checkTernaryCondition(
      selectedUnitOfMeasure === '',
      MODAL_CONTENT.unit_of_measure_placeholder,
      selectedUnitOfMeasure,
    ),
    isDisabled: true,
    width: '10rem',
    height: '2rem',
    name: formFieldNames.unitOfMeasure,
    ariaLabel: MODAL_CONTENT.unit_of_measure_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.unit_of_measure_placeholder,
    error: errorState.unitOfMeasure,
    errorMessage: '',
    dropdownlist: unitOfMeasurementOptions,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.unitOfMeasure,
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    setModalErrorState: setErrorState,
    fieldName: MODAL_CONTENT.uom_field,
    hasNoBottomMargin: true,
    labelMarginTop: '1.5rem',
    labelMarginBottom: '0.25rem',
    minWidth: 'fit-content',
    fieldBottomMargin: '0rem',
    customSx: outlinedDropdownInputSx,
    onUpdate: (value) => {
      setErrorState({
        ...errorState,
        unitOfMeasure: isEmpty(value),
      });
    },
  };

  const applicationMethodDropdownProps = {
    label: MODAL_CONTENT.application_method_label,
    headerColor: FARM_FIELD_LABEL_COLOR,
    customPlaceholderColor: COOL_GRAY,
    value: checkTernaryCondition(
      formValue.applicationMethod === '',
      MODAL_CONTENT.application_method_placeholder,
      formValue.applicationMethod,
    ),
    isDisabled: inputsDisabled,
    width: '15.20rem',
    height: '2rem',
    name: formFieldNames.applicationMethod,
    ariaLabel: MODAL_CONTENT.application_method_placeholder,
    dropDownPlaceholder: MODAL_CONTENT.application_method_placeholder,
    error: errorState.applicationMethod,
    errorMessage: '',
    setModalErrorState: setErrorState,
    dropdownlist: fertilizerApplicationMethod,
    isNotOutlined: false,
    setFormFieldValue: customFieldChangeHandler,
    field_key: formFieldNames.applicationMethod,
    setStateValue: () => {
      /* do nothing here */
    },
    showLabelAsValue: true,
    labelMarginTop: '1.5rem',
    labelMarginBottom: '0.25rem',
    minWidth: '13.75rem',
    fieldBottomMargin: '0rem',
    moduleError: !isCombinationUnique,
    customSx: outlinedDropdownInputSx,
    onUpdate: (value) => {
      setErrorState({
        ...errorState,
        applicationMethod: isEmpty(value),
      });
    },
  };

  const checkIsDatepickerOpen = (dataId) => {
    return datePickerOpen.id === dataId && datePickerOpen.isOpen;
  };

  const dateAppliedPickerProps = (dataId, item) => {
    return {
      label: MODAL_CONTENT.date_applied_label,
      labelColor: FARM_FIELD_LABEL_COLOR,
      dateTextColor: COOL_GRAY,
      calendarOpenDate: checkTernaryCondition(
        hasTestDefaultDate,
        new Date(),
        `${year - 1}-09-01`,
      ),
      value: checkTernaryCondition(
        ifAnyIsTrue(
          formValue.datesApplied[dataId] === '',
          formValue.datesApplied[dataId] === undefined,
        ),
        MODAL_CONTENT.date_applied_placeholder,
        formValue.datesApplied[dataId],
      ),
      inputFormat: DATE_FORMAT,
      onUpdate: (newValue) => {
        let newDateArray = [...datesAppliedArray];
        let newValueArray = [...formValue.datesApplied];
        newValueArray[dataId] = checkTernaryCondition(
          newValue?.toDate() !== undefined,
          newValue?.toDate(),
          null,
        );
        newDateArray[dataId] = newValueArray[dataId];
        customFieldChangeHandler(formFieldNames.datesApplied, newValueArray);
        setDateAppliedArray(newDateArray);
        setDateRequiedList(
          dateRequiedList.filter((requiredDate) => requiredDate !== dataId),
        );
        const duplicatedDateIndex = formValue.datesApplied.findIndex(
          (tillageDate, i) => {
            const formattedDate = moment(newValueArray[dataId]).format(
              'YYYY-MM-DD',
            );
            const formattedTillageDate =
              moment(tillageDate).format('YYYY-MM-DD');
            return (
              i !== dataId &&
              formattedTillageDate !== MODAL_CONTENT.invalid_date &&
              formattedDate !== MODAL_CONTENT.invalid_date &&
              formattedDate === formattedTillageDate
            );
          },
        );
        if (duplicatedDateIndex !== -1) {
          setDuplicateDate({
            flag: true,
            dupIndexArray: [...duplicatedDate.dupIndexArray, dataId],
          });
        }
        if (
          duplicatedDateIndex === -1 &&
          duplicatedDate.dupIndexArray.includes(dataId)
        ) {
          setDuplicateDate({
            flag:
              duplicatedDate.dupIndexArray.filter((date) => date !== dataId)
                .length > 0,
            dupIndexArray: duplicatedDate.dupIndexArray.filter(
              (date) => date !== dataId,
            ),
          });
        }
        let indexesWithValue = formValue.datesApplied.reduce(function (
          a,
          e,
          i,
        ) {
          if (
            moment(e).format('YYYY-MM-DD') === moment(item).format('YYYY-MM-DD')
          )
            a.push(i);
          return a;
        },
        []);
        if (indexesWithValue.length >= 2) {
          indexesWithValue = [...indexesWithValue].filter(
            (value) => value !== dataId,
          );
        }
        if (
          duplicatedDateIndex === -1 &&
          duplicatedDate.dupIndexArray.includes(indexesWithValue[0])
        ) {
          setDuplicateDate({
            flag:
              duplicatedDate.dupIndexArray.filter(
                (data) => data !== indexesWithValue[0],
              ).length > 0,
            dupIndexArray: duplicatedDate.dupIndexArray.filter(
              (data) => data !== indexesWithValue[0],
            ),
          });
        }
      },
      icon: Calendericon,
      open: checkIsDatepickerOpen(dataId),
      onClose: () => setDatePickerOpen(false),
      placeholder: MODAL_CONTENT.date_applied_placeholder,
      width: '8.3rem',
      height: '2rem',
      onClickHandler: () => setDatePickerOpen({ id: dataId, isOpen: true }),
      error:
        ifAnyIsTrue(
          dateError.indexArray.length > 0,
          dateRequiedList.length > 0,
          duplicatedDate.dupIndexArray.length > 0,
          isDateInvalid,
        ) && actsAsModal,
      errorMessage: '',
      setModalErrorState: setErrorState,
      isNotOutlined: false,
      minWidth: '0rem',
      customSelectedDateColor: DARK_GREEN,
      dateList: datesAppliedArray,
      isDisabled: inputsDisabled,
      labelMarginTop: '1.5rem',
      labelMarginBottom: '0.25rem',
      validationError: !isCombinationUnique,
      minDate: `${year - 1}-09-01`,
      maxDate: `${year}-12-31`,
    };
  };

  const setDateErrorValue = () => {
    if (datesAppliedArray.length > 0) {
      let count = 0;
      let indexArray = [];
      datesAppliedArray.forEach((date, index) => {
        if (
          (new Date(date) > new Date(`${+year}-12-31`) ||
            new Date(date) < new Date(`${year - 2}-12-31`)) &&
          date !== null
        ) {
          count = count + 1;
          indexArray.push(index);
        }
      });
      if (count > 0) {
        setDateError({ flag: true, indexArray: indexArray });
      } else {
        setDateError({ flag: false, indexArray: indexArray });
      }
    }
  };

  useEffect(() => {
    const fertilizerDetails = fertilizerTypeDropDown.find(
      (item) => item.value === formValue.fertilizerType,
    );
    setPreviousFertilizerCategory(fertilizerDetails?.category);
    setPreviousNPKValues({
      nPercentage: fertilizerDetails?.nPercentage,
      pPercentage: fertilizerDetails?.pPercentage,
      kPercentage: fertilizerDetails?.kPercentage,
    });
    const category = fertilizerDetails?.category;
    let nPercentage = '';
    let pPercentage = '';
    let kPercentage = '';
    if (category === MODAL_CONTENT.organic_label) {
      if (previousFertilizerCategory !== MODAL_CONTENT.synthetic) {
        nPercentage = checkTernaryCondition(
          id !== 0,
          formValue.nPercentage,
          '',
        );
        pPercentage = checkTernaryCondition(
          id !== 0,
          formValue.pPercentage,
          '',
        );
        kPercentage = checkTernaryCondition(
          id !== 0,
          formValue.kPercentage,
          '',
        );
      }
    } else {
      nPercentage = checkTernaryCondition(
        fertilizerDetails?.nPercentage !== null,
        fertilizerDetails?.nPercentage,
        checkTernaryCondition(
          id !== 0 &&
            isValueNullorUndefined(previousNPKValues.nPercentage) &&
            previousFertilizerCategory !== MODAL_CONTENT.organic_label,
          formValue.nPercentage,
          '',
        ),
      );
      pPercentage = checkTernaryCondition(
        fertilizerDetails?.pPercentage !== null,
        fertilizerDetails?.pPercentage,
        checkTernaryCondition(
          id !== 0 &&
            isValueNullorUndefined(previousNPKValues.pPercentage) &&
            previousFertilizerCategory !== MODAL_CONTENT.organic_label,
          formValue.pPercentage,
          '',
        ),
      );
      kPercentage = checkTernaryCondition(
        fertilizerDetails?.kPercentage !== null,
        fertilizerDetails?.kPercentage,
        checkTernaryCondition(
          id !== 0 &&
            isValueNullorUndefined(previousNPKValues.kPercentage) &&
            previousFertilizerCategory !== MODAL_CONTENT.organic_label,
          formValue.kPercentage,
          '',
        ),
      );
    }

    setIsnpkDisabled({
      nPercentage: fertilizerDetails?.nPercentage !== null,
      pPercentage: fertilizerDetails?.pPercentage !== null,
      kPercentage: fertilizerDetails?.kPercentage !== null,
    });
    if (actsAsModal) {
      setFormValue({
        ...formValue,
        fertilizerCategory: category,
        nPercentage: nPercentage,
        pPercentage: pPercentage,
        kPercentage: kPercentage,
      });
    }
  }, [formValue.fertilizerType, actsAsModal]);
  useEffect(() => {
    setDateErrorValue();
  }, [datesAppliedArray]);

  useEffect(() => {
    selectUom();
  }, [formValue.fertilizerType, formValue.manureType]);

  useEffect(() => {
    if (fertilizerTypeDropDown?.length > 0) {
      const newFertilizerTypeId = formValue.fertilizerType;
      const value = fertilizerTypeDropDown.find(
        (fertilizerType) => fertilizerType?.value === newFertilizerTypeId,
      )?.label;
      if (value === MODAL_CONTENT.noneOption) {
        setFormValue({
          ...NitrogenFertilizerFormInitialData,
          fertilizerType: newFertilizerTypeId,
        });
        setNoDataOptionSelected(true);
        setErrorState(ModalInitialErrorState);
      } else setNoDataOptionSelected(false);
    }
  }, [formValue.fertilizerType, fertilizerTypeDropDown]);

  useEffect(() => {
    const isAnyDateInvalid = datesAppliedArray
      .map((item) => item !== null && moment(item).format('YYYY-MM-DD'))
      .includes(MODAL_CONTENT.invalid_date);
    setIsDateInvalid(isAnyDateInvalid);
    setIsSubmitDisabled(
      Object.values(errorState).includes(true) ||
        dateError.flag ||
        isAnyDateInvalid ||
        duplicatedDate.flag ||
        !isCombinationUnique,
    );
  }, [
    errorState,
    dateError,
    datesAppliedArray,
    duplicatedDate,
    isCombinationUnique,
  ]);
  useEffect(() => {
    if (dateRequiedList.length === 0) {
      setErrorState({ ...errorState, datesApplied: false });
    }
  }, [dateRequiedList]);
  useEffect(() => {
    if (!actsAsModal) {
      submitHandler(formValue, id, BASELINE_MODAL_CONTENT.fertilizer_list);
    }
  }, [actsAsModal, formValue]);

  useEffect(() => {
    if (actsAsModal) {
      const checkCombinationUnique = () => {
        const keysToCheck = [
          formFieldNames.fertilizerType,
          formFieldNames.animalType,
          formFieldNames.manureType,
          formFieldNames.applicationMethod,
          formFieldNames.hasInhibitor,
          formFieldNames.datesApplied,
        ];

        const convertToTargetTime = (date) => {
          const targetTime = new Date(date);
          targetTime.setUTCHours(0, 0, 0, 0); // Reset to 00:00:00 UTC
          targetTime.setHours(
            targetTime.getHours() + 24,
            targetTime.getMinutes(),
          );
          return targetTime;
        };

        const isSameDate = (date1, date2) => {
          const d1 = date1;
          const d2 = convertToTargetTime(date2);
          return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
          );
        };

        const isMatch = (dataItem) => {
          return keysToCheck.every((key) => {
            if (key === formFieldNames.datesApplied) {
              return dataItem[key].some((date) =>
                formValue[key].some((formDate) => isSameDate(date, formDate)),
              );
            }
            return dataItem[key] === formValue[key];
          });
        };

        const isUnique = !practiceTypeData.some(({ data }) => isMatch(data));
        setIsCombinationUnique(isUnique);
      };

      checkCombinationUnique();
    }
  }, [actsAsModal, formValue, practiceTypeData]);

  useEffect(() => {
    const noneFertilizer = fertilizerTypeDropDown.find(
      (item) => item.label === MODAL_CONTENT.noneOption,
    );
    if (noneFertilizer !== undefined) {
      const NONE_FERTILIZER_TYPE_ID = noneFertilizer.value;
      const hasNoneFertilizerType = practiceTypeData.some(
        (item) => item.data.fertilizerType === NONE_FERTILIZER_TYPE_ID,
      );
      setIsAddBtnDisabled(hasNoneFertilizerType);
      if (actsAsModal) {
        const hasOtherFertilizerType = practiceTypeData.some(
          (item) => item.data.fertilizerType !== NONE_FERTILIZER_TYPE_ID,
        );
        if (hasOtherFertilizerType) {
          const filteredDropdownList = fertilizerTypeDropDown.filter(
            (item) => item.value !== NONE_FERTILIZER_TYPE_ID,
          );
          setFertilizerTypeDropdownList(filteredDropdownList);
        } else {
          setFertilizerTypeDropdownList(fertilizerTypeDropDown);
        }
      }
    }
  }, [practiceTypeData, fertilizerTypeDropDown, actsAsModal]);

  return (
    <>
      {checkTernaryCondition(
        actsAsModal,
        <NitrogentFertilizerModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          submitHandler={submitHandler}
          disableAllFieldActions={disableAllFieldActions}
          existingFertilizerTypeIds={existingFertilizerTypeIds}
          formValue={formValue}
          setFormValue={setFormValue}
          setErrorState={setErrorState}
          datesAppliedArray={datesAppliedArray}
          setDateAppliedArray={setDateAppliedArray}
          dateError={dateError}
          setDateError={setDateError}
          selectedUnitOfMeasure={selectedUnitOfMeasure}
          setPreviousFertilizerCategory={setPreviousFertilizerCategory}
          setPreviousNPKValues={setPreviousNPKValues}
          setDateRequiedList={setDateRequiedList}
          duplicatedDate={duplicatedDate}
          setDuplicateDate={setDuplicateDate}
          noDataOptionSelected={noDataOptionSelected}
          customFieldChangeHandler={customFieldChangeHandler}
          isSubmitDisabled={isSubmitDisabled}
          onHoverDateApplied={onHoverDateApplied}
          setOnHoverDateApplied={setOnHoverDateApplied}
          mineralPercentageArray={mineralPercentageArray}
          fertilizertypeDropdownProps={fertilizertypeDropdownProps}
          animalTypeDropdownProps={animalTypeDropdownProps}
          manureTypeDropdownProps={manureTypeDropdownProps}
          unitOfMeasurementDropdownProps={unitOfMeasurementDropdownProps}
          applicationMethodDropdownProps={applicationMethodDropdownProps}
          dateAppliedPickerProps={dateAppliedPickerProps}
          dateRequiedList={dateRequiedList}
          isInTesting={isInTesting}
          errorState={errorState}
          isCombinationUnique={isCombinationUnique}
          makeFormDirty={makeFormDirty}
        />,
        <NitrogenFertilizerContent
          fertilizertypeDropdownProps={fertilizertypeDropdownProps}
          noDataOptionSelected={noDataOptionSelected}
          fertilizerTypeDropDown={fertilizerTypeDropDown}
          formValue={formValue}
          animalTypeDropdownProps={animalTypeDropdownProps}
          manureTypeDropdownProps={manureTypeDropdownProps}
          datesAppliedArray={datesAppliedArray}
          dateAppliedPickerProps={dateAppliedPickerProps}
          setDateAppliedArray={setDateAppliedArray}
          customFieldChangeHandler={customFieldChangeHandler}
          dateRequiedList={dateRequiedList}
          setDateRequiedList={setDateRequiedList}
          isInTesting={isInTesting}
          setDuplicateDate={setDuplicateDate}
          duplicatedDate={duplicatedDate}
          setOnHoverDateApplied={setOnHoverDateApplied}
          onHoverDateApplied={onHoverDateApplied}
          errorState={errorState}
          setErrorState={setErrorState}
          dateError={dateError}
          applicationMethodDropdownProps={applicationMethodDropdownProps}
          unitOfMeasurementDropdownProps={unitOfMeasurementDropdownProps}
          mineralPercentageArray={mineralPercentageArray}
          disableAllFieldActions={disableAllFieldActions}
          inputsDisabled={inputsDisabled}
          deleteHandler={() =>
            deleteHandler(
              id,
              MODAL_CONTENT.heading,
              fertilizerTypeDropDown.find(
                (fertilizerType) =>
                  fertilizerType?.value === formValue?.fertilizerType,
              )?.label,
            )
          }
          makeFormDirty={makeFormDirty}
          isEditable={isEditable}
        />,
      )}
    </>
  );
};

export default NitrogenFertilizerWrapper;
NitrogenFertilizerWrapper.propTypes = {
  actsAsModal: PropTypes.bool,
  practiceTypeData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        datesApplied: PropTypes.arrayOf(PropTypes.string),
        fertilizerType: PropTypes.number,
        animalType: PropTypes.number,
      }),
    }),
  ),
  hasTestDefaultDate: PropTypes.bool,
  year: PropTypes.number,
  id: PropTypes.number,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  isInTesting: PropTypes.bool,
  formDataValue: PropTypes.shape({
    datesApplied: PropTypes.array,
  }),
  setIsAddBtnDisabled: PropTypes.func,
  makeFormDirty: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
};
