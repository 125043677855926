export const MODAL_CONTENT = {
  titlePrefix: 'Copy',
  copyBtnLabel: 'Copy',
  cancelBtnLabel: 'Cancel',
  noDataText: 'There are no results to display for the filtered selection.',
  tractorVehicleCopyError:
    'Copy of farm tractor / vehicle failed to complete due to a technical error. Please try again.',
  fieldCropCopyError:
    'Copy of field cash crop(s) failed to complete due to a technical error. Please try again.',
};

export const farmsDataProps = { labelKey: 'farmName', valueKey: 'farmId' };
export const farmyearsDataProps = {
  name: 'yearsList',
  labelKey: 'dateId',
  valueKey: 'sampleId',
};
export const fuelsDataProps = {
  name: 'fuelDtoList',
  labelKey: 'fuelName',
  valueKey: 'ffId',
};
export const fieldsDataProps = { labelKey: 'fieldName', valueKey: 'fieldId' };
export const fieldYearsDataProps = {
  name: 'listOfYears',
  labelKey: 'yearId',
  valueKey: 'sampleId',
};
export const cropsDataProps = {
  name: 'crops',
  labelKey: 'cropName',
  valueKey: 'cropId',
};

export const allFieldsOption = { label: 'All fields', value: 'All fields' };
export const allFarmsOption = { label: 'All farms', value: 'All farms' };
export const allYearsOption = { label: 'All years', value: 'All years' };
export const allCropsOption = { label: 'All crops', value: 'All crops' };
export const allFuelsOption = { label: 'All fuels', value: 'All fuels' };

export const ITEMS_PER_PAGE = 10;

/**
 * @description Filters and returns valid combinations based on the provided dropdown values and properties.
 * @param {Array} data - The array of data items to filter.
 * @param {Object} dropdown1Props - The properties for the first dropdown.
 * @param {Object} dropdown2Props - The properties for the second dropdown.
 * @param {Object} dropdown3Props - The properties for the third dropdown.
 * @param {string} dropdown1Value - The selected value for the first dropdown.
 * @param {string} dropdown2Value - The selected value for the second dropdown.
 * @param {string} dropdown3Value - The selected value for the third dropdown.
 * @param {Array} dropdown1List - The list of items for the first dropdown.
 * @param {Array} dropdown2List - The list of items for the second dropdown.
 * @param {Array} dropdown3List - The list of items for the third dropdown.
 * @param {Object} dropdown3DisabledItems - The object containing disabled items for the third dropdown.
 * @param {Function} isSelected - The function to determine if an item is selected.
 * @returns {Array} An array of valid combinations.
 */
export const getValidCombinations = ({
  data,
  dropdown1Props,
  dropdown2Props,
  dropdown3Props,
  dropdown1Value,
  dropdown2Value,
  dropdown3Value,
  dropdown1List,
  dropdown2List,
  dropdown3List,
  dropdown3DisabledItems,
  isSelected,
}) => {
  const validCombinations = [];

  data.forEach((dropdown1Item) => {
    if (
      dropdown1Value === dropdown1Item[dropdown1Props?.labelKey] ||
      dropdown1Value === dropdown1List[dropdown1List.length - 1]?.label
    ) {
      dropdown1Item[dropdown2Props?.name]?.forEach((dropdown2Item) => {
        if (
          dropdown2Value === dropdown2Item[dropdown2Props?.labelKey] ||
          dropdown2Value === dropdown2List[dropdown2List.length - 1]?.label
        ) {
          dropdown2Item[dropdown3Props?.name]?.forEach((dropdown3Item) => {
            const selected = isSelected(
              dropdown1Item,
              dropdown2Item,
              dropdown3Item,
            );
            const disabled =
              !selected &&
              dropdown3DisabledItems?.values?.includes(
                dropdown3Item[dropdown3Props.labelKey],
              );
            if (
              dropdown3Value === dropdown3Item[dropdown3Props?.labelKey] ||
              dropdown3Value === dropdown3List[dropdown3List.length - 1]?.label
            ) {
              validCombinations.push({
                dropdown1Item,
                dropdown2Item,
                dropdown3Item,
                selected,
                disabled,
              });
            }
          });
        }
      });
    }
  });
  return validCombinations;
};
