export const MODAL_CONTENT = {
  heading: 'Tractors / vehicles',
  fossil_fuel_type_label: 'Tractor / vehicle fuel type',
  fossil_fuel_type_placeholder: 'Select fuel type',
  consumption_label: 'Fuel consumption',
  consumption_placeholder: 'Type amount',
  unit_of_measure_label: 'Unit of measure',
  row: 'row',
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
};

export const fossilFuelTypeOptions = [{ label: 'Gasoline', value: 'Gasoline' }];

export const formFieldNames = {
  fossilFuelType: 'fossilFuelType',
  consumption: 'consumption',
  unitOfMeasure: 'unitOfMeasure',
};

export const fossilFuelTypeFormInitialData = {
  fossilFuelType: '',
  consumption: '',
  unitOfMeasure: '',
};

export const unitOfMeasurementTypes = (list) => {
  const uomList = list?.map((item) => ({
    itemLabel: item.uomNameDisplay,
    value: item.uomId,
    uomId: item.uomId,
    uomNameFull: item.uomNameFull,
  }));
  return uomList;
};
