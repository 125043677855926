export const ACTIVITY_TYPE = {
  RECRUITMENT: 'Recruitment',
  OUTREACH: 'Outreach',
  CONTRACTING: 'Contracting',
  ENROLLMENT: 'Enrollment',
  BASELINE_REPORTING: 'Baseline reporting',
  ACTIVITY_REPORTING: 'Activity reporting',
  DELETE: 'Delete',
  REMOVE: 'Remove',
  SET_INACTIVE: 'Set inactive',
};

export const getActivityPracticeMapping = (activitiesList) => {
  const activityPracticeMapping = {};

  activitiesList.forEach((activity) => {
    const activityWithData = {
      projectActivityId: activity.id,
      activityCategory: activity.activityCategory,
      projectActivityType: activity.value,
      contractedUnits: '',
      incentiveRate: activity.incentiveRate,
      error: null,
    };

    if (activityPracticeMapping[activity.activityCategory]) {
      activityPracticeMapping[activity.activityCategory].push(activityWithData);
    } else {
      activityPracticeMapping[activity.activityCategory] = [activityWithData];
    }
  });

  return activityPracticeMapping;
};

export const ACTIVITY_SUBTYPE = {
  not_started: 'Not started',
  interested: 'Interested',
  contract_added: 'Contract added',
};
