import { NavigationItem, NavigationWrapper } from './LeftNavigationMenu.style';
import PropTypes from 'prop-types';

const LeftNavigationMenu = ({
  navigationMenuList,
  navSwitchHandler,
  activeTab,
}) => {
  return (
    <NavigationWrapper>
      {navigationMenuList.map((menu, index) => {
        return (
          <NavigationItem
            active={menu.id === activeTab}
            key={menu.id}
            onClick={() => {
              navSwitchHandler(menu.id);
            }}>
            {menu.title}
          </NavigationItem>
        );
      })}
    </NavigationWrapper>
  );
};

LeftNavigationMenu.propTypes = {
  navigationMenuList: PropTypes.array,
  navSwitchHandler: PropTypes.func,
  activeTab: PropTypes.string,
};

export default LeftNavigationMenu;
