import styled from 'styled-components';
import { TYPOGRAPHY_GRAY } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const dropdownStyleProps = (deepTillageType) => ({
  height: '2rem',
  width: checkTernaryCondition(deepTillageType, '15.625rem', '15rem'),
});

export const DatePickerWithDelete = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: ${({ margin }) => margin};
  margin-bottom: 0.5rem;
`;

export const PercentageWrapper = styled.div`
  position: relative;
  left: -175px;
  color: COOL_GRAY;
  fontsize: 14px;
  margin-bottom: 1rem;
`;

export const AddOptionWrapper = styled.div`
  display: flex;
  width: 100px;
  align-items: baseline;
  cursor: pointer;
`;

export const deleteIconStyles = (firstIcon, parentFormatError, isHovered) => ({
  color: TYPOGRAPHY_GRAY,
  position: 'relative',
  top: checkTernaryCondition(firstIcon, 12, -10),
  cursor: 'pointer',
  height: '1rem',
  width: '1rem',
  marginTop: checkTernaryCondition(parentFormatError, '0rem', '1rem'),
  visibility: checkTernaryCondition(isHovered, 'visible', 'hidden'),
});

export const alertIconStyling = {
  alignSelf: 'center',
};
export const TillageModalTooltipSx = {
  padding: '0rem',
  width: '1rem',
  height: '1rem',
  marginTop: '0.2rem',
};
export const primaryButtonActionStyle = {
  marginTop: '0.5rem',
  justifyContent: 'flex-end',
  gap: '0rem',
};
export const TillagePracticeTooltipSx = {
  padding: '0rem',
  lineHeight: '1.188rem',
  width: '1rem',
  height: '1rem',
  marginLeft: '0.75rem',
};
