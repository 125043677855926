import styled from 'styled-components';

export const FilterHeader = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;
export const sortStyle = {
  height: '1rem',
  width: '1rem',
};
export const RowCellWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  color: ${({ color }) => color};
  gap: ${({ gap }) => gap};
  padding: ${({ padding }) => padding};
  cursor: ${({ cursor }) => cursor};
  align-items: ${({ alignment }) => alignment};
`;
export const CellValueWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 18rem;
  color: ${(props) => props.color};
`;
export const TextWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  word-wrap: break-word;
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const SortWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.26rem;
  border-radius: 50%;
`;
export const MainWrapper = styled.div`
  width: 100%;
  background: #ffff;
`;

export const datagridSx = {
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  borderRight: 0,
  borderLeft: 0,
  borderBottom: 0,
  borderRadius: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '140%',
    color: '#333333',
    whiteSpace: 'break-spaces',
  },
  '& .MuiDataGrid-cellContent': {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '0.9rem',
    lineHeight: '1.188rem',
    color: '#333333',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    borderBottom: '1px solid #D0D0CE',
    paddingRight: '1rem',
  },

  '& .MuiDataGrid-columnHeaders': {
    maxHeight: '2.5rem',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
};
export const DataGridWrapper = styled.div`
  height: 82vh;
  width: 100%;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
`;
export const SearchAndFilterHeader = styled.div`
  flex: 1;
`;

export const dialogContentCustomSx = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '36rem',
    },
    '& .MuiBox-root': {
      fontWeight: 400,
    },
    '& .MuiSvgIcon-root': {
      alignSelf: 'center',
    },
  },
};
export const dialogTitleSx = {
  marginBottom: '0.5rem',
};
