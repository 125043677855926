import { MenuItem } from '@mui/material';
import { menuItemSx } from '../CustomOptionsButton.style';
import { useActivityContext } from 'containers/ActivityListContainer';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { enrollmentReview } from 'pages/Origination/Org_Project/Participants/EnrollmentReview';
import axios from 'axios';
import { getActionIdFromActionCode } from 'pages/Origination/Org_Project/Participants/Participants.content';
import { LoaderProvider, loaderContext } from 'contextAPI/loaderContext';
import { LifeCycleContext } from 'contextAPI/statusLifecycleContext';
import { ConfigurationContext } from 'contextAPI/configurationContext';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { checkTernaryCondition, hasAnyRequiredAccess } from 'utils/helper';
import { ORIGINATION_VIEWER } from 'utils/config';
import { DOWNLOAD_ENROLLMENT_REPORT } from 'urls';

export const InitiateEnrollmentButton = ({ label, disabled = false }) => {
  const { participantId, projectId, activity, setEnrollModalData, closeMenu } =
    useActivityContext();
  const handleClick = () => {
    setEnrollModalData((pre) => ({
      ...pre,
      flag: true,
      id: participantId,
      projectId: projectId,
      contractId: activity.associatedContractId,
      instanceId: activity.enrollmentId,
    }));
    closeMenu();
  };

  return (
    <MenuItem
      sx={menuItemSx}
      key="initiateEnrollment"
      onClick={handleClick}
      disabled={disabled}>
      {label}
    </MenuItem>
  );
};

InitiateEnrollmentButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const ViewPdf = ({ url }) => {
  const { participantId, projectId, closeMenu } = useActivityContext();
  const { setIsLoading } = useContext(loaderContext);
  const handleViewPdf = () => {
    setIsLoading(true);
    axios
      .get(
        url
          .replace(':participnatId', participantId)
          .replace(':projectId', projectId),
      )
      .then((response) => response.data.reportLink)
      .then((reportLink) => window.open(reportLink))
      .finally(() => {
        setIsLoading(false);
        closeMenu();
      });
  };

  return (
    <MenuItem sx={menuItemSx} key="viewEnrollment" onClick={handleViewPdf}>
      View
    </MenuItem>
  );
};

ViewPdf.propTypes = {
  url: PropTypes.string.isRequired,
};

export const ViewPdfButton = ({ url }) => (
  <LoaderProvider>
    <ViewPdf url={url} />
  </LoaderProvider>
);

ViewPdfButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export const ReviewEnrollmentButton = () => {
  const {
    participantId,
    projectId,
    activity,
    statusMappingList,
    setReviewEnrollmentProps,
    closeMenu,
    projectCycleId,
  } = useActivityContext();
  const { updateParticipantStatus } = useContext(LifeCycleContext);
  const { setAreUiActionInProgress } = useContext(ConfigurationContext);
  const { userRoles } = useContext(userAuthRolesContext);
  const isViewer = hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]);

  const handleClick = () => {
    Promise.resolve(
      setAreUiActionInProgress((prev) => prev + 1),
      setReviewEnrollmentProps({
        ...enrollmentReview(participantId, projectId),
        modalData: { flag: true },
        instanceId: activity.enrollmentId,
        formId: activity.formId,
        actionCode: 1,
        contractId: activity.associatedContractId,
        projectCycleId: projectCycleId,
      }),
    ).then(() => {
      if (
        getActionIdFromActionCode('ENROLL-SUBMIT', statusMappingList) ===
        activity.actionId
      ) {
        updateParticipantStatus({
          contractId: activity.associatedContractId,
          projectId,
          participantId,
          instanceId: activity.enrollmentId,
          actionId: getActionIdFromActionCode(
            'ENROLL-REVIEW',
            statusMappingList,
          ),
          projectCycleId: projectCycleId,
        });
      }
    });
    closeMenu();
  };

  return checkTernaryCondition(
    isViewer,
    <ViewPdfButton url={DOWNLOAD_ENROLLMENT_REPORT} />,
    <MenuItem sx={menuItemSx} key="reviewEnrollment" onClick={handleClick}>
      Review
    </MenuItem>,
  );
};

export const RequestAdditionalEnrollment = ({ disabled = false }) => {
  const {
    setEnrollModalData,
    participantId,
    projectId,
    participantName,
    activity,
    closeMenu,
  } = useActivityContext();
  const handleRequestEnrollment = () => {
    setEnrollModalData((pre) => ({
      ...pre,
      flag: true,
      id: Number(participantId),
      projectData: [],
      projectId: projectId,
      participantName: participantName,
      contractId: activity.associatedContractId,
      instanceId: null,
      shouldGenerateNewInstance: true,
    }));
    closeMenu();
  };

  if (!activity.isRequestEnrollmentEnabled) {
    return null;
  }

  return (
    <MenuItem
      sx={menuItemSx}
      key="requestEnrollment"
      onClick={handleRequestEnrollment}
      disabled={disabled}>
      Request additional enrollment
    </MenuItem>
  );
};

RequestAdditionalEnrollment.propTypes = {
  disabled: PropTypes.bool,
};
