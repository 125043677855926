import { checkTernaryCondition, covertDateToLocalTimeZone } from 'utils/helper';

export const PROJECT_CALCULATION_REPORT_CONTENT = {
  toogleViewCalculationsTooltip:
    'Baseline and project views toggled to on will display full calculations with the delta values. Toggling only baseline or project to on will display the published calculations for those specific values. Project will be disabled until those calculations have been published.',
  noCalcDataTitle:
    'Your calculations are pending input(s) from baseline/activity reporting.',
  noCalcDataSubTitle: 'You will be notified when your calculations are ready.',
  run_calculations: 'Run calculations',
};

export const PROJECT_CALCULATION_COLUMN_TOOLTIP = {
  tooltipTitle: 'VM0042 and additional quantification approaches applied',
  tooltipContent:
    "Greenhouse gas (GHG) emissions and removals were quantified according to Verra's Methodology for Improved Agriculture Management, VM0042 v2.0 Quantification Approach 1 (i.e., Measure and Model) and Quantification Approach 3 (i.e., Default Factors). For Quantification Approach 1, HabiTerre's Carbon Solution was leveraged as the biogeochemical model.",
};

export const calculationSummaryData = (
  totalMT,
  totalAcerage,
  totalParticipants,
  baselineDate,
  projectDate,
) => [
  {
    label: (
      <span>
        Total MT CO<sub>2</sub>e
      </span>
    ),
    value: totalMT?.toString(),
  },
  { label: 'Total acreage', value: totalAcerage?.toString() },
  { label: 'Total participants', value: totalParticipants?.toString() },
  {
    label: 'Baseline calculated on',
    value: checkTernaryCondition(
      baselineDate,
      covertDateToLocalTimeZone(baselineDate),
      baselineDate,
    ),
  },
  {
    label: 'Project calculated on',
    value: checkTernaryCondition(
      projectDate,
      covertDateToLocalTimeZone(projectDate),
      projectDate,
    ),
  },
];
